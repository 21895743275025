import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Navbar } from '../../components/Navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';
import './Landing.scss';

export const Landing = ({ children, className, withFooterLogos, withPadding }) => (
  <div className={`page-landing ${className}`.trim()}>
    <Navbar />
    <div className={classNames({ 'page-landing-content': withPadding })}>{children}</div>
    <Footer withLogos={withFooterLogos} />
  </div>
);

Landing.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  withFooterLogos: PropTypes.bool,
  withPadding: PropTypes.bool,
};

Landing.defaultProps = {
  className: '',
  withFooterLogos: false,
  withPadding: false,
};
