import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const VerifyAddressAction = createAction('Vendor Address', 'Verify address');

export const verifyAddressAction = data => ({
  [RSAA]: {
    endpoint: `address/verify`,
    method: 'POST',
    body: data,
    types: [VerifyAddressAction.REQUEST, VerifyAddressAction.SUCCESS, VerifyAddressAction.FAILURE],
  },
});
