import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio, SubmitButton, Field } from '@bottomless/common/components';
import * as Yup from 'yup';
import { DumbType, OrderSources, SubscriptionType } from '@bottomless/common/constants';
import { useDataEffect } from '@bottomless/common/hooks';
import { Loader } from 'react-feather';
import { getVariantSelectLabel } from '@bottomless/common/utils';

const NTH_DICT = {
  1: 'st',
  2: 'nd',
  3: 'rd',
};

const createSelectOptions = (data, label, inputLabel = item => item.name) =>
  !data
    ? {}
    : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

const Schema = Yup.object().shape({
  user: Yup.string().required(),
  source: Yup.string(),
  product: Yup.string().when('source', {
    is: 'user_one_off',
    then: Yup.string().required('This field is required'),
  }),
  variant: Yup.string().when('source', {
    is: 'user_one_off',
    then: Yup.string().required('This field is required'),
  }),
});

export const TriggerOrderForm = ({ user, onSubmit, addToast, toggleModal, products, getPendingOrder }) => {
  const [selectedProduct, setProduct] = useState(null);
  const [pendingOrder, setPendingOrder] = useState();
  const isGiftSubscription = useMemo(() => user.subscriptionType === SubscriptionType.Prepaid, [user]);

  const { isFetching } = useDataEffect(getPendingOrder, setPendingOrder, user._id);

  const isSameDayMonthly = useMemo(
    () => user.subscriptionType === SubscriptionType.Fixed && user.dumb_type === DumbType.SameDayMonthly,
    [user]
  );

  const selectProduct = id => {
    const product = products.find(p => p._id === id);
    setProduct(product);
  };

  const onSuccess = useCallback(() => {
    addToast(`Order has been generated for ${user.first_name} ${user.last_name}`);
    toggleModal();
  }, [addToast, user, toggleModal]);

  const nth = useCallback(amount => `${amount}${NTH_DICT[amount] || 'th'}`, []);

  return !isFetching ? (
    <Form
      initialValues={{
        user: user._id,
        source: 'vendor',
        product: '',
        variant: '',
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      {({ isSubmitting, values }) => (
        <>
          <Radio label="Subscription Order" type="radio" name="source" value="vendor" />
          <div className="mt-2">
            <div>
              Create a regular order for the subscription product, and adjust future orders accordingly. Trigger this
              only if the customer is expecting their next subscription order
            </div>
            {pendingOrder?.order && values.source !== OrderSources.USER_ONE_OFF && (
              <div className="text-danger mt-2">
                Order is already in progress, Do you want to generate another subscription order?
              </div>
            )}
            {isSameDayMonthly && values.source !== OrderSources.USER_ONE_OFF && (
              <div className="text-danger mt-2">
                Heads up: This customer&apos;s frequency is &quot;Monthly on {user.dumb_day}&quot;, so a new order will
                still be generated for them on next {nth(user.dumb_day)} day of month.
              </div>
            )}
          </div>
          <hr />
          {!isGiftSubscription && (
            <div>
              <Radio label="One Off" type="radio" name="source" value="user_one_off" />
              <div className="mt-2 mb-2">
                Not related to the subscription product or reordering schedule. Use this for standalone orders like
                backup supplies or gifts.
              </div>
            </div>
          )}
          <Field
            name="product"
            type="select"
            label="Product"
            options={createSelectOptions(products, '--- select product ---', item => item.name)}
            onChange={event => {
              selectProduct(event.target.value);
            }}
            disabled={values.source !== 'user_one_off'}
          />

          <Field
            name="variant"
            type="select"
            label="Variant"
            options={createSelectOptions(
              selectedProduct?.variants?.filter(v => v.available),
              '--- select variant ---',
              getVariantSelectLabel
            )}
            disabled={values.source !== 'user_one_off'}
          />
          <hr />

          <div className="mt-4">
            <SubmitButton color="dark" isSubmitting={isSubmitting}>
              Trigger
            </SubmitButton>
          </div>
        </>
      )}
    </Form>
  ) : (
    <div className="d-flex justify-content-center align-items-center">
      <Loader size="18" className="spin" />
    </div>
  );
};

TriggerOrderForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  getPendingOrder: PropTypes.func.isRequired,
};
