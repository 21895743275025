import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, DataLoading } from '@bottomless/common/components';
import { utcDate } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  email: Yup.string().required(),
});

const MergeSchema = Yup.object().shape({
  customerId: Yup.string().required(),
  paymentMethod: Yup.string()
    .required()
    .label('Payment method'),
});

export const UpdateEmailForm = ({
  user,
  onSubmit,
  onSubmitSuccess,
  getShopifyUserPaymentMethods,
  mergeShopifyAccounts,
}) => {
  const [isTakenEmail, setIsTakenEmail] = useState();
  const [shopifyCustomer, setShopifyCustomer] = useState();
  const [shopifyPaymentMethods, setShopifyPaymentMethods] = useState();
  const form = useRef();
  const paymentForm = useRef();

  useEffect(() => {
    if (isTakenEmail && !shopifyPaymentMethods && form.current?.state?.values?.email) {
      getShopifyUserPaymentMethods(form.current.state.values.email).then(({ payload }) => {
        setShopifyCustomer(payload);
        setShopifyPaymentMethods(
          (payload.paymentMethods || []).reduce(
            (all, paymentMethod) => ({
              ...all,
              [paymentMethod.id]: `${paymentMethod.instrument.brand.replace(/^\w/, c => c.toUpperCase())} ${
                paymentMethod.instrument.lastDigits
              }`,
            }),
            { '': '-- Pick payment method --' }
          )
        );

        if (!payload.paymentMethods?.length) {
          paymentForm.current.setFieldTouched('paymentMethod', true);
          setTimeout(() => {
            paymentForm.current.setFieldError(
              'paymentMethod',
              "It's not possible to merge this customer, because they don't have any active payment methods."
            );
          }, 0);
        }
      });
    }
  }, [form, paymentForm, isTakenEmail, shopifyPaymentMethods, getShopifyUserPaymentMethods]);

  const handleSubmit = useCallback(
    async (...data) => {
      try {
        setIsTakenEmail(false);
        setShopifyCustomer(null);
        setShopifyPaymentMethods(null);
        return await onSubmit(...data);
      } catch (e) {
        if (e?.details?.email === 'Email has already been taken') {
          setIsTakenEmail(true);
        }

        throw e;
      }
    },
    [onSubmit]
  );

  return (
    <>
      <Form
        innerRef={form}
        initialValues={{
          email: user.local.email,
          base_id: user.base_id,
          paidUntil: user.paidUntil ? utcDate(user.paidUntil) : undefined,
        }}
        validationSchema={Schema}
        onSubmit={handleSubmit}
        onSuccess={onSubmitSuccess}
      >
        {({ isSubmitting }) => (
          <>
            <Field name="email" label="New Email" type="text" />
            <SubmitButton color="dark" isSubmitting={isSubmitting}>
              Save
            </SubmitButton>
          </>
        )}
      </Form>
      {isTakenEmail && (
        <>
          {(!shopifyPaymentMethods || !shopifyCustomer) && <DataLoading count={0} isLoading={true} />}
          {shopifyPaymentMethods && shopifyCustomer && (
            <>
              <div className="mt-5 mb-2">
                <strong>Do you want to merge this customer with following one?</strong>
              </div>
              <div>
                {shopifyCustomer.firstName} {shopifyCustomer.lastName}
              </div>
              <div className="mb-4">{shopifyCustomer.email}</div>

              <div className="text-secondary mb-4">
                We need to know which Shopify payment method of a new customer you want to use for subscription, so we
                can transfer subscription contract.
              </div>
              <Form
                innerRef={paymentForm}
                validationSchema={MergeSchema}
                initialValues={{ paymentMethod: '', customerId: shopifyCustomer.id }}
                onSubmit={mergeShopifyAccounts}
                onSuccess={onSubmitSuccess}
              >
                {({ isSubmitting }) => (
                  <>
                    <Field name="paymentMethod" label="Payment method" type="select" options={shopifyPaymentMethods} />
                    <Field name="customerId" type="hidden" />
                    <SubmitButton color="dark" isSubmitting={isSubmitting}>
                      Merge
                    </SubmitButton>
                  </>
                )}
              </Form>
            </>
          )}
        </>
      )}
    </>
  );
};

UpdateEmailForm.propTypes = {
  user: PropTypes.shape({
    base_id: PropTypes.string.isRequired,
    paidUntil: PropTypes.instanceOf(Date),
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  getShopifyUserPaymentMethods: PropTypes.func,
  mergeShopifyAccounts: PropTypes.func,
};
