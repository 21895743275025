import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Order } from './Order';
import { FulifillmentDate } from './FulfillmentDate';
import { Box, BoxCollapsible } from '../../../../components/Box';

export const UnscannedBatch = ({ groupOrders, batchId, isLast8Hours, onCSV, toggle, setOrder, setBatchId }) => {
  const getScanForm = useCallback(() => {
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/vendor-portal/orders/batch-shipment/${groupOrders[0].batch_data?._id}/scan-form`
    );
  }, [groupOrders]);

  const onPrintUnscannedLabels = useCallback(() => {
    setBatchId(batchId);
    toggle();
  }, [setBatchId, batchId, toggle]);

  const fulfilledInShopify = useMemo(() => !!batchId?.match('shopify'), [batchId]);

  const menuItems = useMemo(
    () => [
      ...(isLast8Hours(groupOrders[0].date_fulfilled) ? [{ label: 'Get CSV', action: () => onCSV(batchId) }] : []),
      ...(!fulfilledInShopify && groupOrders[0].batch_data?.scan_form
        ? [{ label: 'Print scan form', action: getScanForm }]
        : []),
      ...(!fulfilledInShopify ? [{ label: 'Print Unscanned Labels', action: onPrintUnscannedLabels }] : []),
    ],
    [isLast8Hours, batchId, groupOrders, onCSV, getScanForm, onPrintUnscannedLabels, fulfilledInShopify]
  );

  return (
    <Box menuItems={menuItems} menuStyle="buttons">
      <div className="d-flex justify-content-between align-items-center unscanned-batch">
        <div>
          <FulifillmentDate dateFulfilled={groupOrders[0].date_fulfilled} />
          {isLast8Hours(groupOrders[0].date_fulfilled) && <span className="text-success"> - most recent</span>}
          <div className="small text-secondary">
            {groupOrders.length} order{groupOrders.length > 1 ? 's' : ''}
          </div>
          {!fulfilledInShopify && !groupOrders[0].batch_data?.scan_form && (
            <div className="text-secondary">Scan From is being generated. It can take a few more minutes...</div>
          )}
        </div>
      </div>
      <BoxCollapsible>
        <div className="unscanned-orders">
          {groupOrders.map(order => (
            <Order
              key={order._id}
              order={order}
              withDate={false}
              unscanned={true}
              toggle={() => {
                setOrder(order);
                toggle();
              }}
              fulfilledInShopify={fulfilledInShopify}
            />
          ))}
        </div>
      </BoxCollapsible>
    </Box>
  );
};

UnscannedBatch.propTypes = {
  vendor: PropTypes.shape({
    _id: PropTypes.string,
    packagingCount: PropTypes.object.isRequired,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
  groupOrders: PropTypes.func.isRequired,
  batchId: PropTypes.array.isRequired,
  isLast8Hours: PropTypes.func.isRequired,
  onCSV: PropTypes.func.isRequired,

  setOrder: PropTypes.func.isRequired,
  setBatchId: PropTypes.func.isRequired,
};
