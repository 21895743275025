import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { utcDate } from '@bottomless/common/utils';

const schema = Yup.object().shape({
  override_fulfillment_date: Yup.string().required(),
});

export const EditFulfillmentDateForm = ({ order, onSubmit, onSubmitSuccess }) => {
  const handleSubmit = useCallback(
    data => {
      const override_fulfillment_date = data.override_fulfillment_date
        ? moment(data.override_fulfillment_date).format('YYYY-MM-DD')
        : null;

      return onSubmit({ ...data, override_fulfillment_date });
    },
    [onSubmit]
  );
  return (
    <Form
      initialValues={{
        ...order,
        override_fulfillment_date: order.override_fulfillment_date
          ? utcDate(order.override_fulfillment_date)
          : undefined,
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <Field
            type="date"
            name="override_fulfillment_date"
            label="Override Fulfillment Date"
            format="MM/DD/YYYY"
            min={new Date(Date.now() + 1 * 86400000)}
          />

          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

EditFulfillmentDateForm.propTypes = {
  order: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
