import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ScaleStatus } from '../../../../components/ScaleStatus';
import { Box } from '../../../../components/Box';
import { UserStatus } from '../../../../components/UserStatus';

export const User = ({ user }) => (
  <Box showChevronRight>
    <Link
      to={`/vendor/dashboard/users/${user._id}`}
      className="text-decoration-none text-color-black"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Row className="align-items-center">
        <Col xs="5">
          <div>
            {user.first_name} {user.last_name}
          </div>
          <div className="d-block text-secondary small">{user.local.email}</div>
        </Col>
        <Col xs="4">{user.scale_status && <ScaleStatus status={user} />}</Col>
        <Col xs="3" className="text-right">
          <div>
            <UserStatus user={user} />
          </div>
          {user.dumb_period && (
            <Badge pill color="info" className="ml-1">
              Fixed Period: {user.dumb_period}
            </Badge>
          )}
        </Col>
      </Row>
    </Link>
  </Box>
);

User.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    base_id: PropTypes.string,
    base_tare: PropTypes.string,
    base_calibration: PropTypes.string,
    signupDate: PropTypes.string,
    dumb_period: PropTypes.number,
    trial: PropTypes.bool,
    landing_page: PropTypes.string,
    credits: PropTypes.array,
    referral_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    support: PropTypes.shape({
      order: PropTypes.shape({
        needs_support: PropTypes.bool,
        date: PropTypes.string,
      }),
      scale: PropTypes.shape({
        needs_support: PropTypes.bool,
        date: PropTypes.string,
      }),
    }),
    paused: PropTypes.bool,
    pausedUntil: PropTypes.string,
    hibernate: PropTypes.bool,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    paidUntil: PropTypes.string,
    scale_status: PropTypes.string,
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    scale_shipments: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};
