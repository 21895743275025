import React from 'react';
import PropTypes from 'prop-types';
import { useToggle } from '@bottomless/common/hooks';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { TriggerOrderForm } from './forms/TriggerOrderForm';

export const TriggerOrder = ({ user, onTrigger, addToast, products, getPendingOrder }) => {
  const [isOpen, toggle] = useToggle();

  return (
    <div>
      <Button onClick={toggle} color="dark" size="sm">
        Trigger order
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} size="lg">
          <ModalHeader toggle={toggle}>Trigger Order</ModalHeader>
          <ModalBody>
            <TriggerOrderForm
              toggleModal={toggle}
              onSubmit={onTrigger}
              user={user}
              addToast={addToast}
              products={products}
              getPendingOrder={getPendingOrder}
            />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

TriggerOrder.propTypes = {
  user: PropTypes.object.isRequired,
  onTrigger: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  products: PropTypes.array,
  getPendingOrder: PropTypes.func.isRequired,
};
