import classNames from 'classnames';
import { get, has } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Link } from 'react-router-dom';
import { Badge, Col, Row, Collapse } from 'reactstrap';
import { DateDiffFormat, Tracking as TrackingNumber } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { StaticTracking } from '../../../../components/UspsTracking/StaticTracking';
import { Box } from '../../../../components/Box';
import { SimpleAddress } from '../../../../components/SimpleAddress';

export const Shipment = ({ shipment, changeStatusToDelivered }) => {
  const [isCollapsed, collapse] = useToggle(true);
  const [hidden, setHidden] = useState(false);

  const onUSPSStatusClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      collapse();
    },
    [collapse]
  );

  const changeToArrived = useCallback(() => {
    changeStatusToDelivered(shipment);
    setHidden(true);
  }, [changeStatusToDelivered, shipment]);

  if (hidden) {
    return null;
  }

  return (
    <Box className="scale-shipment" menuItems={[{ label: 'Change Status to Arrived', action: changeToArrived }]}>
      <Row className="justify-content-center">
        <Col>
          <div>
            <Link to={`/vendor/dashboard/users/${shipment.user._id}`} className="text-primary">
              <span>
                {shipment.user.first_name} {shipment.user.last_name}
              </span>
            </Link>
            <SimpleAddress address={shipment.user.verifiedAddress} />
          </div>
        </Col>
        <Col>
          <div>
            ID: <span className="text-secondary">{shipment._id}</span>
          </div>
          {has(shipment, 'vendor.name') && (
            <>
              {' / '}
              <Badge color="warning">Fulfilled By: {get(shipment, 'vendor.name')}</Badge>
            </>
          )}
          <div>
            Status: <span className="text-secondary">{shipment.status}</span>
          </div>
          <div>
            Shipped: <DateDiffFormat date={shipment.date_shipped} withColor />
          </div>
        </Col>
        <Col>
          <div>
            Tracking: <TrackingNumber number={shipment.tracking_number} label={false} shippingService={'USPS'} />
          </div>

          {shipment.usps_tracking && (
            <div className="usps-status cursor-pointer" onClick={onUSPSStatusClick}>
              New USPS status:{' '}
              <Badge
                color={classNames({
                  danger: ['alert', 'return_to_sender'].includes(shipment.usps_tracking.status),
                  success: shipment.usps_tracking.status === 'delivered',
                  primary: ['accepted', 'in_transit'].includes(shipment.usps_tracking.status),
                  warning: shipment.usps_tracking.status === 'pre_transit',
                  secondary: shipment.usps_tracking.status === 'unknown',
                })}
              >
                {get(shipment, 'usps_tracking.status', '---')}
              </Badge>
              <span className="ml-1">{!isCollapsed ? <ChevronUp size="11" /> : <ChevronDown size="11" />}</span>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Collapse isOpen={!isCollapsed}>
            {shipment.usps_tracking && (
              <div className="mt-3">
                <div>New tracking</div>
                <StaticTracking usps_tracking={shipment.usps_tracking} />
              </div>
            )}
          </Collapse>
        </Col>
      </Row>
    </Box>
  );
};

Shipment.propTypes = {
  shipment: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    tracking_number: PropTypes.string.isRequired,
    tracking_updates: PropTypes.array,
    type: PropTypes.string.isRequired,
    usps_tracking: PropTypes.object,
    date_shipped: PropTypes.string,
    user_id: PropTypes.string,
    user: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      signupDate: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      verifiedAddress: PropTypes.object.isRequired,
    }),
  }).isRequired,
  changeStatusToDelivered: PropTypes.func.isRequired,
};
