import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { addToastAction } from '@bottomless/common/store';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import { getGiftPurchasesAction } from '../../../store';
import { Filters } from './components/Filters';
import { Gift } from './components/Gift';
import './GiftPurchases.scss';

const GiftsPageComponent = ({ getGiftPurchases, addToast, isLoading, giftPurchases, location: { search } }) => {
  useEffect(() => {
    getGiftPurchases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Dashboard title="Gift Purchases">
      <Row className="mb-4">
        <Col>
          <Filters
            isLoading={isLoading}
            hasData={giftPurchases && giftPurchases.docs && giftPurchases.docs.length > 0}
          />
        </Col>
      </Row>

      {giftPurchases &&
        giftPurchases.docs.map(purchase => <Gift key={purchase._id} purchase={purchase} addToast={addToast} />)}
    </Dashboard>
  );
};

GiftsPageComponent.propTypes = {
  getGiftPurchases: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  giftPurchases: PropTypes.shape({
    docs: PropTypes.array.isRequired,
  }),
  isLoading: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export const GiftsPage = connect(
  ({ gift }) => ({
    giftPurchases: gift.data,
    isLoading: gift.isLoading,
  }),
  (dispatch, { location: { search } }) => ({
    getGiftPurchases: params => dispatch(getGiftPurchasesAction({ ...params, ...qs.parse(search) })),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
  })
)(GiftsPageComponent);
