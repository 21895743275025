import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Col, Row } from 'reactstrap';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const NameSchema = Yup.object().shape({
  first_name: Yup.string().required('This field is required'),
  last_name: Yup.string().required('This field is required'),
});

export const UpdateNameForm = ({ user, onSubmit, onSubmitSuccess }) => (
  <Form
    initialValues={{ first_name: user.first_name, last_name: user.last_name }}
    validationSchema={NameSchema}
    onSubmit={onSubmit}
    onSuccess={onSubmitSuccess}
  >
    {({ isSubmitting }) => {
      return (
        <>
          <Row>
            <Col xs="12" sm="6">
              <Field name="first_name" label="First name" />
            </Col>
            <Col xs="12" sm="6">
              <Field name="last_name" label="Last name" />
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <SubmitButton size="sm" color="dark" isSubmitting={isSubmitting}>
              Save
            </SubmitButton>
          </div>
        </>
      );
    }}
  </Form>
);

UpdateNameForm.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
