import { OrderSources, OrderStatuses, SubscriptionType } from '@bottomless/common/constants';
import { useMemo } from 'react';

export const useOrderActions = ({ order, orders, user, cancelAction, skipAction }) => {
  const isSimpleSubscription = useMemo(() => !!user.shopifySubscriptionContractId, [user]);

  const isFixedSimpleSubscription = useMemo(
    () => isSimpleSubscription && user.subscriptionType === SubscriptionType.Fixed,
    [isSimpleSubscription, user]
  );
  const isPrepaidSubscription = useMemo(() => user.subscriptionType === SubscriptionType.Prepaid, [user]);

  const isOneOff = useMemo(() => order.source === OrderSources.USER_ONE_OFF, [order]);

  const pendingOrders = useMemo(
    () =>
      (orders || []).filter(
        o =>
          [OrderStatuses.Initiated, OrderStatuses.SubproductGenerated].includes(o.status) &&
          o.source !== OrderSources.USER_ONE_OFF
      ),
    [orders]
  );

  const isTheOnlyPendingOrder = useMemo(() => pendingOrders.length === 1 && pendingOrders[0]._id === order._id, [
    order,
    pendingOrders,
  ]);

  if (order.status !== OrderStatuses.Initiated) {
    return [];
  }

  if (!isOneOff && isTheOnlyPendingOrder && (isFixedSimpleSubscription || isPrepaidSubscription)) {
    return [{ label: 'Skip order', action: skipAction }];
  }

  return [{ label: 'Cancel order', action: cancelAction }];
};
