import React, { useCallback } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  shopifyNote: Yup.string()
    .max(4000)
    .required('This field is required'),
});

export const UpsertShopifyNoteModal = ({
  user,
  addToast,
  updateUserShopifyNote: updateUserShopifyNoteRaw,
  isModalOpen,
  toggleModal,
  onUpdate,
}) => {
  const updateUserShopifyNote = useCallback(data => updateUserShopifyNoteRaw(user._id, data), [
    user,
    updateUserShopifyNoteRaw,
  ]);

  const handleSuccess = user => {
    onUpdate(user);
    addToast(`Shopify Order Note for user ${user.first_name} ${user.last_name} has been updated.`);
    toggleModal();
  };

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Shopify Order Note</ModalHeader>
      <ModalBody>
        <small className="mb-3">
          This note will appear in the notes section of the Shopify Portal for all future orders.
        </small>
        <Form
          initialValues={{ shopifyNote: user.shopifyNote }}
          onSubmit={updateUserShopifyNote}
          onSuccess={handleSuccess}
          validationSchema={Schema}
        >
          {({ isSubmitting }) => (
            <>
              <Field name="shopifyNote" type="textarea" label="" />
              <SubmitButton color="dark" isSubmitting={isSubmitting}>
                Save
              </SubmitButton>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

UpsertShopifyNoteModal.propTypes = {
  updateUserShopifyNote: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    shopifyNote: PropTypes.string,
    _id: PropTypes.string.isRequired,
  }).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
