import React from 'react';
import PropTypes from 'prop-types';
import { Field, OneLineAddress } from '@bottomless/common/components';

export const BuyerInformation = ({
  setFieldValue,
  values,
  errors,
  shouldShipScaleToBuyer,
  address,
  isGiftRedeemed,
}) => (
  <div className="mb-3 mt-4 get-started-item">
    <div className="d-flex align-items-end justify-content-between">
      <div className="d-flex align-items-center mb-3">
        <h4>Buyer information</h4>
      </div>
    </div>

    <div>
      <div className="buyer-name">
        <Field name="buyer.first_name" label="Your First Name" disabled={isGiftRedeemed} />
        <Field name="buyer.last_name" label="Your Last Name" disabled={isGiftRedeemed} />
      </div>
      <Field name="buyer.email" type="email" label="E-mail Address" disabled={isGiftRedeemed} />
      <Field name="buyer.phone" type="phone" label="Phone (Optional)" disabled={isGiftRedeemed} />
      {(address && address.current) || shouldShipScaleToBuyer ? (
        <div className="buyer-address">
          <OneLineAddress {...{ setFieldValue, values, errors }} fieldPrefix="buyer." disabled={isGiftRedeemed} />
        </div>
      ) : (
        ''
      )}
    </div>
  </div>
);

BuyerInformation.propTypes = {
  shouldShipScaleToBuyer: PropTypes.bool,
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object,
  address: PropTypes.object,
  isGiftRedeemed: PropTypes.bool,
};

BuyerInformation.defaultProps = {
  shouldShipScaleToBuyer: false,
  address: null,
};
