import React from 'react';
import PropTypes from 'prop-types';

export const ProductStatus = {
  Active: 'active',
  Archived: 'archived',
};

export const getColor = product => {
  if (product.ignored) {
    return undefined;
  }

  if (!product.product && !product.ignored && !product.deleted) {
    return 'info';
  }

  if (product.deleted || (product.product && product.product.status === ProductStatus.Archived)) {
    return 'danger';
  }

  if (!product.product || product.vendor_change_set) {
    return 'warning';
  }

  if (product.product && product.product.status === ProductStatus.Active) {
    return 'success';
  }

  return 'secondary';
};

export const Status = ({ product }) => {
  if (!product.product && !product.ignored && !product.deleted) {
    return <span className={`text-${getColor(product)}`}>New</span>;
  }

  if (product.product) {
    if (product.product.status === ProductStatus.Active) {
      return <span className={`text-${getColor(product)}`}>Active</span>;
    }

    if (product.deleted || (product.product && product.product.status === ProductStatus.Archived)) {
      return <span className={`text-${getColor(product)}`}>Archived</span>;
    }

    return <span className={`text-${getColor(product)}`}>Draft</span>;
  }

  return null;
};

Status.propTypes = {
  product: PropTypes.shape({
    product: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
    deleted: PropTypes.bool,
    ignored: PropTypes.bool,
  }).isRequired,
};
