import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isWholeNumber } from '@bottomless/common/utils';
import { InfoTooltip } from '@bottomless/common/components';

const TooltipContent = ({ user, pricingRule }) => {
  const freeShippingThreshold = useMemo(
    () => pricingRule?.free_shipping_threshold && pricingRule.free_shipping_threshold / 100,
    [pricingRule]
  );

  if (isWholeNumber(user.deliveryPrice)) {
    return <div>${user.deliveryPrice}</div>;
  }
  if (pricingRule.free_shipping) {
    return <div>$0</div>;
  }
  if (freeShippingThreshold) {
    return (
      <div>
        <div>
          <span className="font-weight-bold">$0 - ${freeShippingThreshold}</span> - Calculated when order is processed
        </div>
        <div>
          <span className="font-weight-bold">${freeShippingThreshold} and up</span>
          <span> - Free</span>
        </div>
      </div>
    );
  }
  return <div>Calculated when order is processed</div>;
};

TooltipContent.propTypes = {
  user: PropTypes.shape({
    deliveryPrice: PropTypes.number,
  }),
  pricingRule: PropTypes.shape({
    free_shipping_threshold: PropTypes.number,
    free_shipping: PropTypes.bool,
  }),
};

export const ShippingInfo = ({ user }) => {
  const isFreeShipping = user.pricing_rule?.free_shipping || user.deliveryPrice === 0;

  return (
    <div className="d-flex flex-row align-items-center">
      <div>{isWholeNumber(user.deliveryPrice) || isFreeShipping ? 'Fixed' : 'Dynamic'}</div>
      <InfoTooltip className="ml-2" size="sm">
        <TooltipContent user={user} pricingRule={user.pricing_rule} />
      </InfoTooltip>
    </div>
  );
};

ShippingInfo.propTypes = {
  user: PropTypes.shape({
    deliveryPrice: PropTypes.number,
    pricing_rule: PropTypes.shape({
      free_shipping_threshold: PropTypes.number,
      free_shipping: PropTypes.bool,
    }),
  }),
};
