import { debounce, get, pickBy } from 'lodash-es';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, DataLoading, Pagination } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import { getProposedProductsV2Action } from '../../../store';
import { Product } from './components/Product';
import './Products.scss';

const Schema = Yup.object().shape({
  vendor_id: Yup.string(),
  status: Yup.string(),
  rotationPrice: Yup.string(),
  search: Yup.string(),
});

const ProductsPageComponent = ({ getProducts, history, location: { pathname, search } }) => {
  const query = qs.parse(search);
  const formRef = useRef();
  const [products, setProducts] = useState(null);
  const [filters, setFilters] = useState(query);

  useDataEffect(getProducts, setProducts, filters);

  const onFilterChange = (filter, value) => setFilters({ ...filters, [filter]: value });

  useEffect(() => {
    history.push({ pathname, search: qs.stringify(pickBy(filters, v => v && v !== 'null')) });
  }, [filters, history, pathname]);

  const onClearClick = () => {
    setFilters({});
    formRef.current.resetForm({ search: '' });
  };

  const Filters = (
    <Form
      inline
      innerRef={formRef}
      initialValues={{ ...filters }}
      validationSchema={Schema}
      onSubmit={() => {}}
      onSuccess={() => {}}
    >
      {() => (
        <>
          <Row className="w-100 align-items-center">
            <Col className="pr-0">
              <Field
                className="w-100"
                name="search"
                label="Search"
                onChange={debounce(event => {
                  onFilterChange('search', event.target.value);
                }, 200)}
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );

  return (
    <Dashboard>
      <Row className="mb-4">
        <Col xs="12">{Filters}</Col>
      </Row>
      <DataLoading count={(get(products, 'docs') || { length: 0 }).length} isLoading={products === null}>
        <div className="text-center">
          <span className="d-block mb-3">No data matching selected filters.</span>
          <Button size="sm" onClick={onClearClick}>
            Clear filters
          </Button>
        </div>
      </DataLoading>
      {products && (
        <>
          {products.docs.map(product => (
            <Product product={product} key={product._id} />
          ))}
          <div className="mt-2">
            <Pagination collection={products} onPageChange={getProducts} setter={setProducts} />
          </div>
        </>
      )}
    </Dashboard>
  );
};

ProductsPageComponent.propTypes = {
  getProducts: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const ProductsPageV2 = connect(null, (dispatch, { location: { search } }) => ({
  getProducts: () => dispatch(getProposedProductsV2Action({ ...qs.parse(search), limit: 20 })),
}))(ProductsPageComponent);
