import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Radio } from '@bottomless/common/components';
import { DumbType } from '@bottomless/common/constants';

const Schema = Yup.object().shape({
  dumb_type: Yup.string().required('This field is required'),
  dumb_period: Yup.number().when('dumb_type', {
    is: val => val === DumbType.Days,
    then: Yup.number()
      .typeError('Only natural numbers(1,2,3...) are allowed')
      .integer('Only natural numbers(1,2,3...) are allowed')
      .min(1, 'Only natural numbers(1,2,3...) are allowed')
      .required('This field is required'),
    otherwise: Yup.number().notRequired(),
  }),
  dumb_day: Yup.number().when('dumb_type', {
    is: val => val === DumbType.SameDayMonthly,
    then: Yup.number()
      .typeError('Only natural numbers(1,2,3...) are allowed')
      .integer('Only natural numbers(1,2,3...) are allowed')
      .min(1, 'Day must be between 1-28')
      .max(28, 'Day must be between 1-28')
      .required('Day is required'),
    otherwise: Yup.number().notRequired(),
  }),
});

export const DumbPeriodForm = ({ user, onSubmit, onSubmitSuccess }) => (
  <Form
    initialValues={{
      dumb_type: user.dumb_type,
      dumb_period: user.dumb_period,
      dumb_day: user.dumb_day,
    }}
    validationSchema={Schema}
    onSubmit={onSubmit}
    onSuccess={onSubmitSuccess}
  >
    {({ isSubmitting, values }) => (
      <>
        <div className="d-flex mb-3">
          <div className="mr-3">
            <Radio name="dumb_type" type="radio" value={DumbType.Days} label="Days" />
          </div>
          <div>
            <Radio name="dumb_type" type="radio" value={DumbType.SameDayMonthly} label="Same day monthly" />
          </div>
        </div>
        {values.dumb_type === DumbType.Days && <Field name="dumb_period" label="Frequency [days]" type="field" />}
        {values.dumb_type === DumbType.SameDayMonthly && <Field name="dumb_day" label="Day of Month" type="field" />}
        <SubmitButton size="sm" className="mb-2" color="dark" isSubmitting={isSubmitting}>
          Save
        </SubmitButton>
      </>
    )}
  </Form>
);

DumbPeriodForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  updateDumbSubscription: PropTypes.func,
};
