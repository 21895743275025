import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { DataLoading } from '@bottomless/common/components';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { connectWithAbort, addToastAction } from '@bottomless/common/store';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import { tabs } from './components/Tabs';
import {
  syncOrderTrackingAction,
  updateOrderAction,
  getOrderNotesAction,
  getAllTrackingBatchesAction,
} from '../../../store';
import { Batch } from './components/Batch';
import { Filters } from './components/Filters';

import './OrderTracking.scss';
import { useCallback } from 'react';

const OrderTrackingPageComponent = ({
  allBatches,
  getOrders,
  addToast,
  isLoading,
  updateOrder,
  syncOrderTracking,
  location,
  match: {
    params: { status },
  },
  getOrderNotes,
}) => {
  const [orderNotes, setOrderNotes] = useState();
  const [orderIds, setOrderIds] = useState();

  const query = useMemo(() => qs.parse(location.search), [location.search]);

  useEffect(() => {
    getOrders(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, query]);

  useEffect(() => {
    if ((!orderNotes || !orderNotes.length) && allBatches?.length) {
      const orderIds = [];
      allBatches?.length &&
        allBatches.forEach(vendor =>
          vendor.batches.forEach(batch => batch.orders.forEach(order => orderIds.push(order._id)))
        );
      setOrderIds(orderIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBatches]);

  useConditionalDataEffect(orderIds && !orderNotes, getOrderNotes, setOrderNotes, orderIds);

  const onSync = async id => {
    try {
      await syncOrderTracking(id);
      getOrders(status);
      addToast('Order shipping status synced', 'success');
    } catch (e) {
      addToast(`Order sync failed ${e.message}`, 'error');
    }
  };

  const vendorCheck = vendor => {
    return vendor.batches && vendor.batches.map(e => e.orders).flat().length > 0;
  };

  const onCreateNote = useCallback(
    note => {
      setOrderNotes(currNotes => {
        currNotes[note.order] = [note, ...currNotes[note.order]];
        return currNotes;
      });
    },
    [setOrderNotes]
  );

  return (
    <Dashboard title="Order tracking" className="page-order-tracking" tabs={tabs}>
      <div className="mb-4">
        <Filters isLoading={isLoading} hasData={allBatches && allBatches.length > 0} isAll={true} />
      </div>

      <DataLoading count={1} isLoading={isLoading} />

      {allBatches && allBatches.length > 0 && (
        <>
          {allBatches.map(vendor => {
            if (vendorCheck(vendor)) {
              return (
                <div key={vendor._id._id}>
                  {vendor.batches.map(
                    batch =>
                      batch.orders &&
                      batch.orders.length > 0 && (
                        <Batch
                          key={batch._id}
                          batch={batch}
                          onSyncTracking={onSync}
                          onUpdate={updateOrder}
                          addToast={addToast}
                          orderNotes={orderNotes}
                          onCreateNote={onCreateNote}
                        />
                      )
                  )}
                </div>
              );
            }
          })}
        </>
      )}
    </Dashboard>
  );
};

OrderTrackingPageComponent.propTypes = {
  getOrders: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  allBatches: PropTypes.array,
  addToast: PropTypes.func.isRequired,
  syncOrderTracking: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  }).isRequired,
  getOrderNotes: PropTypes.func.isRequired,
};

export const OrderTrackingPage = connectWithAbort(
  ({ order }) => ({
    allBatches: order.trackingBatches,
    isLoading: order.isLoading,
  }),
  (dispatch, { location: { search } }) => ({
    getOrders: (status, params) => dispatch(getAllTrackingBatchesAction(status, { ...params, ...qs.parse(search) })),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    updateOrder: (id, data) => dispatch(updateOrderAction(id, data)),
    syncOrderTracking: id => dispatch(syncOrderTrackingAction(id)),
    getOrderNotes: id => dispatch(getOrderNotesAction(id)),
  })
)(OrderTrackingPageComponent);
