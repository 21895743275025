import React from 'react';
import PropTypes from 'prop-types';
import { useToggle } from '@bottomless/common/hooks';
import { Tooltip } from '@bottomless/common/components';
import { Button } from 'reactstrap';
import { Info } from 'react-feather';
import { UpsertShopifyNoteModal } from './UpsertShopifyNoteModal';

export const CreateShopifyNote = ({ user, addToast, updateUserShopifyNote, onUpdate }) => {
  const [isModalOpen, toggleModal] = useToggle();

  return (
    <div className="mr-2">
      <div className="d-flex align-items-center">
        <Button onClick={toggleModal} color="dark" size="sm" className="mr-2">
          Add Shopify Order Note
        </Button>
        <Tooltip
          content="This note will appear in the notes section of the Shopify Portal for all future orders."
          id="shopify-note-tooltip"
        >
          <Info size={20} />
        </Tooltip>
      </div>

      <UpsertShopifyNoteModal
        user={user}
        addToast={addToast}
        updateUserShopifyNote={updateUserShopifyNote}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        onUpdate={onUpdate}
      />
    </div>
  );
};

CreateShopifyNote.propTypes = {
  user: PropTypes.object.isRequired,
  addToast: PropTypes.func.isRequired,
  updateUserShopifyNote: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
