import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetScaleShipmentTrackingForUserTypes = createAction('Vendor scale shipment', 'Get shipment tracking');

export const getScaleShipmentTrackingForUserAction = userId => ({
  [RSAA]: {
    endpoint: `vendor-portal/scale-shipments/tracking/${userId}`,
    method: 'GET',
    types: [
      GetScaleShipmentTrackingForUserTypes.REQUEST,
      GetScaleShipmentTrackingForUserTypes.SUCCESS,
      GetScaleShipmentTrackingForUserTypes.FAILURE,
    ],
  },
});

export const GetScaleShipmentsTrackingTypes = createAction('Vendor scale shipment', 'Get shipments tracking');

export const getShipmentsTrackingAction = params => ({
  [RSAA]: {
    endpoint: `vendor-portal/scale-shipments/tracking/${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [
      GetScaleShipmentsTrackingTypes.REQUEST,
      GetScaleShipmentsTrackingTypes.SUCCESS,
      GetScaleShipmentsTrackingTypes.FAILURE,
    ],
  },
});

export const SetShipmentToArrivedActionTypes = createAction('Vendor scale shipment', 'Set Scale Shipment to arrived');

export const setShipmentToArrivedAction = id => ({
  [RSAA]: {
    endpoint: `vendor-portal/scale-shipments/${id}/arrived`,
    method: 'POST',
    types: [
      SetShipmentToArrivedActionTypes.REQUEST,
      SetShipmentToArrivedActionTypes.SUCCESS,
      SetShipmentToArrivedActionTypes.FAILURE,
    ],
  },
});
