import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Toasts } from '@bottomless/common/components';
import { Footer } from '../../components/Footer/Footer';
import './Simple.scss';

export const Simple = ({ children, stretch, withFooter, className, footer: Footer }) => (
  <div className={classNames('page-simple', { 'page-simple--stretch': stretch, [className]: className })}>
    <div className="page-content">{children}</div>
    {withFooter && <Footer />}
    <Toasts />
  </div>
);

Simple.defaultProps = {
  stretch: false,
  withFooter: true,
  footer: Footer,
};

Simple.propTypes = {
  children: PropTypes.node.isRequired,
  stretch: PropTypes.bool,
  withFooter: PropTypes.bool,
  className: PropTypes.string,
  footer: PropTypes.elementType,
};
