import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ChevronDown, ChevronUp } from 'react-feather';
import { CreateComment } from './components/CreateComment';
import { Activity } from './components/Activity';
import { TimelineDate } from './components/TimelineDate';
import './ActivityTimeline.scss';

const ACTIVITIES_PER_STEP = 3;
const ActivityTimelineComponent = ({ activities, createComment, onCreate }) => {
  const [visibleActivities, setVisibleActivities] = useState([]);
  const [hiddenActivities, setHiddenActivities] = useState([]);
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const [showLessVisible, setShowLessVisible] = useState(false);

  useEffect(() => {
    if (activities) {
      const allActivities = [...activities];
      const initialVisibleActivities = allActivities.splice(0, ACTIVITIES_PER_STEP);
      setVisibleActivities(initialVisibleActivities);
      setHiddenActivities(allActivities);
      setShowMoreVisible(allActivities.length > 0);
      setShowLessVisible(false);
    }
  }, [activities]);

  const handleShowMore = () => {
    const nextActivities = hiddenActivities.slice(0, ACTIVITIES_PER_STEP);
    setVisibleActivities([...visibleActivities, ...nextActivities]);
    setHiddenActivities(hiddenActivities.slice(ACTIVITIES_PER_STEP));
    setShowLessVisible(true);
    setShowMoreVisible(hiddenActivities.length > ACTIVITIES_PER_STEP);
  };

  const handleShowLess = () => {
    const initialVisibleActivities = activities.slice(0, ACTIVITIES_PER_STEP);
    const remainingHiddenActivities = activities.slice(ACTIVITIES_PER_STEP);
    setVisibleActivities(initialVisibleActivities);
    setHiddenActivities(remainingHiddenActivities);
    setShowMoreVisible(true);
    setShowLessVisible(false);
  };

  const renderActivitiesWithDates = activitiesToRender => {
    let lastDate = null;
    return activitiesToRender.map(activity => {
      const currentDate = new Date(activity.created_at).toDateString();
      const showDate = currentDate !== lastDate;
      lastDate = currentDate;

      return (
        <React.Fragment key={activity._id}>
          {showDate && <TimelineDate date={activity.created_at} />}
          <Activity activity={activity} isComment={activity.type === 'vendor_comment'} />
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h4 className="font-weight-bold mb-0">Activity</h4>
      </div>
      <div>
        <CreateComment onSubmit={data => createComment(data)} onSuccess={onCreate} />
        {renderActivitiesWithDates(visibleActivities)}
        {(showMoreVisible || showLessVisible) && (
          <div className="mb-auto mt-4">
            {showMoreVisible && (
              <a className="text-primary cursor-pointer mr-3" onClick={handleShowMore}>
                Show More <ChevronDown size={24} />
              </a>
            )}
            {showLessVisible && (
              <a className="text-secondary cursor-pointer" onClick={handleShowLess}>
                Show Less <ChevronUp size={24} />
              </a>
            )}
          </div>
        )}
      </div>
    </>
  );
};

ActivityTimelineComponent.propTypes = {
  createComment: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
};

export const ActivityTimeline = connect(null, () => ({}))(memo(ActivityTimelineComponent));
