import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import classNames from 'classnames';
import { DateFormat, Price } from '@bottomless/common/components';
import { BatchShipmentHistory } from './BatchShipmentHistory';
import { Box, BoxCollapsible } from '../../../../components/Box';

export const Payout = ({ payouts, payoutId, generateReceipt, addToast }) => {
  const regenerateReceipt = useCallback(async () => {
    try {
      const batchIds = payouts[payoutId].batches.map(batch => batch._id);
      const {
        error,
        payload: { status },
      } = await generateReceipt({ batchIds });
      if (!error && ![404, 401].includes(status)) {
        addToast('Receipt generated.');
      }
    } catch (e) {
      addToast('Unable to generate receipt.');
    }
  }, [payouts, payoutId, generateReceipt, addToast]);

  return (
    <Box menuItems={[{ label: 'Regenerate Receipt', action: regenerateReceipt }]} menuStyle="buttons">
      <Row className="d-flex justify-content-between align-items-center pl-3 pr-3 mb-1 mt-2">
        <div className="payout-size">
          <DateFormat date={payouts[payoutId].batches[0].payout_date} />
          <div className="small text-secondary">
            <Price value={payouts[payoutId].summary} cents />
          </div>
        </div>
      </Row>
      <BoxCollapsible>
        {payouts[payoutId].batches.map((batch, i, all) => (
          <div key={batch._id} className={classNames({ 'border-bottom': i < all.length - 1 })}>
            <BatchShipmentHistory batch={batch} />
          </div>
        ))}
      </BoxCollapsible>
    </Box>
  );
};

Payout.propTypes = {
  generateReceipt: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  payouts: PropTypes.object.isRequired,
  payoutId: PropTypes.string.isRequired,
};
