import { GetGiftPurchasesTypes, GetGiftPurchaseTypes, UpdateGiftPurchaseTypes } from './gift.actions';

const initialState = {
  data: {
    docs: [],
  },
  isLoading: false,
  selectedGift: null,
  isLoadingSelectedGift: false,
};

export const giftsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetGiftPurchasesTypes.REQUEST:
      return { ...initialState, isLoading: true };
    case GetGiftPurchasesTypes.SUCCESS:
      return {
        data: action.payload,
        isLoading: false,
      };
    case GetGiftPurchasesTypes.FAILURE:
      return { ...initialState, isLoading: false };
    case GetGiftPurchaseTypes.REQUEST:
      return { ...initialState, isLoadingSelectedGift: true };
    case GetGiftPurchaseTypes.SUCCESS:
      return {
        selectedGift: action.payload,
        isLoadingSelectedGift: false,
      };
    case GetGiftPurchaseTypes.FAILURE:
      return { ...initialState, isLoadingSelectedGift: false };

    case UpdateGiftPurchaseTypes.SUCCESS: {
      return {
        selectedGift: action.payload,
      };
    }
    default:
      return state;
  }
};
