import React from 'react';
import PropTypes from 'prop-types';
import { Form, SubmitButton } from '@bottomless/common/components';
import { useCallback } from 'react';

export const ProcessPaymentForm = ({ order, processOrder, addToast, toggleModal, setOrder, isGiftSubscription }) => {
  const onSuccess = useCallback(
    data => {
      setOrder(data);
      addToast(`Order has been processed`);
      toggleModal();
    },
    [addToast, toggleModal, setOrder]
  );

  const onSubmit = useCallback(() => processOrder(order._id), [order, processOrder]);

  return (
    <Form onSubmit={onSubmit} onSuccess={onSuccess}>
      {({ isSubmitting }) => (
        <>
          <div>
            {isGiftSubscription
              ? 'The order will be synced with Shopify'
              : 'This user will be charged and the order will be synced with Shopify'}
          </div>
          <hr />

          <div className="mt-4">
            <SubmitButton color="dark" isSubmitting={isSubmitting} loadingText="Processing" withLoader>
              Process
            </SubmitButton>
          </div>
        </>
      )}
    </Form>
  );
};

ProcessPaymentForm.propTypes = {
  order: PropTypes.object.isRequired,
  processOrder: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  setOrder: PropTypes.func.isRequired,
  isGiftSubscription: PropTypes.bool,
};
