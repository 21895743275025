import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { DateFormat } from '@bottomless/common/components';

export const FulifillmentDate = ({ dateFulfilled }) => {
  if (!dateFulfilled) {
    return '---';
  }

  const hoursDiff = moment().diff(moment(dateFulfilled), 'hours');
  const diff = hoursDiff < 72 ? `${hoursDiff} hours ago` : moment(dateFulfilled).fromNow();

  return (
    <>
      <DateFormat date={dateFulfilled} /> ({diff})
    </>
  );
};

FulifillmentDate.propTypes = {
  dateFulfilled: PropTypes.string,
};
