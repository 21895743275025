import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { SubmitButton } from '@bottomless/common/components';
import { useDataEffect, useQueryString } from '@bottomless/common/hooks';
import { PendingStripe } from './PendingStripe';

const ACCOUNTING_STATUS = {
  INVITED: 'invited',
  ACTIVE: 'active',
  PENDING: 'pending',
};

export const Stripe = ({ vendor, getStripeLink, addToast }) => {
  const queryString = useQueryString();
  const [isRequesting, setRequesting] = useState(false);
  const getterFunction = useCallback(() => (isRequesting ? getStripeLink() : undefined), [getStripeLink, isRequesting]);
  const isFinished = useMemo(() => !!queryString.params.finished, [queryString.params]);

  const { data } = useDataEffect(getterFunction, () => {}, undefined, {}, [isRequesting]);

  const onCreateAccountClick = useCallback(() => setRequesting(true), [setRequesting]);

  useEffect(() => {
    if (data && data.link) {
      window.location = data.link;
    }
  }, [data]);

  useEffect(() => {
    if (isFinished) {
      addToast('Your Stripe account has been updated');
      queryString.update({ finished: undefined });
    }
  }, [isFinished, addToast, queryString, queryString.update]);

  return (
    <div>
      {vendor && (
        <div className="mb-5 text-center">
          <Alert color="info">
            {isFinished ||
              (vendor.accounting.status === ACCOUNTING_STATUS.PENDING && (
                <>
                  <div className="text-info mb-4">Your Stripe account is pending.</div>
                  <PendingStripe vendor={vendor} />
                  <div>
                    <SubmitButton
                      size="sm"
                      color="success"
                      isSubmitting={isRequesting}
                      onClick={onCreateAccountClick}
                      loadingText="Preparing a redirect"
                    >
                      Update account
                    </SubmitButton>
                  </div>
                </>
              ))}
            {vendor.accounting.status === ACCOUNTING_STATUS.ACTIVE && (
              <div>Your Stripe account is active. You will receive your payout once a month.</div>
            )}
            {!isFinished && vendor.accounting.status === ACCOUNTING_STATUS.INVITED && (
              <>
                <div className="mb-4">You have a pending Stripe account invitation.</div>
                <div>
                  <SubmitButton
                    size="sm"
                    color="success"
                    isSubmitting={isRequesting}
                    onClick={onCreateAccountClick}
                    loadingText="Preparing a redirect"
                  >
                    Create account
                  </SubmitButton>
                </div>
              </>
            )}
            {!vendor.accounting.status && (
              <div>You don&apos;t have any pending intites to Stripe. Contact Bottomless.</div>
            )}
          </Alert>
        </div>
      )}
    </div>
  );
};

Stripe.propTypes = {
  vendor: PropTypes.shape({
    accounting: {
      stripe_connected_account: PropTypes.string,
      status: PropTypes.string,
    },
  }).isRequired,
  getStripeLink: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};
