import React from 'react';
import PropTypes from 'prop-types';
import { OrderStatuses } from '@bottomless/common/src/constants';
import { useMemo } from 'react';
import { Badge } from 'reactstrap';

export const statusMap = {
  [OrderStatuses.SentToRoaster]: 'processed',
  [OrderStatuses.SubproductGenerated]: OrderStatuses.Initiated,
};

const STATUS_PROCESSING_FAILURE = 'Processing Failure';

export const OrderStatus = ({ order, fulfillmentError }) => {
  const status = useMemo(() => {
    if (fulfillmentError) {
      return STATUS_PROCESSING_FAILURE;
    }

    const mappedStatus = statusMap[order.status] || order.status;

    return mappedStatus.replace(/_/g, ' ');
  }, [order, fulfillmentError]);

  const color = useMemo(() => {
    if (status === STATUS_PROCESSING_FAILURE) {
      return 'danger';
    }

    switch (order.status) {
      case OrderStatuses.Fulfilled:
        return 'success';
      case OrderStatuses.Cancelled:
      case OrderStatuses.Refunded:
      case OrderStatuses.Replaced:
      case OrderStatuses.Offboarded:
        return 'danger';
      case OrderStatuses.Initiated:
      case OrderStatuses.SubproductGenerated:
      case OrderStatuses.Paid:
      case OrderStatuses.Shipment:
      case OrderStatuses.WithoutShipment:
        return 'warning';
      default:
        return 'info';
    }
  }, [order, status]);

  return (
    <Badge pill color={color} className="text-capitalize">
      {status}
    </Badge>
  );
};

OrderStatus.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  fulfillmentError: PropTypes.string,
};
