import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetOrdersTypes = createAction('Vendor Orders', 'Get orders');

export const getOrdersAction = params => ({
  [RSAA]: {
    endpoint: `vendor-portal/orders${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetOrdersTypes.REQUEST, GetOrdersTypes.SUCCESS, GetOrdersTypes.FAILURE],
  },
});

export const GetPendingOrdersTypes = createAction('Vendor Orders', 'Get pending orders');

export const getPendingOrderAction = id => ({
  [RSAA]: {
    endpoint: `vendor-portal/orders/${id}/pending`,
    method: 'GET',
    types: [GetPendingOrdersTypes.REQUEST, GetPendingOrdersTypes.SUCCESS, GetPendingOrdersTypes.FAILURE],
  },
});

export const UpdateOrderTypes = createAction('Vendor Orders', 'Charge order');

export const updateOrderAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/orders/${id}`,
    method: 'PATCH',
    body: data,
    types: [UpdateOrderTypes.REQUEST, UpdateOrderTypes.SUCCESS, UpdateOrderTypes.FAILURE],
  },
});

export const ReplaceOrderTypes = createAction('Vendor Orders', 'Replace order');

export const replaceOrderAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/orders/${id}/replace-order`,
    method: 'POST',
    body: data,
    types: [ReplaceOrderTypes.REQUEST, ReplaceOrderTypes.SUCCESS, ReplaceOrderTypes.FAILURE],
  },
});

export const SyncOrderTrackingTypes = createAction('Vendor Orders', 'Sync tracking');

export const syncOrderTrackingAction = id => ({
  [RSAA]: {
    endpoint: `vendor-portal/orders/${id}/tracking/sync`,
    method: 'POST',
    types: [SyncOrderTrackingTypes.REQUEST, SyncOrderTrackingTypes.SUCCESS, SyncOrderTrackingTypes.FAILURE],
  },
});

export const GetOrderNotesTypes = createAction('Vendor Orders', 'Get order notes');

export const getOrderNotesAction = ids => ({
  [RSAA]: {
    endpoint: 'vendor-portal/orders/notes',
    method: 'POST',
    body: { ids },
    types: [GetOrderNotesTypes.REQUEST, GetOrderNotesTypes.SUCCESS, GetOrderNotesTypes.FAILURE],
  },
});

export const GetAllTrackingBatchesTypes = createAction('Vendor Orders', 'Get all tracking batches');

export const getAllTrackingBatchesAction = (status, params) => ({
  [RSAA]: {
    endpoint: `vendor-portal/orders/tracking/batch/${status}${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetAllTrackingBatchesTypes.REQUEST, GetAllTrackingBatchesTypes.SUCCESS, GetAllTrackingBatchesTypes.FAILURE],
  },
});

export const CreateOrderNoteTypes = createAction('Vendor Orders', 'Create note');

export const createOrderNoteAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/orders/${id}/note`,
    method: 'POST',
    body: data,
    types: [CreateOrderNoteTypes.REQUEST, CreateOrderNoteTypes.SUCCESS, CreateOrderNoteTypes.FAILURE],
  },
});

export const TriggerOrderTypes = createAction('Vendor OrderGen Instance', 'Create');

export const triggerOrderAction = data => ({
  [RSAA]: {
    endpoint: `vendor-portal/orders/trigger`,
    method: 'POST',
    body: data,
    types: [TriggerOrderTypes.REQUEST, TriggerOrderTypes.SUCCESS, TriggerOrderTypes.FAILURE],
  },
});

export const ProcessOrderTypes = createAction('Vendor Orders', 'Process order');

export const processOrderAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/orders/${id}/process`,
    method: 'POST',
    body: data,
    types: [ProcessOrderTypes.REQUEST, ProcessOrderTypes.SUCCESS, ProcessOrderTypes.FAILURE],
  },
});

export const CancelOrderTypes = createAction('Vendor Orders', 'Cancel order');

export const cancelOrderAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/orders/${id}/cancel`,
    method: 'POST',
    body: data,
    types: [CancelOrderTypes.REQUEST, CancelOrderTypes.SUCCESS, CancelOrderTypes.FAILURE],
  },
});
