import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { Price, ProductName, UserAvatar, VariantPrice } from '@bottomless/common/components';

export const LineItems = ({ user }) => {
  const [isOpen, toggle] = useToggle();

  const getVariant = useCallback(
    productVariant => productVariant.product.variants.find(variant => variant._id === productVariant.variant),
    []
  );

  if (!user.lineItems?.length) {
    return null;
  }

  const renderDynamicPrice = user => {
    const { personalized, dynamicPricing, pricing_rule } = user || {};
    if (personalized && personalized.price_type && dynamicPricing) {
      return (
        <div className="text-secondary text-sm">
          <Price value={dynamicPricing.price} pricingRule={pricing_rule} />
        </div>
      );
    }
    return '';
  };

  return (
    <>
      <Button color="link" size="sm" onClick={toggle} className="pl-0">
        +{user.lineItems.length} more
      </Button>

      {isOpen && (
        <Modal unmountOnClose className="modal-line-items" isOpen={isOpen} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}>Products</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <UserAvatar user={user} height="70" />
                <div className="ml-3">
                  <ProductName
                    product={user.product}
                    personalized={user.personalized}
                    isSubproduct={true}
                    quantity={user.quantity || 1}
                    productVariant={user.product}
                    grind={user.grind?.name}
                  />
                </div>
              </div>
              <div className="text-right">
                {user.product.product && user.product.product.rotating && user.personalized?.price_type ? (
                  user.personalized.price_type.replace(/^\w/, c => c.toUpperCase())
                ) : (
                  <VariantPrice user={user} variant={getVariant(user.product)} pricingRule={user.pricing_rule} />
                )}
              </div>
              {user.product.product && user.product.product.rotating ? renderDynamicPrice(user) : ''}
            </div>

            {user.lineItems.map(lineItem => (
              <>
                <hr />
                <div key={lineItem._id} className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <UserAvatar
                      user={{ ...lineItem, product: { product: lineItem.product, variant: lineItem.variant } }}
                      height="70"
                    />
                    <div className="ml-3">
                      <ProductName
                        product={lineItem.product}
                        isSubproduct={true}
                        quantity={lineItem.quantity}
                        productVariant={lineItem}
                        grind={lineItem.grind?.name}
                      />
                    </div>
                  </div>
                  <div className="text-right">
                    <VariantPrice user={user} variant={getVariant(lineItem)} pricingRule={user.pricing_rule} />
                  </div>
                </div>
              </>
            ))}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

LineItems.propTypes = {
  user: PropTypes.shape({
    product: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
        vendor_id: PropTypes.object,
        rotating: PropTypes.bool,
        category: PropTypes.shape({
          name: PropTypes.string,
        }),
        personalized_rotation: PropTypes.bool,
      }),
      variant: PropTypes.string.isRequired,
    }).isRequired,
    quantity: PropTypes.number,
    personalized: PropTypes.shape({
      price_type: PropTypes.string,
    }),
    grind: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        product: PropTypes.object,
      })
    ),
    pricing_rule: PropTypes.object.isRequired,
  }).isRequired,
};
