import { GetScaleShipmentsTrackingTypes } from './scale-shipment.actions';

const initialState = {
  data: {
    docs: [],
    total: 0,
    limit: 100,
    pages: 0,
    page: 0,
  },
  batches: [],
  isLoading: false,
  isV2data: false,
};

export const scaleShipmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetScaleShipmentsTrackingTypes.REQUEST:
      return { ...state, batches: [], isLoading: true };

    case GetScaleShipmentsTrackingTypes.SUCCESS:
      return {
        ...state,
        batches: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
