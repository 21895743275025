import PropTypes from 'prop-types';
import React, { useState, useMemo, useCallback } from 'react';
import { get, has } from 'lodash-es';
import { Edit2, CreditCard, AtSign, Phone } from 'react-feather';
import { Button, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {
  DateFormat,
  ProductName,
  Tracking,
  Price,
  VariantPrice,
  CopyToClipboard,
  Address,
  InfoTooltip,
  PhoneNumberFormat,
} from '@bottomless/common/components';
import { CustomerNotes } from '../../../../components/CustomerNotes/CustomerNotes';
import { useToggle } from '@bottomless/common/hooks';
import {
  DumbPeriodForm,
  UpdateStatusForm,
  UpdateAddressForm,
  UpdateEmailForm,
  UpdatePhoneForm,
  MagicLinkForm,
  UpdateNameForm,
  UpdateProductForm,
  UpdateSubscriptionTypeForm,
} from './forms';
import { UserStatus } from '../../../../components/UserStatus';
import { LineItems } from './LineItems';
import { ShippingPriceForm } from './forms/ShippingPriceForm';
import { DumbType, SubscriptionType, UserStatuses } from '@bottomless/common/src/constants';
import { Box } from '../../../../components/Box';
import { ScaleStatus } from '../../../../components/ScaleStatus';
import { UserSubscriptionType } from './UserSubscriptionType';
import { NextReorder } from './NextReorder';
import { ShippingInfo } from './ShippingInfo';

const FORM_DUMB_PERIODS = 'dumb_period';
const FORM_MAGIC_LINK = 'magic_link';
const FORM_UPDATE_EMAIL = 'update_email';
const FORM_UPDATE_PHONE = 'update_phone';
const FORM_UPDATE_ADDRESS = 'update_address';
const FORM_UPDATE_STATUS = 'update_status';
const FORM_SHIPPING_PRICE = 'shipping_price';
const FORM_UPDATE_NAME = 'name';
const FORM_UPDATE_PRODUCT = 'update_product';
const FORM_UPDATE_SUBSCRIPTION_TYPE = 'update_subscription_type';

const ActionButton = ({ onClick, text, color, disabled }) => (
  <div className="d-flex flex-row">
    <Button className="w-100 mb-1" onClick={onClick} size="sm" color={color || 'info'} disabled={disabled}>
      {text}
    </Button>
  </div>
);

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
};

export const User = ({
  user,
  setUser,
  onCreateNote,
  addToast,
  updateEmail,
  updatePhone,
  updateAddress,
  updateAccountStatus,
  updateDumbSubscription,
  sendPaymentUpdateLink,
  vendor,
  createMagicLink,
  updateShipping,
  updateName,
  orders,
  skipOrder,
  products,
  options,
  updateProduct,
  getShopifyUserPaymentMethods,
  mergeShopifyAccounts,
  getPickupLocations,
  getScaleShipmentTrackingForUser,
}) => {
  const [isOpen, toggle] = useToggle();
  const [type, setType] = useState();
  const [isSendingPaymentLink, setSendingPaymentLink] = useState();
  const [magicLink, setMagicLink] = useState(null);

  const isBottomlessAccount = useMemo(() => (user?.local?.email ? user.local.email.match('@bottomless.com') : true), [
    user,
  ]);

  const generatePaymentUpdateLink = useCallback(async () => {
    setSendingPaymentLink(true);
    try {
      await sendPaymentUpdateLink(user._id);
      addToast(`Payment update link sent to ${user.local.email}`, 'success');
    } catch (err) {
      addToast(`Oops, something went wrong with ${user.first_name} ${user.last_name}.`);
    }
    setSendingPaymentLink(false);
  }, [sendPaymentUpdateLink, user, addToast]);

  const variant = user.product.product.variants.find(variant => variant._id === user.product.variant);

  const { order } = user;

  const userNotes = useMemo(
    () => ({
      ...user,
      notes: user.notes
        .filter(note => {
          if (note.content?.match(/Sticky note/)) {
            return false;
          }
          return true;
        })
        .map(note => {
          if (note.content?.match(/Template:.*\n.*Title:.*/)) {
            return {
              ...note,
              content: `Notification Sent: \n${note.content}`,
            };
          }
          return note;
        }),
    }),
    [user]
  );

  const renderDynamicPrice = user => {
    const { personalized, dynamicPricing, pricing_rule } = user || {};
    if (personalized && personalized.price_type && dynamicPricing) {
      return (
        <div className="text-secondary text-sm">
          <Price value={dynamicPricing.price} pricingRule={pricing_rule} />
        </div>
      );
    }
    return '';
  };

  const openForm = useCallback(
    type => {
      setType(type);
      toggle();
    },
    [setType, toggle]
  );

  const onDataUpdate = useCallback(
    (e, type, message) => {
      if (e && e._id === user._id) {
        setUser(e);
        toggle();
        addToast(message || `${type} updated`, 'success');
      } else {
        addToast(`Could not update ${type || ''}`, 'danger');
      }
    },
    [addToast, toggle, user._id, setUser]
  );

  const shopifyUrl = useMemo(() => {
    if (!vendor.scraper?.url || !user.shopifyCustomerId) {
      return;
    }

    let baseUrl = vendor.scraper?.url;
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.slice(0, -1);
    }

    return `${baseUrl}/admin/customers/${user.shopifyCustomerId.split('/').pop()}`;
  }, [user, vendor]);

  return (
    <>
      <div className={`ordergen-user mb-3`}>
        <Row>
          <Col xs="12" lg="5" className="mb-4 d-flex flex-column">
            <h4 className="font-weight-bold mb-3">Customer details</h4>
            <Box className="flex-grow-1 mb-0 card-user">
              <div>
                <div className="mb-4 d-flex align-items-center justify-content-between">
                  <div>
                    <span className="font-weight-semi-bold">
                      {user.first_name} {user.last_name}
                    </span>
                    {shopifyUrl && (
                      <span className="view-in-shopify ml-2">
                        <a href={shopifyUrl} rel="noopener noreferrer" target="_blank" className="small text-primary">
                          View in Shopify
                        </a>
                      </span>
                    )}
                  </div>
                  <Button size="sm" color="white" onClick={() => openForm(FORM_UPDATE_NAME)}>
                    <Edit2 size="14" />
                  </Button>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <AtSign size="14" />
                    <a
                      href={`mailto:${user.local.email}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text-muted ml-1"
                    >
                      {user.local.email}
                    </a>
                  </div>
                  <Button size="sm" color="white" onClick={() => openForm(FORM_UPDATE_EMAIL)}>
                    <Edit2 size="14" />
                  </Button>
                </div>
                {user.phone && (
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="d-flex align-items-center">
                      <Phone size="14" />
                      <a className="ml-1" href={`tel:${user.phone}`}>
                        <PhoneNumberFormat phone={user.phone} />
                      </a>
                    </div>
                    <Button size="sm" color="white" onClick={() => openForm(FORM_UPDATE_PHONE)}>
                      <Edit2 size="14" />
                    </Button>
                  </div>
                )}
                {user.verifiedAddress && (
                  <div className="mt-4">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <div className="font-weight-semi-bold">
                        {user.verifiedAddress.pickup ? 'Pickup' : 'Shipping'} address
                      </div>
                      <Button size="sm" color="white" onClick={() => openForm(FORM_UPDATE_ADDRESS)}>
                        <Edit2 size="14" />
                      </Button>
                    </div>
                    <div>
                      <Address address={user.verifiedAddress} />
                    </div>
                  </div>
                )}
              </div>

              <div className="text-center mt-4">
                <ActionButton
                  onClick={() => openForm(FORM_MAGIC_LINK)}
                  color="dark"
                  text="Magic Link"
                  disabled={isBottomlessAccount}
                />
              </div>
            </Box>
          </Col>
          <Col xs="12" lg="7" className="mb-4 d-flex flex-column">
            <h4 className="font-weight-bold mb-3">Subscription details</h4>
            <Box className="flex-grow-1 box-long mb-0" actionsUp>
              <Row>
                <Col xs="6">
                  <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <div className="font-weight-semi-bold">Subscription type</div>
                      <Button size="sm" color="white" onClick={() => openForm(FORM_UPDATE_SUBSCRIPTION_TYPE)}>
                        <Edit2 size="14" />
                      </Button>
                    </div>
                    <div>
                      <UserSubscriptionType user={user} />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <div className="font-weight-semi-bold">Status</div>
                      {user.status !== UserStatuses.Closed && (
                        <Button size="sm" color="white" onClick={() => openForm(FORM_UPDATE_STATUS)}>
                          <Edit2 size="14" />
                        </Button>
                      )}
                    </div>
                    <div>
                      <UserStatus
                        user={user}
                        showScaleShipmentStatus
                        getScaleShipmentTrackingForUser={getScaleShipmentTrackingForUser}
                      />
                    </div>
                    {user.pausedUntil && !user.hibernate && user.status !== UserStatuses.Closed && (
                      <div className="mt-2">
                        <span>Until</span>{' '}
                        <span>
                          <DateFormat date={user.pausedUntil} fromZeroed />
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="product-details mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <div className="font-weight-semi-bold">Product</div>
                      <Button size="sm" color="white" onClick={() => openForm(FORM_UPDATE_PRODUCT)}>
                        <Edit2 size="14" />
                      </Button>
                    </div>
                    <div>
                      <ProductName
                        product={user.product}
                        personalized={user.personalized}
                        isSubproduct={true}
                        hideVendor
                        quantity={user.quantity ? user.quantity : undefined}
                        productVariant={user.product}
                        grind={user.grind?.name}
                      />
                      <div className="d-flex align-items-center variant-price">
                        {user.product.product &&
                        user.product.product.rotating &&
                        has(user.personalized, 'price_type') ? (
                          get(user.personalized, 'price_type').replace(/^\w/, c => c.toUpperCase())
                        ) : (
                          <VariantPrice user={user} variant={variant} pricingRule={user.pricing_rule} />
                        )}
                        {user.product.product && user.product.product.rotating ? renderDynamicPrice(user) : ''}
                        <InfoTooltip className="ml-2 discount-tooltip" size="sm">
                          Discount from pricing rule
                        </InfoTooltip>
                      </div>
                      <LineItems user={user} />
                    </div>
                  </div>

                  <div>
                    <div className="font-weight-semi-bold mb-2">Payment method</div>
                    <span className="d-flex align-items-center">
                      <CreditCard size={16} className="mr-2" /> {get(user, 'stripe_brand', '---')}{' '}
                      {get(user, 'stripe_last_four', '---')}
                    </span>
                    {vendor?.shopifyManifest?.accountPageId &&
                      vendor.shopifyManifest?.storefrontUrl &&
                      !isBottomlessAccount && (
                        <Button
                          size="sm"
                          color="link"
                          onClick={generatePaymentUpdateLink}
                          disabled={isSendingPaymentLink}
                        >
                          {isSendingPaymentLink ? 'Sending link...' : 'Update'}
                        </Button>
                      )}
                  </div>
                </Col>
                <Col xs={{ offset: 1, size: 5 }}>
                  {user.signupDate && (
                    <div>
                      <div className="font-weight-semi-bold mb-2">Signup date</div>
                      <div>
                        <DateFormat date={user.signupDate} />
                      </div>
                    </div>
                  )}
                  {user.subscriptionType !== SubscriptionType.Dynamic && (
                    <div className="mt-4">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="font-weight-semi-bold">Frequency</div>
                        <Button size="sm" color="white" onClick={() => openForm(FORM_DUMB_PERIODS)}>
                          <Edit2 size="14" />
                        </Button>
                      </div>
                      <div>
                        {user.dumb_type === DumbType.SameDayMonthly
                          ? `Monthly on ${user.dumb_day}`
                          : `${user.dumb_period} days`}
                      </div>
                    </div>
                  )}
                  {orders && (
                    <NextReorder
                      vendor={vendor}
                      user={user}
                      orders={orders}
                      skipOrder={skipOrder}
                      addToast={addToast}
                    />
                  )}
                  {user.subscriptionType === SubscriptionType.Dynamic && (
                    <>
                      <div className="mt-4">
                        <div className="font-weight-semi-bold mb-2">Ordering strategy</div>
                        <div>{aggressionName(user.ordering_aggression || 2)}</div>
                      </div>
                      {user.base_id && (
                        <div className="mt-4">
                          <div className="font-weight-semi-bold mb-2">Scale status</div>
                          <div>
                            <ScaleStatus status={user.scale_status ? user : { scale_status: 'disconnected' }} />
                          </div>
                        </div>
                      )}
                      {!vendor.shopifyManifest.accountPageId && user.name && (
                        <div className="mt-4">
                          <div className="font-weight-semi-bold mb-2">Account name</div>
                          <div>{user.name}</div>
                        </div>
                      )}
                    </>
                  )}
                  {user.shopifySubscriptionContractId && (
                    <div className="mt-4">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="font-weight-semi-bold">Shipping price</div>
                        <Button size="sm" color="white" onClick={() => openForm(FORM_SHIPPING_PRICE)}>
                          <Edit2 size="14" />
                        </Button>
                      </div>
                      <div>
                        <ShippingInfo user={user} pricingRule={user.pricing_rule} />
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>

        <div>
          {order && (
            <CardBody>
              <div>
                {order.subvendor_id && <div className="text-secondary text-sm">{order.subvendor_id.name}</div>}
                {order.subproduct_name && <div className="mb-2">{order.subproduct_name}</div>}
                <div className="text-sm">OrderId: {order._id}</div>
                <div className="text-sm">
                  Override fulfillment date:{' '}
                  {order.override_fulfillment_date ? <DateFormat date={order.override_fulfillment_date} /> : '---'}
                </div>
                {order.tracking_number && (
                  <div className="text-sm">
                    <Tracking
                      number={order.tracking_number}
                      shippingService={order.shipping_service}
                      trackingUrl={order.tracking_url}
                    />
                  </div>
                )}
              </div>
            </CardBody>
          )}

          <Row>
            <Col>
              <CustomerNotes user={userNotes} onCreate={onCreateNote} />
            </Col>
          </Row>
        </div>
      </div>
      {isOpen && (
        <Modal isOpen={isOpen} size={type === FORM_UPDATE_PRODUCT ? 'lg' : undefined}>
          {type === FORM_MAGIC_LINK && (
            <>
              <ModalHeader toggle={toggle}>
                <span>Magic link</span>
              </ModalHeader>
              <ModalBody>
                {!magicLink && (
                  <MagicLinkForm
                    onSubmit={data => createMagicLink(user._id, data)}
                    onSubmitSuccess={data => {
                      addToast('Magic link created');
                      setMagicLink(data);
                    }}
                  />
                )}

                {magicLink && (
                  <>
                    <div className="d-flex flex-column text-sm mb-4">
                      <div className="mb-2">
                        <code>{magicLink.url}</code>
                      </div>
                      <div>
                        <CopyToClipboard text={magicLink.url} />
                      </div>
                    </div>

                    {magicLink.short && (
                      <div className="d-flex flex-column text-sm">
                        <div className="mb-2">
                          <code>{magicLink.short.url}</code>
                        </div>
                        <div>
                          <CopyToClipboard text={magicLink.short.url} />
                        </div>
                      </div>
                    )}

                    <div className="mt-3">
                      <Button onClick={() => setMagicLink(null)} color="success">
                        Create new
                      </Button>
                    </div>
                  </>
                )}
              </ModalBody>
            </>
          )}

          {type === FORM_UPDATE_EMAIL && (
            <>
              <ModalHeader toggle={toggle}>Update Email</ModalHeader>
              <ModalBody>
                <UpdateEmailForm
                  user={user}
                  onSubmit={data => updateEmail(user._id, data)}
                  onSubmitSuccess={e => onDataUpdate(e, 'Email')}
                  getShopifyUserPaymentMethods={getShopifyUserPaymentMethods}
                  mergeShopifyAccounts={data => mergeShopifyAccounts(user._id, data)}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_UPDATE_PHONE && (
            <>
              <ModalHeader toggle={toggle}>Update Phone</ModalHeader>
              <ModalBody>
                <UpdatePhoneForm
                  user={user}
                  onSubmit={data => updatePhone(user._id, data)}
                  onSubmitSuccess={e => onDataUpdate(e, 'Phone number')}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_UPDATE_ADDRESS && (
            <>
              <ModalHeader toggle={toggle}>Update Address</ModalHeader>
              <ModalBody>
                <UpdateAddressForm
                  user={user}
                  onSubmit={data => updateAddress(user._id, data)}
                  onSubmitSuccess={e => onDataUpdate(e, 'Address')}
                  getPickupLocations={getPickupLocations}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_UPDATE_STATUS && (
            <>
              <ModalHeader toggle={toggle}>Update Status</ModalHeader>
              <ModalBody>
                <UpdateStatusForm
                  user={user}
                  onSubmit={data => updateAccountStatus(user._id, data)}
                  onSubmitSuccess={e => onDataUpdate(e, null, 'Account status updated')}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_DUMB_PERIODS && (
            <>
              <ModalHeader toggle={toggle}>Update Frequency</ModalHeader>
              <ModalBody>
                <DumbPeriodForm
                  user={user}
                  onSubmit={data => updateDumbSubscription(user._id, data)}
                  onSubmitSuccess={e => onDataUpdate(e, 'Frequency')}
                />
              </ModalBody>
            </>
          )}
          {type === FORM_SHIPPING_PRICE && (
            <>
              <ModalHeader toggle={toggle}>Update Shipping Price</ModalHeader>
              <ModalBody>
                <ShippingPriceForm
                  user={user}
                  onSubmitSuccess={e => onDataUpdate(e, 'Shipping')}
                  updateShipping={updateShipping}
                />
              </ModalBody>
            </>
          )}
          {type === FORM_UPDATE_NAME && (
            <>
              <ModalHeader toggle={toggle}>Update Name</ModalHeader>
              <ModalBody>
                <UpdateNameForm
                  user={user}
                  onSubmit={data => updateName(user._id, data)}
                  onSubmitSuccess={e => onDataUpdate(e, 'Name')}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_UPDATE_PRODUCT && (
            <>
              <ModalHeader toggle={toggle}>Update Product</ModalHeader>
              <ModalBody>
                <UpdateProductForm
                  user={user}
                  products={products}
                  options={options}
                  onSubmit={data => updateProduct(user._id, data)}
                  onSuccess={e => onDataUpdate(e, 'Product')}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_UPDATE_SUBSCRIPTION_TYPE && (
            <>
              <ModalHeader toggle={toggle}>Update Subscription Type</ModalHeader>
              <ModalBody>
                <UpdateSubscriptionTypeForm
                  user={user}
                  createMagicLink={createMagicLink}
                  onSubmit={data => updateDumbSubscription(user._id, data, 'vendor-user-details')}
                  onSuccess={e => onDataUpdate(e, 'Subscription type')}
                  onClose={toggle}
                />
              </ModalBody>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

const aggressionName = aggression =>
  ({
    1: 'Never run out',
    2: 'Just right',
    3: 'As fresh as possible',
  }[aggression] ||
  aggression ||
  '---');

User.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string,
    visible: PropTypes.bool,
    error: PropTypes.object,
    status: PropTypes.string,
    trial: PropTypes.bool,
    paused: PropTypes.bool,
    hibernate: PropTypes.bool,
    dumb_type: PropTypes.string,
    dumb_day: PropTypes.string,
    dumb_period: PropTypes.number,
    grind: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    pending_orders: PropTypes.array,
    vendor_id: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    scale_last_connected: PropTypes.string,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
        vendor_id: PropTypes.object,
        rotating: PropTypes.bool,
        category: PropTypes.shape({
          name: PropTypes.string,
        }),
        personalized_rotation: PropTypes.bool,
      }),
      variant: PropTypes.string.isRequired,
    }).isRequired,
    verifiedAddress: PropTypes.shape({
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      pickup: PropTypes.bool,
    }).isRequired,
    phone: PropTypes.string,
    timezone: PropTypes.string,
    ordering_aggression: PropTypes.number,
    order: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      tracking_number: PropTypes.string,
      subproduct_name: PropTypes.string,
      shipping_service: PropTypes.string,
      tracking_url: PropTypes.string,
      override_fulfillment_date: PropTypes.string,
      subvendor_id: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }),
    has_future_order: PropTypes.bool,
    notes: PropTypes.array,
    personalized: PropTypes.shape({
      price_type: PropTypes.string,
      vendor: PropTypes.arrayOf(
        PropTypes.shape({
          fulfillment_timelines: PropTypes.object,
        })
      ),
    }),
    dynamicPricing: PropTypes.shape({
      type: PropTypes.string,
      price: PropTypes.number,
    }),
    selectedVendor: PropTypes.string,
    support: PropTypes.shape({
      order: PropTypes.shape({
        needs_support: PropTypes.bool,
        date: PropTypes.string,
      }),
      scale: PropTypes.shape({
        needs_support: PropTypes.bool,
        date: PropTypes.string,
      }),
    }),
    order_gen_status: PropTypes.string,
    pricing_rule: PropTypes.object,
    slow_local: PropTypes.bool,
    shopifyCustomerId: PropTypes.string,
    shopifySubscriptionContractId: PropTypes.string,
    quantity: PropTypes.number,
    signupDate: PropTypes.string,
    subscriptionType: PropTypes.string,
    scale_status: PropTypes.string,
    base_id: PropTypes.string,
    deliveryPrice: PropTypes.number,
    pausedUntil: PropTypes.string,
  }).isRequired,
  getUserRecords: PropTypes.func.isRequired,
  onCreateNote: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  hideOnGeneration: PropTypes.bool,
  getUserCharges: PropTypes.func,
  updateEmail: PropTypes.func.isRequired,
  updatePhone: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  updateAccountStatus: PropTypes.func.isRequired,
  updateDumbSubscription: PropTypes.func.isRequired,
  sendPaymentUpdateLink: PropTypes.func.isRequired,
  vendor: PropTypes.object.isRequired,
  createMagicLink: PropTypes.func.isRequired,
  updateShipping: PropTypes.func.isRequired,
  updateName: PropTypes.func.isRequired,
  skipOrder: PropTypes.func.isRequired,
  orders: PropTypes.array,
  products: PropTypes.array.isRequired,
  options: PropTypes.object,
  updateProduct: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  getShopifyUserPaymentMethods: PropTypes.func.isRequired,
  mergeShopifyAccounts: PropTypes.func.isRequired,
  getPickupLocations: PropTypes.func.isRequired,
  getScaleShipmentTrackingForUser: PropTypes.func.isRequired,
};

User.defaultProps = {
  addToast: () => {},
  hideOnGeneration: true,
  getUserCharges: () => {},
};
