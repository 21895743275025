import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetUserOrderGenTypes = createAction('Vendor OrderGen Instance', 'Get single ser');

export const getUserOrderGenAction = id => ({
  [RSAA]: {
    endpoint: `vendor-portal/ordergen/${id}`,
    method: 'GET',
    types: [GetUserOrderGenTypes.REQUEST, GetUserOrderGenTypes.SUCCESS, GetUserOrderGenTypes.FAILURE],
  },
});
