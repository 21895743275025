import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DataLoading } from '@bottomless/common/components';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { getVendorProposedProductsAction } from '../../../store/vendor-portal';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import { ListProduct } from './components/ListProduct';
import { Filter } from './components/Filter';
import './Products.scss';

const ProductsPageComponent = ({ getProducts, products, isLoading }) => {
  const [filter, setFilter] = useState({ search: '', status: 'active' });
  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const applyFilter = useCallback(
    product => {
      const searchMatches = !filter.search || product.name.toLowerCase().includes(filter.search.toLowerCase());
      const statusMatches =
        !filter.status ||
        (filter.status === 'new' && !product.product && !product.ignored && !product.deleted) ||
        (product.product && product.product.status === filter.status);

      return searchMatches && statusMatches;
    },
    [filter]
  );

  const filteredProducts = useMemo(() => products && products.length > 0 && products.filter(applyFilter), [
    products,
    applyFilter,
  ]);

  return (
    <Dashboard title="Products" className="page-vendor-products">
      <>
        <div className="mb-4">
          <Filter onChange={setFilter} />
        </div>
        <DataLoading count={(filteredProducts || []).length} isLoading={isLoading}>
          <div className="text-center">
            <span className="mr-2">There are no matching products</span>
          </div>
        </DataLoading>
        {filteredProducts &&
          filteredProducts.length > 0 &&
          filteredProducts.filter(applyFilter).map(product => <ListProduct key={product._id} product={product} />)}
      </>
    </Dashboard>
  );
};

ProductsPageComponent.propTypes = {
  getProducts: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
};

export const ProductsPage = withMetaTags({
  title: 'Bottomless.com: Vendor Portal',
})(
  connect(
    ({ vendorPortal }) => ({
      products: vendorPortal.products,
      isLoading: vendorPortal.isLoading,
    }),
    dispatch => ({
      getProducts: () => dispatch(getVendorProposedProductsAction()),
    })
  )(ProductsPageComponent)
);
