import {
  GetVendorTypes,
  GetVendorUnprocessedOrdersTypes,
  GetVendorUnscannedOrdersTypes,
  CreateVendorOrderShipmentTypes,
  GetVendorProposedProductsType,
} from './vendor-portal.actions';

const initialState = {
  orders: {
    docs: [],
    total: 0,
    limit: 100,
    pages: 0,
    page: 0,
  },
  unprocessedOrders: {
    docs: [],
    total: 0,
    limit: 100,
    pages: 0,
    page: 0,
  },
  products: [],
  vendor: null,
  isLoading: false,
  isLoggedIn: false,
};

export const adminVendorPortalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetVendorTypes.REQUEST:
      return { ...initialState, ...state };

    case GetVendorTypes.SUCCESS:
      return {
        ...state,
        vendor: action.payload,
        isLoggedIn: true,
      };

    case GetVendorUnprocessedOrdersTypes.REQUEST:
    case GetVendorUnscannedOrdersTypes.REQUEST:
    case GetVendorProposedProductsType.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetVendorUnscannedOrdersTypes.SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        orders: action.payload,
      };

    case GetVendorUnprocessedOrdersTypes.SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        unprocessedOrders: action.payload,
      };

    case GetVendorProposedProductsType.SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: action.payload,
      };

    case GetVendorTypes.FAILURE:
    case GetVendorUnprocessedOrdersTypes.FAILURE:
    case GetVendorUnscannedOrdersTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
      };

    case GetVendorProposedProductsType.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case CreateVendorOrderShipmentTypes.SUCCESS: {
      const order = action.payload;
      const index = state.orders.docs.findIndex(o => o._id === order._id);

      return {
        ...state,
        orders: {
          ...state.orders,
          docs: [...state.orders.docs.slice(0, index), order, ...state.orders.docs.slice(index + 1)],
        },
      };
    }

    default:
      return state;
  }
};
