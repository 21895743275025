import React from 'react';
import PropTypes from 'prop-types';
import { Form, SubmitButton } from '@bottomless/common/components';
import { useCallback } from 'react';

export const CancelOrderForm = ({ order, cancelOrder, addToast, toggleModal, setOrder }) => {
  const onSuccess = useCallback(
    data => {
      setOrder(data);
      addToast(`Order has been cancelled`);
      toggleModal();
    },
    [addToast, toggleModal, setOrder]
  );

  const onSubmit = useCallback(() => cancelOrder(order._id), [order, cancelOrder]);

  return (
    <Form onSubmit={onSubmit} onSuccess={onSuccess}>
      {({ isSubmitting }) => (
        <>
          <div>Are you sure that you want to cancel this order?</div>
          <hr />

          <div className="mt-4">
            <SubmitButton color="dark" isSubmitting={isSubmitting} loadingText="Processing" withLoader>
              Yes
            </SubmitButton>
          </div>
        </>
      )}
    </Form>
  );
};

CancelOrderForm.propTypes = {
  order: PropTypes.object.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  setOrder: PropTypes.func.isRequired,
};
