import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Plus, X } from 'react-feather';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { Fragment } from 'react';
import { Confirmation } from '../../../../components/Confirmation/Confirmation';
import { ImageUploader } from '../../../../components/Products/ImageUploader';
import { TaxCategoryInput } from '../../../../components/TaxCategoryInput';
import './UpdateForm.scss';

const Statuses = {
  draft: 'Draft',
  active: 'Active',
  archived: 'Archived',
};

const createSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('This field is required'),
    tax_category: Yup.string().required('This field is required'),
    description: Yup.string().required('This field is required'),

    small_image_src: Yup.string().matches(/^https:/, 'Please use https://'),
    image_src: Yup.string().matches(/^https:/, 'Please use https://'),
    status: Yup.mixed().oneOf(Object.keys(Statuses)),

    variants: Yup.array().of(
      Yup.object().shape({
        size: Yup.number().required('This field is required'),
      })
    ),
  });

export const UpdateForm = ({ product, onSubmit, onSuccess, proposedProduct, vendor, taxCategories }) => {
  const [imageUrls, setImageUrls] = useState(null);
  const [productCopy, setProductCopy] = useState(product);

  const formRef = useRef();

  const schema = useMemo(() => createSchema(), []);

  useEffect(() => {
    setProductCopy({ variants: [], ...product });
    formRef.current.resetForm({ variants: [], ...product });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (imageUrls) {
      formRef.current.setFieldValue(`image_src`, imageUrls.img_src);
      formRef.current.setFieldValue(`small_image_src`, imageUrls.small_img_src);
    }
  }, [imageUrls]);

  const createVariantWebsitePricingOptions = (data, label) => {
    if (!data) return {};
    const options = {};
    options[0] = label;
    data.map(v => (options[v.price] = `${v.size} oz - $${v.price}`));
    return options;
  };

  const variantWebsitePricingOptions = createVariantWebsitePricingOptions(proposedProduct.variants, 'Choose variant');

  const handleSubmit = async data => {
    return onSubmit(data);
  };

  const handleSuccess = (data, { resetForm }) => {
    onSuccess(data);
    resetForm(data);
  };

  const addNewVariant = () => {
    formRef.current.setFieldValue(`variants[${productCopy.variants.length}].available`, true);
    formRef.current.setFieldValue(`variants[${productCopy.variants.length}].skipShipment`, false);

    setProductCopy({
      ...productCopy,
      variants: [
        ...productCopy.variants,
        {
          notSaved: true,
          available: true,
          skipShipment: false,
        },
      ],
    });
  };

  const onRemoveVariant = index => () => {
    return new Promise((promiseResolve, reject) => {
      const resolve = () =>
        promiseResolve(
          (() => {
            const newProduct = {
              ...productCopy,
              variants: [...productCopy.variants.slice(0, index), ...productCopy.variants.slice(index + 1)],
            };
            setProductCopy(newProduct);
            formRef.current.resetForm({ ...newProduct });
          })()
        );
      const event = new Event('open');
      event.promise = { resolve, reject };
      emitter.dispatchEvent(event);
    });
  };

  const setDefaultImg = () => {
    if (!vendor) {
      return;
    }

    if (!vendor.product_image_url) {
      return;
    }

    formRef.current.setFieldValue(`image_src`, `${vendor.product_image_url}?auto=compress`);
    formRef.current.setFieldValue(`small_image_src`, `${vendor.product_image_url}?auto=compress&w=300`);
  };

  const [emitter] = useState(new EventTarget());

  return (
    <div className="update-proposed-product">
      <Form
        innerRef={formRef}
        initialValues={{
          ...product,
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
      >
        {({ isSubmitting }) => (
          <>
            <hr />
            <h4>Shop product</h4>
            <Field name="name" label="Name" />
            <TaxCategoryInput taxCategories={taxCategories} name="tax_category" />
            <Field name="description" type="editor" label="Description" />
            <Row className="align-items-center">
              <Col xs="6">
                <ImageUploader setImageUrls={setImageUrls} />
              </Col>
              <Col xs="6">
                <Button color="link" onClick={setDefaultImg}>
                  Set Vendor&apos;s default
                </Button>
              </Col>
            </Row>
            <Button color="link" onClick={setDefaultImg}>
              Set default
            </Button>
            <Field name="image_src" label="Image url" />
            <Field name="small_image_src" label="Small image url" />
            <div className="d-flex align-items-center justify-content-between">
              <h6>Variants:</h6>
              <Button color="link" className="d-flex align-items-center" onClick={addNewVariant}>
                <Plus size="12" /> <span className="ml-2">Add new variant</span>
              </Button>
            </div>
            {productCopy.variants &&
              productCopy.variants.map((variant, i) => (
                <Fragment key={variant._id}>
                  <Row>
                    <Col lg="auto" xl="auto" md="auto">
                      <span># {variant.numUsers}</span>
                    </Col>
                    <Col>
                      <Field name={`variants[${i}].size`} type="number" label="Size" />
                    </Col>
                    <Col>
                      <Field
                        name={`variants[${i}].website_pricing`}
                        type="select"
                        label="Website Pricing"
                        options={variantWebsitePricingOptions}
                      />
                    </Col>
                    <Col xs="1">
                      <Button color="link" className="text-danger" onClick={onRemoveVariant(i)}>
                        <X size="20" />
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                </Fragment>
              ))}

            <div className="d-flex justify-content-between mt-4">
              <SubmitButton color="primary" isSubmitting={isSubmitting}>
                Save
              </SubmitButton>
            </div>
          </>
        )}
      </Form>
      <Confirmation openEmitter={emitter} text={`Are you sure about removing the variant?`} />
    </div>
  );
};

UpdateForm.propTypes = {
  product: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  shopProducts: PropTypes.array.isRequired,
  proposedProduct: PropTypes.shape({
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        original_id: PropTypes.string.isRequired,
        size: PropTypes.number,
        price: PropTypes.number,
      })
    ).isRequired,
  }).isRequired,
  getQuizAnswers: PropTypes.func,
  vendor: PropTypes.object,
  categories: PropTypes.array.isRequired,
  taxCategories: PropTypes.array,
  addToast: PropTypes.func.isRequired,
  getVendors: PropTypes.func.isRequired,
};
