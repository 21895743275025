import classNames from 'classnames';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { DateFormat } from '@bottomless/common/components';

const STATUSES = {
  OutForDelivery: 'out_for_delivery',
  Delivered: 'delivered',
  InTransit: 'in_transit',
  Accepted: 'accepted',
  PreTransit: 'pre_transit',
  ReturnToSender: 'return_to_sender',
  Alert: 'alert',
};

export const NewTracking = ({ order, onlyFirstTwo }) => {
  const summary = useMemo(() => {
    const trackingDetails =
      order.usps_tracking?.summary ||
      [...(order.tracking_updates || [])].shift()?.tracking_details?.map(details => ({
        ...details,
        description: details.description || details.message,
        date: details.datetime,
      }));

    return trackingDetails?.slice(0, onlyFirstTwo ? 2 : undefined);
  }, [order, onlyFirstTwo]);

  const getCssClass = useCallback(
    status => ({
      'text-success': [STATUSES.OutForDelivery, STATUSES.Delivered].includes(status),
      'text-primary': status === STATUSES.InTransit,
      'text-secondary': status === STATUSES.Accepted,
      'text-info': status === STATUSES.PreTransit,
      'text-danger': [STATUSES.ReturnToSender, 'alert'].includes(status),
    }),
    [order]
  );

  if (!summary) {
    return null;
  }

  return (
    <Row>
      <Col>
        <div>New tracking</div>
        <div>
          <ol className="text-sm pt-2" reversed>
            {summary.map(({ status, description, date }, i) => (
              <li key={i}>
                <div>
                  <span className={classNames('mr-1', getCssClass(status))}>{status}</span>
                  <span>{description}</span>
                </div>

                {date && (
                  <div className="text-secondary">
                    <DateFormat date={date} withTime />
                  </div>
                )}
              </li>
            ))}
          </ol>
        </div>
      </Col>
    </Row>
  );
};

NewTracking.propTypes = {
  order: PropTypes.shape({
    usps_tracking: PropTypes.shape({
      summary: PropTypes.array,
    }),
    tracking_updates: PropTypes.arrayOf(
      PropTypes.shape({
        tracking_details: PropTypes.array,
      })
    ),
  }).isRequired,
  onlyFirstTwo: PropTypes.bool,
};
