import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Collapse, Nav, Navbar as StrapNavBar, NavbarToggler, NavItem } from 'reactstrap';
import './Navbar.scss';

const NavbarComponent = ({ me, getMe, small, withSignUpButton, history, sticky }) => {
  const [isOpen, toggle] = useState(false);
  const [border, setBorder] = useState(false);
  const [{ checkout }] = useCookies(['checkout']);

  useEffect(() => {
    if (!small) {
      getMe();
    }
  }, [getMe, small]);

  useEffect(() => {
    const scrollHandler = () => setBorder(Boolean(window.scrollY));
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const scrollToSignUp = () => window.scrollTo(0, document.querySelector('section.sign-up').offsetTop);
  const classes = [...(border ? ['border-bottom'] : [])];

  const onSignUp = () => {
    if (document.querySelector('section.sign-up')) {
      return scrollToSignUp();
    }

    history.push('/checkout');
  };

  return (
    <StrapNavBar color="white" light fixed={sticky ? 'top' : undefined} expand="md" className={classes.join(' ')}>
      <Link to="/" className="d-flex align-items-center navbar-brand">
        <img src="/images/infinity-black.png" alt="∞" height="25" />
      </Link>
      {!small && (
        <>
          <NavbarToggler onClick={() => toggle(!isOpen)} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto align-items-center" navbar>
              <NavItem>
                <a href="https://blog.bottomless.com" className="nav-link">
                  Blog
                </a>
              </NavItem>

              {me && (
                <NavItem className="ml-2">
                  <NavLink to="/profile" className="border border-dark nav-link">
                    Your Profile <i className="fa fa-long-arrow-right ml-1" />
                  </NavLink>
                </NavItem>
              )}

              {!me && (
                <NavItem className="ml-2">
                  <NavLink to="/login" className="border border-dark nav-link">
                    Login
                  </NavLink>
                </NavItem>
              )}

              {checkout && (
                <NavItem className="ml-2">
                  <NavLink to={`/get_started/${checkout}`} className="border border-dark nav-link nav-link-dark">
                    Finish checkout <i className="fa fa-long-arrow-right ml-1" />
                  </NavLink>
                </NavItem>
              )}

              {withSignUpButton && !checkout && !me && (
                <NavItem className="ml-3">
                  <button className="nav-link border border-dark" onClick={onSignUp}>
                    Sign Up
                  </button>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </>
      )}
    </StrapNavBar>
  );
};

NavbarComponent.propTypes = {
  me: PropTypes.object,
  getMe: PropTypes.func.isRequired,
  small: PropTypes.bool,
  withSignUpButton: PropTypes.bool,
  history: PropTypes.object.isRequired,
  sticky: PropTypes.bool.isRequired,
};

NavbarComponent.defaultProps = {
  small: false,
  withSignUpButton: true,
  sticky: true,
  me: null,
  getMe: () => {},
};

export const Navbar = withRouter(NavbarComponent);
