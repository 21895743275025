import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Form,
  SubmitButton,
  Toasts,
  GoogleMapsLoaded,
  GoogleMapsLoader,
  Price,
  DateFormat,
  CopyToClipboard,
} from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import {
  cancelGiftPurchaseAction,
  getGiftPurchaseAction,
  updateGiftPurchaseAction,
  verifyAddressAction,
} from '../../../store';
import { Actions } from './components/Actions';
import { BuyerInformation } from './components/Buyer';
import { RecipientInformation } from './components/Recipient';
import './GiftPurchases.scss';

const giftSchema = Yup.object().shape({
  recipient: Yup.object()
    .shape({
      first_name: Yup.string().required('This field is required'),
      last_name: Yup.string().required('This field is required'),
      date: Yup.date().required('This field is required'),
      email: Yup.string()
        .email('Email is invalid. Please enter a valid email address.')
        .required('This field is required'),
    })
    .required(),
  buyer: Yup.object().shape({
    first_name: Yup.string().required('This field is required'),
    last_name: Yup.string().required('This field is required'),
    email: Yup.string()
      .email('Email is invalid. Please enter a valid email address.')
      .required('This field is required'),
  }),
});

const initialValues = {
  recipient: {
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    info: undefined,
    date: new Date(),
    phone: undefined,
  },
  buyer: { first_name: undefined, last_name: undefined, email: undefined, phone: undefined },
  scale_shipment: 'should_ship_to_recipient',
  should_gift_wrap: false,
};

export const GiftPurchaseDetailsPageComponent = ({
  getGiftPurchase,
  updateGiftPurchase,
  verifyAddress,
  addToast,
  match,
  cancelGiftPurchase,
}) => {
  const form = useRef();
  const [giftPurchase, setGiftPurchase] = useState(null);
  const [copied, setCopied] = useState(false);
  const [shouldShipScaleToBuyer, setShouldShipScaleToBuyer] = useState(false);
  const [shouldGiftWrapScale, setShouldGiftWrapScale] = useState(false);

  const { id } = match.params;
  const buyerAddress = useRef();
  const recipientAddress = useRef();

  const isGiftRedeemed = useMemo(() => !!giftPurchase?.recipient?.user_id, [giftPurchase]);

  useDataEffect(getGiftPurchase, setGiftPurchase, id);

  useEffect(() => {
    if (form && giftPurchase) {
      form.current.setFieldValue('buyer.first_name', giftPurchase.buyer.first_name);
      form.current.setFieldValue('buyer.last_name', giftPurchase.buyer.last_name);
      form.current.setFieldValue('buyer.email', giftPurchase.buyer.email);
      form.current.setFieldValue('buyer.phone', giftPurchase.buyer.phone || '');

      if (giftPurchase.buyer.verifiedAddress) {
        buyerAddress.current = `${giftPurchase.buyer.verifiedAddress.street1}, ${giftPurchase.buyer.verifiedAddress.city}, ${giftPurchase.buyer.verifiedAddress.state}, ${giftPurchase.buyer.verifiedAddress.zip}`;
        form.current.setFieldValue('buyer.address', buyerAddress.current || '');
        form.current.setFieldValue('buyer.verifiedAddress', giftPurchase.buyer.verifiedAddress || '');
      }

      form.current.setFieldValue('recipient.first_name', giftPurchase.recipient.first_name);
      form.current.setFieldValue('recipient.last_name', giftPurchase.recipient.last_name);
      form.current.setFieldValue('recipient.email', giftPurchase.recipient.email);
      form.current.setFieldValue('recipient.phone', giftPurchase.recipient.phone || '');
      form.current.setFieldValue('recipient.info', giftPurchase.info || '');
      form.current.setFieldValue('recipient.date', moment.utc(giftPurchase.sendAt).format('YYYY-MM-DD') || '');

      if (giftPurchase.recipient.verifiedAddress) {
        recipientAddress.current = `${giftPurchase.recipient.verifiedAddress.street1}, ${giftPurchase.recipient.verifiedAddress.city}, ${giftPurchase.recipient.verifiedAddress.state}, ${giftPurchase.recipient.verifiedAddress.zip}`;
        form.current.setFieldValue('recipient.address', recipientAddress.current || '');
        form.current.setFieldValue('recipient.verifiedAddress', giftPurchase.recipient.verifiedAddress || '');
      } else if (giftPurchase.recipient.user_id && giftPurchase.recipient.user_id.verifiedAddress) {
        recipientAddress.current = `${giftPurchase.recipient.user_id.verifiedAddress.street1}, ${giftPurchase.recipient.user_id.verifiedAddress.city}, ${giftPurchase.recipient.user_id.verifiedAddress.state}, ${giftPurchase.recipient.user_id.verifiedAddress.zip}`;
        form.current.setFieldValue('recipient.address', recipientAddress.current || '');
        form.current.setFieldValue('recipient.verifiedAddress', giftPurchase.recipient.user_id.verifiedAddress || '');
      }

      setShouldGiftWrapScale(giftPurchase.shouldGiftWrap);
      setShouldShipScaleToBuyer(giftPurchase.shouldShipToBuyer);

      if (giftPurchase.shouldShipToBuyer) {
        form.current.setFieldValue('scale_shipment', 'should_ship_to_buyer');
      } else {
        form.current.setFieldValue('scale_shipment', 'should_ship_to_recipient');
      }

      if (giftPurchase.shouldGiftWrap) {
        form.current.setFieldValue('should_gift_wrap', true);
      } else {
        form.current.setFieldValue('should_gift_wrap', false);
      }
    }
  }, [form, giftPurchase]);

  const onCancelGift = useCallback(
    async id => {
      const result = await cancelGiftPurchase(id);
      setGiftPurchase(result.payload);
      await addToast('Gift purchase has been cancelled.', 'success');
      return result;
    },
    [setGiftPurchase, cancelGiftPurchase, addToast]
  );

  const handleSubmit = async data => {
    try {
      if (data.buyer.phone === '+1 (___) ___-____') data.buyer.phone = '';
      if (data.recipient.phone === '+1 (___) ___-____') data.recipient.phone = '';

      if (shouldShipScaleToBuyer && !data.buyer.verifiedAddress) {
        form.current.setFieldError('buyer.address', 'Address is required to ship scale to yourself');
        return;
      }

      if (shouldGiftWrapScale && !data.buyer.verifiedAddress && !data.recipient.verifiedAddress) {
        if (shouldShipScaleToBuyer) {
          form.current.setFieldError('buyer.address', 'Address is required to gift wrap scale');
        } else {
          form.current.setFieldError('recipient.address', 'Address is required to gift wrap scale');
        }
        return;
      }

      let verifiedAddressRecipient;
      if (shouldGiftWrapScale && data.recipient.verifiedAddress) {
        const result = await verifyAddress({
          verifiedAddress: data.recipient.verifiedAddress,
        });
        verifiedAddressRecipient = result ? result.payload : '';
      }

      let verifiedAddressBuyer;
      if (data.buyer.verifiedAddress) {
        const result = await verifyAddress({
          verifiedAddress: data.buyer.verifiedAddress,
        });
        verifiedAddressBuyer = result ? result.payload : '';
      }

      const response = await updateGiftPurchase(id, {
        ...data,
        recipient: {
          ...data.recipient,
          verifiedAddress: verifiedAddressRecipient,
          date: moment(data.recipient.date).format(),
        },
        buyer: { ...data.buyer, verifiedAddress: verifiedAddressBuyer },
        info: data.recipient.info,
      });

      if (!response || (response && !response.payload)) {
        throw new Error('Something went wrong, please try again later.');
      }
      addToast('Gift purchase updated successfully', 'success');
    } catch (e) {
      if (e.details.verifiedAddress) {
        form.current.setFieldError('recipient.address', e.details.verifiedAddress);
        return;
      }

      if (e.details['verifiedAddress.city']) {
        form.current.setFieldError('recipient.address', e.details['verifiedAddress.city']);
        return;
      }

      throw e;
    }
  };

  return (
    <GoogleMapsLoader>
      <Dashboard title={`Gift Purchase${giftPurchase ? ` - ${giftPurchase._id}` : ''}`} back="/vendor/dashboard/gifts">
        <>
          {giftPurchase && (
            <>
              <Form innerRef={form} validationSchema={giftSchema} onSubmit={handleSubmit} initialValues={initialValues}>
                {({ isSubmitting, setFieldValue, values, errors, submitCount }) => (
                  <>
                    <Row>
                      <Col xs="12" md="6">
                        {submitCount > 0 && errors && errors.gift && (
                          <div className="text-left invalid-feedback d-block">{errors.gift}</div>
                        )}
                        {isGiftRedeemed ? <Badge color="success mb-2">Redeemed</Badge> : ''}
                        <div className="mb-1">
                          <span className="text-success">Gift:</span> {giftPurchase.giftId.name} (x
                          {giftPurchase.quantity || 1})
                        </div>
                        {!isGiftRedeemed && (
                          <div className="mb-1 d-flex align-items-center">
                            <div className="text-success mr-1">Redeem Link:</div>
                            <CopyToClipboard text={giftPurchase.redeemLink} onCopy={() => setCopied(true)}>
                              <div color="primary">{copied ? 'Copied!' : 'Copy'}</div>
                            </CopyToClipboard>
                          </div>
                        )}
                        <div className="mb-1">
                          <span className="text-success">Charge:</span>{' '}
                          <Price value={giftPurchase.charge.amount} cents />
                        </div>
                        <div className="mb-0">
                          <span className="text-success">Status:</span> {giftPurchase.status}
                        </div>
                        {giftPurchase.refund?.date && (
                          <div>
                            <span className="text-success">Refund:</span>{' '}
                            <Price value={giftPurchase.refund.amount} cents />{' '}
                            <span className="text-secondary">
                              on <DateFormat date={giftPurchase.refund.date} correctTimezone />
                            </span>
                          </div>
                        )}
                        {isGiftRedeemed ? (
                          <div>
                            <span className="text-success">Redeemed user ID:</span> {giftPurchase.recipient.user_id._id}
                          </div>
                        ) : (
                          ''
                        )}
                      </Col>
                      <Col xs="12" md="6" className="text-right">
                        <Actions cancelGift={onCancelGift} giftPurchase={giftPurchase} />
                      </Col>
                    </Row>
                    <GoogleMapsLoaded>
                      <RecipientInformation
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={submitCount ? errors : null}
                        shouldGiftWrapScale={shouldGiftWrapScale}
                        userId={giftPurchase.recipient.user_id}
                        address={recipientAddress}
                        isGiftRedeemed={isGiftRedeemed}
                      />
                      <BuyerInformation
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={submitCount ? errors : null}
                        shouldGiftWrapScale={shouldGiftWrapScale}
                        shouldShipScaleToBuyer={shouldShipScaleToBuyer}
                        address={buyerAddress}
                        isGiftRedeemed={isGiftRedeemed}
                      />
                    </GoogleMapsLoaded>
                    {!isGiftRedeemed && (
                      <SubmitButton
                        color="dark"
                        loadingText="Submitting..."
                        isSubmitting={isSubmitting}
                        disabled={isGiftRedeemed}
                      >
                        Save Changes
                      </SubmitButton>
                    )}
                  </>
                )}
              </Form>
            </>
          )}
        </>
        <Toasts />
      </Dashboard>
    </GoogleMapsLoader>
  );
};

GiftPurchaseDetailsPageComponent.propTypes = {
  getGiftPurchase: PropTypes.func.isRequired,
  verifyAddress: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  updateGiftPurchase: PropTypes.func.isRequired,
  cancelGiftPurchase: PropTypes.func.isRequired,
};

export const GiftPurchaseDetailsPage = connect(
  ({ gift }) => ({
    giftPurchase: gift.selectedGift,
    isLoading: gift.isLoadingSelectedGift,
  }),
  dispatch => ({
    addToast: message => dispatch(addToastAction(message)),
    updateGiftPurchase: (id, data) => dispatch(updateGiftPurchaseAction(id, data)),
    getGiftPurchase: id => dispatch(getGiftPurchaseAction(id)),
    verifyAddress: data => dispatch(verifyAddressAction(data)),
    cancelGiftPurchase: id => dispatch(cancelGiftPurchaseAction(id)),
  })
)(GiftPurchaseDetailsPageComponent);
