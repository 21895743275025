import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

export const CreateProductForm = ({ product, onCreateNewProduct }) => {
  return (
    <div className="d-flex justify-content-between">
      {!product.product && (
        <>
          <div className="mb-3 ml-3">
            <Button size="sm" type="button" color="dark" outline onClick={onCreateNewProduct}>
              Create new shop product
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

CreateProductForm.propTypes = {
  product: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCreateNewProduct: PropTypes.func.isRequired,
};
