import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import { getProductsFeedbackAction } from '../../../store';
import { Product } from './components/Product';
import './ProductFeedback.scss';

const sortStrategy = sort => {
  switch (sort) {
    case 'like':
      return (a, b) => b.like - a.like;
    case 'dislike':
      return (a, b) => b.dislike - a.dislike;
    case 'ratio':
      return (a, b) => b.ratio - a.ratio;
    default:
      return () => 0;
  }
};

const ProductFeedbackPageComponent = ({ getProductsFeedback }) => {
  const [products, setProducts] = useState(null);
  const [sort, setSort] = useState('like');
  useDataEffect(getProductsFeedback, setProducts);

  const handleSort = type => e => {
    e.preventDefault();
    setSort(type);
  };

  return (
    <Dashboard title="Product feedback">
      <div className="d-flex justify-content-end align-items-center list-sorting mb-2">
        <div className="mr-3 font-weight-bold">Sort:</div>
        <Button
          color="text"
          onClick={handleSort('like')}
          className={classNames('shadow-none', { 'text-underline': sort === 'like' })}
        >
          Liked
        </Button>
        <Button
          color="text"
          onClick={handleSort('dislike')}
          className={classNames('shadow-none', { 'text-underline': sort === 'dislike' })}
        >
          Disliked
        </Button>
        <Button
          color="text"
          onClick={handleSort('ratio')}
          className={classNames('shadow-none', { 'text-underline': sort === 'ratio' })}
        >
          Like/Dislike
        </Button>
      </div>
      <DataLoading count={(products || { length: 0 }).length} isLoading={products === null} />
      {products && products.sort(sortStrategy(sort)).map(product => <Product product={product} key={product._id} />)}
    </Dashboard>
  );
};

ProductFeedbackPageComponent.propTypes = {
  getProductsFeedback: PropTypes.func.isRequired,
};

export const ProductFeedbackPage = connect(null, dispatch => ({
  getProductsFeedback: params => dispatch(getProductsFeedbackAction(params)),
}))(ProductFeedbackPageComponent);
