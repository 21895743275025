import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  content: Yup.string().required('This field is required'),
});

export const CreateComment = ({ onSubmit, onSuccess }) => {
  const handleSuccess = (data, { resetForm }) => {
    onSuccess(data);
    resetForm({ content: '' });
  };

  return (
    <div className="create-comment-wrapper">
      <Form initialValues={{ content: '' }} validationSchema={Schema} onSubmit={onSubmit} onSuccess={handleSuccess}>
        {({ isSubmitting }) => (
          <div className="d-flex">
            <div className="flex-grow-1 content-container">
              <Field name="content" type="text" placeholder="Leave a comment" />
            </div>
            <SubmitButton color="dark" isSubmitting={isSubmitting}>
              Post
            </SubmitButton>
          </div>
        )}
      </Form>
    </div>
  );
};

CreateComment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
