import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Printer } from 'react-feather';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  layout: Yup.string().required(),
});

export const PrinterModal = ({ opened, onSubmit, onSuccess, onClose }) => {
  const [isOpen, setOpen] = useState(opened);

  useEffect(() => {
    setOpen(opened);
  }, [opened]);

  const toggle = () => {
    if (isOpen) {
      onClose();
    }

    setOpen(!isOpen);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm">
      <ModalHeader toggle={toggle}>Select format</ModalHeader>
      <ModalBody>
        <Form
          initialValues={{ layout: 'labelPrinter' }}
          validationSchema={Schema}
          onSubmit={onSubmit}
          onSuccess={onSuccess}
        >
          {() => (
            <>
              <Field
                type="select"
                name="layout"
                label="Format"
                options={{ labelPrinter: 'Label Printer (4x6)', fullPage: 'Full page sheet' }}
              />
              <Button color="success">
                <span className="mr-2">Print labels</span>
                <Printer size="14" />
              </Button>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

PrinterModal.propTypes = {
  opened: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

PrinterModal.defaultProps = {
  opened: false,
};
