import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { set } from 'lodash-es';
import { Table } from 'reactstrap';
import { Default } from '@bottomless/common/components';
import { Nl2Br } from '../Nl2Br/Nl2Br';
import './ProductChangeSet.scss';

export const ProductChangeSet = ({ changeSet, productOptions }) => {
  const findName = useCallback(
    (key, value) => {
      const dict = productOptions[key];

      return (dict.find(o => o._id === value) || { name: value }).name;
    },
    [productOptions]
  );

  const mapSingleProperty = useCallback(
    (key, { oldValue, newValue }) => ({ oldValue: findName(key, oldValue), newValue: findName(key, newValue) }),
    [findName]
  );

  const mapArrayProperty = useCallback(
    (key, { oldValue, newValue }) => ({
      oldValue: oldValue.map(v => findName(key, v)).join(', '),
      newValue: newValue.map(v => findName(key, v)).join(', '),
    }),
    [findName]
  );

  const mapVariants = useCallback(variants => {
    const { oldValue, newValue } = variants
      .map((v, key) => ({ ...v, key }))
      .reduce(
        ({ oldValue, newValue }, { key, ...value }) => {
          const values = Object.entries(value).reduce(
            (all, [field, fieldValue]) => ({
              oldValue: [...all.oldValue, `${field}: ${Default({ children: fieldValue.oldValue })}`],
              newValue: [...all.newValue, `${field}: ${Default({ children: fieldValue.newValue })}`],
            }),
            { oldValue: [], newValue: [] }
          );
          return {
            oldValue: [...oldValue, `${key + 1}: ${values.oldValue.join(', ')}`],
            newValue: [...newValue, `${key + 1}: ${values.newValue.join(', ')}`],
          };
        },
        { oldValue: [], newValue: [] }
      );

    return {
      oldValue: <Nl2Br>{oldValue.join('\n')}</Nl2Br>,
      newValue: <Nl2Br>{newValue.join('\n')}</Nl2Br>,
    };
  }, []);

  const changes = useMemo(() => {
    const { roast, origin, region, process, tasting_notes, tags, varietal, variants, ...rest } = Object.entries(
      changeSet
    ).reduce((all, [key, value]) => set(all, key, value), {});

    return {
      ...rest,
      ...(roast ? { roast: mapSingleProperty('roasts', roast) } : {}),
      ...(origin ? { origin: mapSingleProperty('origins', origin) } : {}),
      ...(region ? { region: mapSingleProperty('regions_raw', region) } : {}),
      ...(process ? { process: mapSingleProperty('processes', process) } : {}),
      ...(tasting_notes ? { tasting_notes: mapArrayProperty('tasting_notes_raw', tasting_notes) } : {}),
      ...(tags ? { tags: mapArrayProperty('tags', tags) } : {}),
      ...(varietal ? { varietal: mapArrayProperty('varietals', varietal) } : {}),
      ...(variants ? { variants: mapVariants(variants) } : {}),
    };
  }, [changeSet, mapSingleProperty, mapArrayProperty, mapVariants]);

  return (
    <div className="product-changeset">
      <Table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Old value</th>
            <th>New value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(changes).map(([field, { oldValue, newValue }]) => (
            <tr key={field}>
              <td>{field}</td>
              <td>
                <Default>{oldValue}</Default>
              </td>
              <td>
                <Default>{newValue}</Default>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const OptionsProps = PropTypes.arrayOf(
  PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })
).isRequired;

ProductChangeSet.propTypes = {
  changeSet: PropTypes.object.isRequired,
  productOptions: PropTypes.shape({
    tasting_notes: OptionsProps,
    roasts: OptionsProps,
    origins: OptionsProps,
    tags: OptionsProps,
    processes: OptionsProps,
    regions: OptionsProps,
    varietals: OptionsProps,
  }).isRequired,
};
