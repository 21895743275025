import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { capitalizeFirstLetter, utcDate, getVariantSelectLabel } from '@bottomless/common/utils';
import { OrderedProduct } from './OrderedProduct';
import './ReplaceForm.scss';
import { statusMap } from '../../pages/VendorPortal/UserDetails/components/OrderStatus';

const createSelectOptions = (data, label, inputLabel = item => item.name) =>
  !data
    ? {}
    : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

const Schema = Yup.object().shape({
  source: Yup.string().required(),
  status: Yup.string().required(),
  product: Yup.string().required(),
  variant: Yup.string().required(),
});

export const ReplaceOrderForm = ({ order, user, products, onSubmit, onSubmitSuccess }) => {
  const [selectedProduct, setProduct] = useState(null);

  const formRef = useRef();
  const selectProduct = id => {
    const product = products.find(p => p._id === id);
    setProduct(product);
  };

  useEffect(() => {
    selectProduct(order.subproduct_id?.product._id);
  }, [order, products]);

  return (
    <>
      <div className="mb-4">
        <OrderedProduct products={products} order={order} user={user} hideRotationDetails />
      </div>
      <Form
        innerRef={formRef}
        initialValues={{
          status: order.status,
          shipping_status: order.shipping_status,
          ...(order.subproduct_id?.product.status === 'active'
            ? { product: order.subproduct_id?.product._id, variant: order.subproduct_id?.variant }
            : {}),
          date_fulfilled: order.date_fulfilled ? utcDate(order.date_fulfilled) : undefined,
          date_arrived: order.date_arrived ? utcDate(order.date_arrived) : utcDate(),
          override_fulfillment_date: order.override_fulfillment_date
            ? utcDate(order.override_fulfillment_date)
            : undefined,
          tracking_number: order.tracking_number,
          shipping_service: order.shipping_service,
          source: 'replaced',
          discount_comment: order.fulfillmentErrors?.vendor?.error || '',
        }}
        validationSchema={Schema}
        onSubmit={onSubmit}
        onSuccess={onSubmitSuccess}
      >
        {({ isSubmitting }) => (
          <>
            <Field
              name="status"
              label="Status"
              type="select"
              options={{
                cancelled: 'Cancelled',
                [order.status]: capitalizeFirstLetter(statusMap[order.status] || order.status),
              }}
            />

            <Field
              name="product"
              type="select"
              label="Product"
              options={createSelectOptions(products, '--- select product ---', item => item.name)}
              onChange={event => {
                selectProduct(event.target.value);
              }}
            />

            <Field
              name="variant"
              type="select"
              label="Variant"
              options={createSelectOptions(
                selectedProduct?.variants?.filter(v => v.available),
                '--- select variant ---',
                getVariantSelectLabel
              )}
            />

            <SubmitButton color="danger" isSubmitting={isSubmitting}>
              Replace
            </SubmitButton>
          </>
        )}
      </Form>
    </>
  );
};

ReplaceOrderForm.propTypes = {
  order: PropTypes.object.isRequired,
  user: PropTypes.object,
  products: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
