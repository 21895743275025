import React from 'react';
import PropTypes from 'prop-types';

export const SimpleAddress = ({ address }) => (
  <div>
    <div>
      {address.street1}
      {address.street2 ? `, ${address.street2}` : ''}
    </div>
    <div>
      {address.city}, {address.state}, {address.zip}
    </div>
  </div>
);

SimpleAddress.propTypes = {
  address: PropTypes.shape({
    street1: PropTypes.string.isRequired,
    street2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }).isRequired,
};
