import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';

export const ButtonsMenu = ({ items }) => {
  const [isLoading, setLoading] = useState(false);

  const onActionClick = useCallback(
    action => async e => {
      e.preventDefault();
      e.stopPropagation();

      setLoading(true);

      try {
        const result = await action(e);
        setLoading(false);
        return result;
      } catch (e) {
        setLoading(true);
        throw e;
      }
    },
    []
  );

  if (!items.length) {
    return null;
  }

  return (
    <div>
      {items.map((item, i) => (
        <Button
          key={i}
          outline
          color="dark"
          size="sm"
          disabled={item.disabled}
          onClick={onActionClick(item.action)}
          className={classNames('ml-3', item.className)}
        >
          {isLoading ? 'Processing...' : item.label}
        </Button>
      ))}
    </div>
  );
};

ButtonsMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      action: PropTypes.func.isRequired,
      className: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
};
