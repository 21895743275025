import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import { MongoChart } from './components/MongoChart';
import { createAnalyticsTokenAction } from '../../../store';
import './Analytics.scss';

const AnalyticsPageComponent = ({ createAnalyticsToken }) => {
  const getUserToken = useCallback(async () => {
    const { payload } = await createAnalyticsToken();
    return payload?.token;
  }, [createAnalyticsToken]);

  return (
    <Dashboard title="Analytics">
      <div className="vendor-portal-analytics">
        <MongoChart getUserToken={getUserToken} />
      </div>
    </Dashboard>
  );
};

AnalyticsPageComponent.propTypes = {
  vendor: PropTypes.object.isRequired,
  createAnalyticsToken: PropTypes.func.isRequired,
};

export const AnalyticsPage = withMetaTags({
  title: 'Bottomless.com: Vendor Portal',
})(
  connect(
    ({ vendorPortal }) => ({
      vendor: vendorPortal.vendor,
    }),
    dispatch => ({
      createAnalyticsToken: () => dispatch(createAnalyticsTokenAction()),
    })
  )(AnalyticsPageComponent)
);
