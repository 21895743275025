import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetVendorTypes = createAction('Vendor', 'Get Vendor');

export const getVendorAction = slug => ({
  [RSAA]: {
    endpoint: `vendors/${slug}`,
    method: 'GET',
    types: [GetVendorTypes.REQUEST, GetVendorTypes.SUCCESS, GetVendorTypes.FAILURE],
  },
});

export const SubmitOnboardingTypes = createAction('Vendor', 'Submit onbooarding');

export const submitOnboardingAction = data => ({
  [RSAA]: {
    endpoint: `vendors/onboarding`,
    method: 'POST',
    body: data,
    types: [SubmitOnboardingTypes.REQUEST, SubmitOnboardingTypes.SUCCESS, SubmitOnboardingTypes.FAILURE],
  },
});
