import { createStore as createBottomlessStore } from '@bottomless/common/store';
import { adminVendorPortalReducer } from './vendor-portal/vendor-portal.reducer';
import { usersReducer } from './user/user.reducer';
import { orderReducer } from './order/order.reducer';
import { scaleShipmentsReducer } from './scale-shipments/scale-shipment.reducer';
import { giftsReducer } from './gift/gift.reducer';

export const createStore = () =>
  createBottomlessStore({
    vendorPortal: adminVendorPortalReducer,
    users: usersReducer,
    order: orderReducer,
    scaleShipment: scaleShipmentsReducer,
    gift: giftsReducer,
  });
