import { groupBy } from 'lodash-es';
import PropTypes from 'prop-types';
import qs from 'query-string';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import { Printer } from 'react-feather';
import { connect } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import slugify from 'slugify';
import * as Yup from 'yup';
import { Form, Field, DataLoading, Pagination } from '@bottomless/common/components';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { GetVendorUnscannedOrdersAction } from '../../../store/vendor-portal';
import { UnscannedBatch } from './components/UnscannedBatch';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import './Orders.scss';

const Schema = Yup.object().shape({
  layout: Yup.string().required(),
});

const UnscannedOrdersPageComponent = ({ getOrders, orders, vendor, isLoading }) => {
  const [order, setOrder] = useState(null);
  const [batchId, setBatchId] = useState(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const toggle = () => {
    if (isOpen && order) {
      setOrder(null);
    }

    setOpen(!isOpen);
  };

  const isLast8Hours = useCallback(dateFulfilled => moment().diff(moment(dateFulfilled), 'hours') < 8, []);

  const onSubmit = data => {
    const filename = `${vendor.name}-labels-${data.layout}`;
    const orderUrl = order ? `&orderId=${order._id}` : '';
    const batchUrl = batchId ? `batchId=${batchId}` : 'batchId=all';

    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/vendor-portal/labels/${slugify(
        filename
      )}?${batchUrl}${orderUrl}&layout=${data.layout}`
    );
  };

  const onCSV = batchId => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/api/vendor-portal/csv/?batchId=${batchId}`);
  };

  return (
    <Dashboard showFulfillmentTimelines title="Unscanned orders" className="page-vendor-orders">
      <>
        <DataLoading count={orders && orders.length} isLoading={isLoading}>
          <div className="text-center text-large">
            <span className="mr-2">All orders fulfilled</span>
            <span role="img" aria-label="Party Popper emoji">
              🎉
            </span>
          </div>
        </DataLoading>
        {orders && orders.length > 0 && (
          <>
            <Row className="mb-4">
              <Col>
                <Button
                  onClick={() => {
                    toggle();
                    setBatchId('all');
                  }}
                  color="success"
                  className="mr-3"
                >
                  <span className="mr-2">Print All Unscanned Labels</span>
                  <Printer size="14" />
                </Button>
              </Col>
            </Row>
            {Object.entries(groupBy(orders, 'batch_id')).map(([batchId, groupOrders]) => (
              <UnscannedBatch
                groupOrders={groupOrders}
                batchId={batchId}
                isLast8Hours={isLast8Hours}
                onCSV={onCSV}
                toggle={toggle}
                setOrder={setOrder}
                setBatchId={setBatchId}
                key={batchId}
              />
            ))}
            <div className="mt-2">
              <Pagination collection={orders} onPageChange={getOrders} />
            </div>

            <Modal isOpen={isOpen} toggle={toggle} size="sm">
              <ModalHeader toggle={toggle}>Select format</ModalHeader>
              <ModalBody>
                <Form
                  initialValues={{ layout: 'labelPrinter' }}
                  validationSchema={Schema}
                  onSubmit={onSubmit}
                  onSuccess={() => {}}
                >
                  {() => (
                    <>
                      <Field
                        type="select"
                        name="layout"
                        label="Format"
                        options={{ labelPrinter: 'Label Printer (4x6)', fullPage: 'Full page sheet' }}
                      />
                      <Button color="success">
                        <span className="mr-2">Print labels</span>
                        <Printer size="14" />
                      </Button>
                    </>
                  )}
                </Form>
              </ModalBody>
            </Modal>
          </>
        )}
      </>
    </Dashboard>
  );
};

UnscannedOrdersPageComponent.propTypes = {
  getOrders: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  orders: PropTypes.shape({
    docs: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
  }),
  vendor: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export const UnscannedOrdersPage = withMetaTags({
  title: 'Bottomless.com: Vendor Portal',
})(
  connect(
    ({ vendorPortal }) => ({
      vendor: vendorPortal.vendor,
      orders: vendorPortal.orders,
      isLoading: vendorPortal.isLoading,
    }),
    (dispatch, { location: { search } }) => ({
      getOrders: () => dispatch(GetVendorUnscannedOrdersAction(qs.parse(search))),
    })
  )(UnscannedOrdersPageComponent)
);
