import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loader } from '@bottomless/common/components';
import { useScript } from '@bottomless/common/hooks';
import { withMetaTags } from '../../components/MetaTags/MetaTags';
import { Simple } from '../Simple/Simple';
import { getVendorAction } from '../../store/vendor-portal';
import { FulfillmentTimes } from './components/FulfillmentTimes/FulfillmentTimes';
import './Dashboard.scss';
import { ChevronLeft } from 'react-feather';

const Expired = () => (
  <Simple>
    <Container className="pt-5">
      <h1 className="text-danger text-center">Your session has expired</h1>
    </Container>
  </Simple>
);

export const VendorPortal = ({
  children,
  title,
  subtitle,
  getVendor,
  vendor,
  isLoggedIn,
  className,
  showFulfillmentTimelines,
  tabs,
  back,
}) => {
  useEffect(() => {
    getVendor();
  }, [getVendor]);

  const [loaded, error] = useScript(`https://chat-assets.frontapp.com/v1/chat.bundle.js`);

  useEffect(() => {
    if (
      process.env.REACT_APP_FRONT_APP_CHAT_ID &&
      'FrontChat' in window &&
      vendor &&
      vendor.secret &&
      vendor.secret.front
    ) {
      window.FrontChat('init', {
        chatId: process.env.REACT_APP_FRONT_APP_CHAT_ID,
        useDefaultLauncher: true,
        email: vendor.ordering_email,
        userHash: vendor.secret.front,
      });
    }
  }, [loaded, error, vendor]);

  if (!isLoggedIn) {
    return <Expired />;
  }

  if (!vendor) {
    return <Loader />;
  }

  return (
    <Simple className={className}>
      <Container className="vendor-portal mt-4 mb-5">
        {vendor && (
          <>
            <header className="mb-1">
              <div className="d-flex justify-content-between mb-4">
                <div>
                  <h3 className="mb-0 text-left font-weight-bold">{title}</h3>
                  {subtitle && <div className="text-secondary">{subtitle}</div>}
                  {back && (
                    <Link to={back} className="d-flex mt-2 align-items-center text-decoration-none">
                      <ChevronLeft size="14" /> Back
                    </Link>
                  )}
                </div>

                {showFulfillmentTimelines && <FulfillmentTimes />}
              </div>
              {tabs && (
                <Nav tabs>
                  {tabs.map(({ to, name }) => (
                    <NavItem key={to}>
                      <NavLink className="nav-link" to={to} exact>
                        {name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              )}
            </header>
            <div className="mt-4">{children}</div>
          </>
        )}
      </Container>
    </Simple>
  );
};

VendorPortal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  getVendor: PropTypes.func.isRequired,
  vendor: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    img_url: PropTypes.string.isRequired,
    ordering_email: PropTypes.string.isRequired,
    secret: PropTypes.shape({
      front: PropTypes.string.isRequired,
    }).isRequired,
    accounting: PropTypes.shape({
      stripe_connected_account: PropTypes.string,
    }).isRequired,
  }),
  isLoggedIn: PropTypes.bool.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  showFulfillmentTimelines: PropTypes.bool,
  tabs: PropTypes.array,
  back: PropTypes.array,
};

export const Dashboard = withMetaTags({
  title: 'Bottomless.com: Vendor Portal',
})(
  connect(
    ({ vendorPortal }) => ({
      vendor: vendorPortal.vendor,
      isLoggedIn: vendorPortal.isLoggedIn,
    }),
    dispatch => ({
      getVendor: () => dispatch(getVendorAction()),
    })
  )(VendorPortal)
);
