import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetPickupLocationsTypes = createAction('Vendor location', 'Get Pickup locations');

export const getPickupLocationsAction = () => ({
  [RSAA]: {
    endpoint: `vendor-portal/location/pickup`,
    method: 'GET',
    types: [GetPickupLocationsTypes.REQUEST, GetPickupLocationsTypes.SUCCESS, GetPickupLocationsTypes.FAILURE],
  },
});
