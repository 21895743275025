import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

export const FulfillmentCol = ({ day, curFulfillmentTime }) => {
  const [days] = useState(Math.floor(curFulfillmentTime.median / 24));
  const [hours] = useState(curFulfillmentTime.median % 24);

  const timeMapping = {
    24: ['green', 'faster than average'],
    32: ['orange', 'average'],
    Infinity: ['red', 'slower than average'],
  };

  const getMessage = (time = curFulfillmentTime.median) => {
    for (const curTime in timeMapping) {
      if (time <= curTime) {
        return timeMapping[curTime][1];
      }
    }
  };

  const getColor = (time = curFulfillmentTime.median) => {
    for (const curTime in timeMapping) {
      if (time <= curTime) {
        return timeMapping[curTime][0];
      }
    }
  };

  const timeText = () => {
    let text = '';
    if (days !== 0) {
      text += days + ' days';
      if (hours !== 0) {
        text += ' and ';
      }
    }
    if (hours !== 0) {
      text += hours + ' h';
    }
    return text;
  };

  return (
    <Col className="text-center">
      <div className="text-uppercase font-weight-bold">{day.substring(0, 3)}</div>
      {curFulfillmentTime.median === null ? (
        <span> Need more shipments to get time! </span>
      ) : (
        <>
          <div>
            ~<span style={{ color: getColor() }}> {timeText()} </span>
          </div>
          <div className="small text-secondary">{getMessage()}</div>
        </>
      )}
    </Col>
  );
};

FulfillmentCol.propTypes = {
  day: PropTypes.string.isRequired,
  curFulfillmentTime: PropTypes.shape({
    perc10: PropTypes.number,
    perc90: PropTypes.number,
    median: PropTypes.number,
    num: PropTypes.number,
  }),
};
