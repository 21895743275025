import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const TimelineDateComponent = ({ date }) => {
  const momentDate = moment(date);
  const isToday = momentDate.isSame(moment(), 'day');

  return (
    <div className="date-container">
      <div className="timeline-node">
        <div className="line"></div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="flex-grow-1 ml-2">{isToday ? 'Today' : momentDate.format('MMMM D, YYYY')}</div>
      </div>
    </div>
  );
};

TimelineDateComponent.propTypes = {
  date: PropTypes.string.isRequired,
};

export const TimelineDate = memo(TimelineDateComponent);
