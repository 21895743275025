import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const CreateCustomerNoteTypes = createAction('Vendor customer notes', 'Create');

export const createCustomerNoteAction = data => ({
  [RSAA]: {
    endpoint: 'vendor-portal/customer_notes',
    method: 'POST',
    body: data,
    types: [CreateCustomerNoteTypes.REQUEST, CreateCustomerNoteTypes.SUCCESS, CreateCustomerNoteTypes.FAILURE],
  },
});

export const GetCustomerNotesTypes = createAction('Vendor customer notes', 'Get all');

export const getCustomerNotesAction = id => ({
  [RSAA]: {
    endpoint: `vendor-portal/customer_notes/${id}`,
    method: 'GET',
    types: [GetCustomerNotesTypes.REQUEST, GetCustomerNotesTypes.SUCCESS, GetCustomerNotesTypes.FAILURE],
  },
});
