import { get, has, isString } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Container, FormFeedback, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Checkbox, AddressInput } from '@bottomless/common/components';
import { week } from '@bottomless/common/utils';
import { addToastAction } from '@bottomless/common/store';
import { GoogleMapsLoaded, GoogleMapsLoader } from '../../../components/Maps/GoogleMapsLoader';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { Landing } from '../../../layouts/Landing/Landing';
import { submitOnboardingAction } from '../../../store/vendor';

const sizes = [8, 10, 12, 16, 24, 32, 48, 80];

const paymentMethods = ['ACH', 'Credit Card', 'Check', 'Bottomless Initiated', 'Other'];

const integrations = [
  'Shopify Integration',
  'Quickbooks Integration',
  "Don't care, just Automatic",
  'Magento Integration',
  'Check/ACH',
  'Credit Card On-File',
  'Other',
];

const goals = [
  'Tons of Orders',
  'Orders Within a Time',
  'Fresh Coffee to Customers',
  'Building Brand Recognition',
  'Managing Inventory Ahead of Time',
  'Proper Operations',
  'Automating Payments & Accounting',
  'Other',
];

const customers = [
  'All Customers',
  'Only Office Customers',
  'E-commerce Online Customers Only',
  'Other Customers',
  'Other',
];

const FIELD_OTHER = 'Other';

const checkboxOtherSchema = opts =>
  Yup.object(
    opts.reduce(
      (all, opt) => ({
        ...all,
        [opt]: Yup.bool(),
      }),
      {
        [`${FIELD_OTHER}_text`]: Yup.string().when(FIELD_OTHER, {
          is: true,
          then: Yup.string().required('This field is required'),
        }),
      }
    )
  ).test(
    'oneOfRequired',
    'At least one option is required',
    value => Object.values(value).filter(v => v === true).length > 0
  );

const Schema = Yup.object().shape({
  company: Yup.string().required('This field is required'),
  location: Yup.string().required('This field is required'),
  website: Yup.string()
    .url()
    .required('This field is required'),
  contact: Yup.string().required('This field is required'),
  email: Yup.string()
    .email()
    .required('This field is required'),
  phone: Yup.string()
    .matches(/^\+1\s.?[(]?[0-9]{3}[)]\s.?[0-9]{3}[-][0-9]{4}|^\+1[0-9]{10}/i, 'Invalid phone number')
    .required('This field is required'),
  roasting_days: Yup.object({
    monday: Yup.bool(),
    tuesday: Yup.bool(),
    wednesday: Yup.bool(),
    thursday: Yup.bool(),
    friday: Yup.bool(),
    saturday: Yup.bool(),
    sunday: Yup.bool(),
  }).test(
    'oneOfRequired',
    'At least one option is required',
    value => Object.values(value).filter(v => v === true).length > 0
  ),
  fulfillment_days: Yup.object()
    .shape({
      monday: Yup.bool(),
      tuesday: Yup.bool(),
      wednesday: Yup.bool(),
      thursday: Yup.bool(),
      friday: Yup.bool(),
      saturday: Yup.bool(),
      sunday: Yup.bool(),
    })
    .test(
      'oneOfRequired',
      'At least one option is required',
      value => Object.values(value).filter(v => v === true).length > 0
    ),
  verifiedAddress: Yup.object()
    .shape({
      street1: Yup.string().required('This field is required'),
      city: Yup.string().required('This field is required'),
      zip: Yup.string().required('This field is required'),
      state: Yup.string().required('This field is required'),
      street2: Yup.string(),
    })
    .required(),
  sizes: checkboxOtherSchema(sizes),
  paymentMethods: checkboxOtherSchema(paymentMethods),
  customers: checkboxOtherSchema(customers),
  integrations: checkboxOtherSchema(integrations),
  goals: checkboxOtherSchema(goals),
  ordering_email: Yup.string()
    .email()
    .required(),
  description: Yup.string()
    .min(100)
    .required(),
  w9_agreement: Yup.bool().oneOf([true], 'Field must be checked'),
  products_agreement: Yup.bool().oneOf([true], 'Field must be checked'),
});

const CheckboxOtherField = ({ label, options, fieldName, values, errors }) => {
  return (
    <div className="d-flex flex-column mb-4">
      <span className="mb-1">{label}</span>
      <div className="d-flex flex-column flex-sm-row flex-wrap">
        {options.map((opt, i) => (
          <div className="my-1 mx-1 col text-nowrap flex-grow-0" key={i}>
            <Checkbox name={`${fieldName}[${opt}]`} label={opt} />
          </div>
        ))}
      </div>

      {values && values[fieldName] && values[fieldName][FIELD_OTHER] && (
        <div className="mt-2">
          <Field name={`${fieldName}[${FIELD_OTHER}_text]`} type="text" label="Other" />
        </div>
      )}

      {has(errors, fieldName) && isString(get(errors, fieldName)) && (
        <FormFeedback className="text-left d-block">{get(errors, fieldName)}</FormFeedback>
      )}
    </div>
  );
};

CheckboxOtherField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string.isRequired),
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
};

const checkboxDefaults = opts => opts.reduce((all, opt) => ({ ...all, [opt]: false }), {});

const VendorOnboardingComponent = ({ submitOnboarding, addToast }) => {
  const [finished, setFinished] = useState(false);

  return (
    <GoogleMapsLoader>
      <Landing withPadding>
        <Container>
          <Row className="justify-content-center mb-4">
            {finished && (
              <Alert color="success">
                <span>Thank you for filling out your information!</span>
                <p className="mb-0">We’ve sent you an email with the confirmation at the email address you provided</p>
              </Alert>
            )}
            {!finished && (
              <Col xs="12" sm="10" className={'pb-4'}>
                <Form
                  initialValues={{
                    company: '',
                    location: '',
                    website: '',
                    contact: '',
                    phone: '',
                    email: '',
                    comments: '',
                    fulfillment_days: {
                      monday: false,
                      tuesday: false,
                      wednesday: false,
                      thursday: false,
                      friday: false,
                      saturday: false,
                      sunday: false,
                    },
                    roasting_days: {
                      monday: false,
                      tuesday: false,
                      wednesday: false,
                      thursday: false,
                      friday: false,
                      saturday: false,
                      sunday: false,
                    },
                    sizes: checkboxDefaults(sizes),
                    paymentMethods: checkboxDefaults(paymentMethods),
                    integrations: checkboxDefaults(integrations),
                    goals: checkboxDefaults(goals),
                    customers: checkboxDefaults(customers),
                    w9_agreement: false,
                    products_agreement: false,
                  }}
                  validationSchema={Schema}
                  onSubmit={submitOnboarding}
                  onSuccess={(data, { resetForm }) => {
                    addToast('Thank you! we will contact you soon');
                    setFinished(true);
                    resetForm();
                  }}
                >
                  {({ isSubmitting, setFieldValue, values, errors }) => (
                    <>
                      <Field name="company" label="Company name" />
                      <Field name="location" label="Roastery location" />
                      <GoogleMapsLoaded>
                        <AddressInput {...{ setFieldValue, values }} />
                      </GoogleMapsLoaded>
                      <Field name="website" label="Website" />
                      <Field name="contact" label="Contact Name" />
                      <Field name="email" type="email" label="E-mail" />
                      <Field name="phone" type="phone" label="Phone number" />
                      <Field name="ordering_email" type="email" label="Ordering E-mail" />

                      <div className="d-flex flex-column mb-4">
                        <span className="mb-2">Roasting Days</span>
                        <div className="px-4">
                          <div className="d-flex flex-column flex-sm-row justify-content-between">
                            {week.map((day, i) => (
                              <div className="mr-1 text-capitalize" key={i}>
                                <Checkbox name={`roasting_days[${day}]`} label={day} />
                              </div>
                            ))}
                          </div>
                          {has(errors, 'roasting_days') && isString(get(errors, 'roasting_days')) && (
                            <FormFeedback className="text-left d-block">{get(errors, 'roasting_days')}</FormFeedback>
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-column mb-4">
                        <span className="mb-2">Shipping fulfillment Schedule</span>
                        <div className="px-4">
                          <div className="d-flex flex-column flex-sm-row justify-content-between text-capitalize">
                            {week.map((day, i) => (
                              <div className="mr-1" key={i}>
                                <Checkbox name={`fulfillment_days[${day}]`} label={day} />
                              </div>
                            ))}
                          </div>
                          {has(errors, 'fulfillment_days') && isString(get(errors, 'fulfillment_days')) && (
                            <FormFeedback className="text-left d-block">{get(errors, 'fulfillment_days')}</FormFeedback>
                          )}
                        </div>
                      </div>

                      <CheckboxOtherField
                        values={values}
                        options={sizes}
                        fieldName={'sizes'}
                        label={'Bottomless offers all sizes, mark the ones that apply for you:'}
                        errors={errors}
                      />

                      <CheckboxOtherField
                        values={values}
                        options={paymentMethods}
                        fieldName={'paymentMethods'}
                        label={'Preferred Payment Method'}
                        errors={errors}
                      />

                      <CheckboxOtherField
                        values={values}
                        options={integrations}
                        fieldName={'integrations'}
                        label={'What arrangement would be ideal'}
                        errors={errors}
                      />

                      <CheckboxOtherField
                        values={values}
                        options={goals}
                        fieldName={'goals'}
                        label={'What do you most care about'}
                        errors={errors}
                      />

                      <CheckboxOtherField
                        values={values}
                        options={customers}
                        fieldName={'customers'}
                        label={'Would you like to offer Bottomless to your customers?'}
                        errors={errors}
                      />

                      <Field
                        type="textarea"
                        name="description"
                        label="Introduction Paragraph to Bottomless customers"
                      />

                      <div className="mb-2">
                        <Checkbox
                          name="w9_agreement"
                          label={
                            <span>
                              Please fill-out W9 information: Please download this form{' '}
                              <a
                                href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-primary"
                              >
                                https://www.irs.gov/pub/irs-pdf/fw9.pdf
                              </a>{' '}
                              and email the complete form to{' '}
                              <a href="mailto:operations@bottomless.com" className="text-primary">
                                operations@bottomless.com
                              </a>
                            </span>
                          }
                        />
                      </div>

                      <div className="mb-4">
                        <Checkbox
                          name="products_agreement"
                          label={
                            <span>
                              Products: please email your pricing list to{' '}
                              <a href="mailto:operations@bottomless.com" className="text-primary">
                                operations@bottomless.com
                              </a>
                            </span>
                          }
                        />
                      </div>

                      <div>
                        <SubmitButton color="dark" isSubmitting={isSubmitting} loadingText="Signing up">
                          Submit <i className="fa fa-long-arrow-right ml-1" />
                        </SubmitButton>
                      </div>
                    </>
                  )}
                </Form>
              </Col>
            )}
          </Row>
        </Container>
      </Landing>
    </GoogleMapsLoader>
  );
};

VendorOnboardingComponent.propTypes = {
  submitOnboarding: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const VendorOnboardingPage = connect(null, dispatch => ({
  submitOnboarding: data => dispatch(submitOnboardingAction(data)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
}))(
  withMetaTags({
    title: 'Bottomless.com: Vendor Onboarding',
  })(VendorOnboardingComponent)
);
