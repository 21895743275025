import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Field, Form, SubmitButton } from '@bottomless/common/components';
import { Button, Col, Label, Row } from 'reactstrap';
import { get } from 'lodash-es';
import { Trash2 } from 'react-feather';
import { getVariantSelectLabel } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  product: Yup.string().required('This field is required'),
  variant: Yup.string().required('This field is required'),
  quantity: Yup.number()
    .min(1)
    .required('This field is required'),
  grind: Yup.string(),
  lineItems: Yup.array().of(
    Yup.object({
      product: Yup.string().required('This field is required'),
      variant: Yup.string().required('This field is required'),
      quantity: Yup.string().required('This field is required'),
      grind: Yup.string(),
    })
  ),
});

const createSelectOptions = (data, label, inputLabel = item => item.name) =>
  !data
    ? { null: label }
    : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

export const UpdateProductForm = ({ user, products, options, onSubmit, onSuccess }) => {
  const isSubscriptionByUsage = useMemo(() => !!user.shopifySubscriptionContractId, [user]);

  const form = useRef();

  const grindOptions = useMemo(() => createSelectOptions(options.grind), [options]);

  const initialValues = useMemo(
    () => ({
      product: user.product.product._id,
      variant: user.product.variant,
      quantity: user.quantity || 1,
      grind: user.grind?._id || '',
      lineItems:
        user.lineItems?.map(l => ({
          product: l.product._id,
          variant: l.variant,
          quantity: l.quantity,
          grind: l.grind?._id,
        })) || [],
    }),
    [user]
  );

  const addNewProduct = useCallback(() => {
    form.current.setFieldValue('lineItems', [
      ...form.current.state.values.lineItems,
      { product: '', variant: '', quantity: 1, grind: '' },
    ]);
  }, [form]);

  const removeProduct = useCallback(
    index => () => {
      form.current.setFieldValue('lineItems', [
        ...form.current.state.values.lineItems.slice(0, index),
        ...form.current.state.values.lineItems.slice(index + 1),
      ]);
    },
    [form]
  );

  const onProductPick = useCallback(
    (path = []) => () => {
      form.current.setFieldValue([...path, 'variant'].join('.'), '');
    },
    [form]
  );

  const productHasGrind = useCallback(
    productId => {
      const product = products.find(p => p._id === productId);

      if (!product) {
        return { supported: true, disabled: true };
      }

      const supported = product.category.manifest.attributes.find(attribute => attribute.field === 'grind');

      const disabled = !product.available_ground;

      return { supported, disabled };
    },
    [products]
  );

  return (
    <Form
      innerRef={form}
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      className="form-admin-edit-product"
    >
      {({ isSubmitting, values }) => (
        <>
          <div className="first-product-edit-row">
            <Row>
              <Col xs="12" sm="3">
                <Field
                  name="product"
                  type="select"
                  label="Product"
                  options={createSelectOptions(products, '--- select product ---', item => item.name)}
                  onChange={onProductPick()}
                />
              </Col>
              <Col xs="12" sm="3">
                <Field
                  name="variant"
                  type="select"
                  label="Variant"
                  options={createSelectOptions(
                    products.find(p => p._id === values.product)?.variants?.filter(v => v.available),
                    '--- select variant ---',
                    getVariantSelectLabel
                  )}
                />
              </Col>
              <Col xs="12" sm="3">
                {productHasGrind(values.product).supported ? (
                  <Field
                    name="grind"
                    label="Grind"
                    type="select"
                    options={grindOptions}
                    disabled={productHasGrind(values.product).disabled}
                  />
                ) : (
                  <div>
                    <Label>Grind</Label>
                    <div className="text-secondary mt-2">Doesn&apos;t apply</div>
                  </div>
                )}
              </Col>
              <Col xs="12" sm="3">
                <Field name="quantity" type="number" label="Quantity" min="1" disabled={!isSubscriptionByUsage} />
              </Col>
            </Row>
          </div>
          {values.lineItems.map((_, key) => (
            <div key={key} className="d-flex align-items-start justify-content-between">
              <Row>
                <Col xs="12" sm="3">
                  <Field
                    name={`lineItems[${key}].product`}
                    type="select"
                    label="Product"
                    options={createSelectOptions(products, '--- select product ---', item => item.name)}
                    onChange={onProductPick([`lineItems[${key}]`])}
                  />
                </Col>
                <Col xs="12" sm="3">
                  <Field
                    name={`lineItems[${key}].variant`}
                    type="select"
                    label="Variant"
                    options={createSelectOptions(
                      products
                        .find(p => p._id === get(values, ['lineItems', key, 'product']))
                        ?.variants?.filter(v => v.available),
                      '--- select variant ---',
                      getVariantSelectLabel
                    )}
                  />
                </Col>
                <Col xs="12" sm="3">
                  {productHasGrind(get(values.lineItems, [key, 'product'])).supported ? (
                    <Field
                      name={`lineItems[${key}].grind`}
                      label="Grind"
                      type="select"
                      options={grindOptions}
                      disabled={productHasGrind(get(values.lineItems, [key, 'product'])).disabled}
                    />
                  ) : (
                    <div>
                      <Label>Grind</Label>
                      <div className="text-secondary mt-2">Doesn&apos;t apply</div>
                    </div>
                  )}
                </Col>
                <Col xs="12" sm="3">
                  <Field
                    name={`lineItems[${key}].quantity`}
                    type="number"
                    label="Quantity"
                    min="1"
                    disabled={!isSubscriptionByUsage}
                  />
                </Col>
              </Row>
              <Button color="secondary" outline className="ml-4 mt-4" onClick={removeProduct(key)}>
                <Trash2 size="12" />
              </Button>
            </div>
          ))}
          {isSubscriptionByUsage && (
            <Button onClick={addNewProduct} color="dark" outline className="mt-3">
              + Add product
            </Button>
          )}
          <div className="d-flex justify-content-end">
            <SubmitButton size="sm" color="dark" isSubmitting={isSubmitting}>
              Save
            </SubmitButton>
          </div>
        </>
      )}
    </Form>
  );
};

UpdateProductForm.propTypes = {
  user: PropTypes.shape({
    product: PropTypes.shape({
      product: PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }),
      variant: PropTypes.string.isRequired,
    }),
    grind: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    quantity: PropTypes.number,
    lineItems: PropTypes.array,
    shopifySubscriptionContractId: PropTypes.string,
  }).isRequired,
  products: PropTypes.array.isRequired,
  options: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
