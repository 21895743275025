import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { SubmitButton, Form, Field, DateFormat } from '@bottomless/common/components';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { READABLE_FORMAT } from '../../../../utils/dates';
import { Edit2 } from 'react-feather';

const Schema = Yup.object().shape({
  override_fulfillment_date: Yup.date().required('This field is required'),
});

export const SkipOrder = ({ fulfillmentDate, skipOrder }) => {
  const [isOpen, toggle] = useToggle();

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <>
      <Button size="sm" color="white" onClick={toggle}>
        <Edit2 size="14" />
      </Button>
      <Modal isOpen={isOpen} size="md" toggle={toggle}>
        <ModalHeader toggle={toggle}>Skip current order</ModalHeader>
        <ModalBody>
          <Form
            initialValues={{ override_fulfillment_date: fulfillmentDate }}
            validationSchema={Schema}
            onSubmit={skipOrder}
            onSuccess={toggle}
            className="mw-300"
          >
            {({ isSubmitting, values }) => (
              <>
                <Field type="hidden" name="override_fulfillment_date" />
                <div className="d-flex justify-content-between mb-4">
                  <div className="font-weight-bolder order-heading">New reorder date</div>
                  <div>
                    <DateFormat date={values.override_fulfillment_date} format={READABLE_FORMAT} />
                  </div>
                </div>
                <div className="mb-3 text-center">
                  <SubmitButton color="dark" isSubmitting={isSubmitting}>
                    Skip order
                  </SubmitButton>
                </div>
              </>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

SkipOrder.propTypes = {
  skipOrder: PropTypes.func.isRequired,
  fulfillmentDate: PropTypes.object,
};
