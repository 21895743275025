import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

const GroupedNavOption = ({ title, subOptions: subOptionsRaw }) => {
  const location = useLocation();
  const subOptions = useMemo(
    () =>
      subOptionsRaw.filter(opt => {
        if (!opt.show) {
          return true;
        }
        return opt.show();
      }),
    [subOptionsRaw]
  );
  const isSelected = useMemo(() => subOptions.find(({ link }) => location.pathname.includes(link)), [
    location.pathname,
    subOptions,
  ]);

  return (
    <div
      className={classNames('grouped-nav', {
        'group-selected': isSelected,
      })}
    >
      <div className="grouped-option">{title}</div>
      {subOptions.map(({ link, title }) => (
        <NavLink key={title} to={link}>
          <span className="grouped-sub-option">{title}</span>
        </NavLink>
      ))}
    </div>
  );
};

GroupedNavOption.propTypes = {
  title: PropTypes.string.isRequired,
  subOptions: PropTypes.arrayOf({
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    show: PropTypes.func,
  }).isRequired,
};

export const Menu = ({ vendor }) => {
  const isShopify = useMemo(() => vendor?.shopifyManifest?.sendOrderData?.shopify, [vendor]);
  const isVendorShopifyOnly = useMemo(
    () =>
      vendor?.shopifyManifest?.fulfilledExternally?.shopify &&
      vendor?.shopifyManifest?.fulfilledExternally?.marketplace,
    [vendor]
  );

  const orderSubOptions = useMemo(() => {
    const options = [];
    if (!isVendorShopifyOnly) {
      options.push({ title: 'Unprocessed', link: '/vendor/dashboard/unprocessed' });
    }

    options.push(
      { title: 'Unscanned', link: '/vendor/dashboard/unscanned' },
      { title: 'History', link: '/vendor/dashboard/history' }
    );

    if (isShopify) {
      options.push({ title: 'Tracking', link: '/vendor/dashboard/tracking/sent_to_roaster' });
    }
    return options;
  }, [isShopify, isVendorShopifyOnly]);

  return (
    <div className="panel-nav">
      <div className="nav-section d-flex flex-md-column">
        <NavLink to="/" className={classNames('panel-nav-logo mb-3', { 'flex-column vendor-lock': vendor })}>
          {!vendor && <img src="/images/Bottomless-logo-blue.png" alt="Bottomless" width="140" />}
          {vendor && (
            <>
              <img src={vendor.img_url} alt={vendor.name} className="vendor-logo mb-2" />
            </>
          )}
        </NavLink>
        <GroupedNavOption title="Orders" subOptions={orderSubOptions} />
        {isShopify && (
          <>
            <NavLink to="/vendor/dashboard/users">
              <span>Users</span>
            </NavLink>
            <NavLink to="/vendor/dashboard/analytics">
              <span>Analytics</span>
            </NavLink>
            <NavLink to="/vendor/dashboard/scale-shipments">
              <span>Scale Shipments</span>
            </NavLink>
            <NavLink to="/vendor/dashboard/gifts">
              <span>Gifts</span>
            </NavLink>
          </>
        )}
        <NavLink to="/vendor/dashboard/products">
          <span>Products</span>
        </NavLink>
        <NavLink to="/vendor/dashboard/products-feedback">
          <span>Products Feedback</span>
        </NavLink>
        <NavLink to="/vendor/dashboard/payout">
          <span>Payout</span>
        </NavLink>
      </div>
    </div>
  );
};

Menu.propTypes = {
  vendor: PropTypes.object,
};
