import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { Loader } from 'react-feather';
import PropTypes from 'prop-types';

export const FulfillOrderInShopifyModal = ({ isOpen, toggle, fulfillOrderInShopify, orders, addToast }) => {
  const [isSubmitting, setIsSubmitting] = useState();
  const [isError, setIsError] = useState(false);

  const onSubmit = async () => {
    const submit = async jobId => {
      setIsSubmitting(true);
      try {
        const res = await fulfillOrderInShopify({ orders: orders.map(row => row._id), jobId, async: true });

        if (res.payload?.async) {
          if (res.payload?.status === 'preparing') {
            setTimeout(() => submit(res.payload?.jobId), 5000);
          } else if (res.payload?.status === 'ready') {
            addToast('Orders fulfilled in Shopify', 'success');
            toggle();
          } else {
            setIsError(true);
            addToast('Something went wrong, Please try again!', 'error');
          }
          return;
        }

        addToast('Orders fulfilled in Shopify', 'success');
        toggle();
      } catch (err) {
        setIsError(true);
      }
      setIsSubmitting(false);
    };

    await submit();
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Mark as fulfilled</ModalHeader>
      <ModalBody>
        {isError ? 'Do you want to retry?' : `Do you want to mark ${orders?.length} order(s) in Shopify as fulfilled?`}
        <div className="small mt-3 text-secondary">
          Depending on the number of orders, this can take up to a few minutes.
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="transparent" outline className="mr-3" size="sm" onClick={toggle} disabled={isSubmitting}>
          No
        </Button>
        <Button color="success" onClick={onSubmit} disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              Fulfilling... <Loader size="13" className="spin ml-2" />
            </>
          ) : (
            'Yes'
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

FulfillOrderInShopifyModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  fulfillOrderInShopify: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  addToast: PropTypes.func.isRequired,
};
