import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { SubscriptionType, UserStatuses } from '@bottomless/common/constants';
import { getFormattedStatus } from '../../utils/user';
import { ScaleShipment } from './components/ScaleShipment';
import './UserStatus.scss';

const STATUS_CANCELLED = 'cancelled';
const STATUS_PAUSED = 'paused';
const STATUS_ACTIVE = 'active';

export const UserStatus = ({
  user,
  showScaleShipmentStatus: showScaleShipmentStatusRaw,
  getScaleShipmentTrackingForUser,
}) => {
  const showScaleShipmentStatus = useMemo(
    () =>
      showScaleShipmentStatusRaw &&
      [UserStatuses.OnboardingScaleShipped, UserStatuses.OnboardingPaid, UserStatuses.ScaleReplaced].includes(
        user.status
      ) &&
      !user.hibernate &&
      !user.pausedUntil &&
      user.subscriptionType === SubscriptionType.Dynamic,
    [user, showScaleShipmentStatusRaw]
  );

  const status = useMemo(() => {
    if ([UserStatuses.Closed, UserStatuses.Offboarding].includes(user.status) || user.hibernate) {
      return STATUS_CANCELLED;
    }

    if (user.pausedUntil) {
      return STATUS_PAUSED;
    }

    if (
      user.subscriptionType !== SubscriptionType.Dynamic &&
      [UserStatuses.OnboardingScaleShipped, UserStatuses.OnboardingPaid, UserStatuses.ScaleReplaced].includes(
        user.status
      )
    ) {
      return STATUS_ACTIVE;
    }

    return user.status;
  }, [user]);

  const color = useMemo(() => {
    switch (status) {
      case UserStatuses.Active:
      case UserStatuses.Guest:
        return 'success';
      case UserStatus.Closed:
      case STATUS_CANCELLED:
        return 'danger';
      case UserStatuses.OnboardingPaid:
      case STATUS_PAUSED:
        return 'warning';
      case UserStatuses.OnboardingScaleShipped:
        return 'secondary';
      default:
        return 'info';
    }
  }, [status]);

  return (
    <div>
      <Badge pill color={color} className="text-capitalize">
        {getFormattedStatus(status)}
      </Badge>
      {showScaleShipmentStatus && (
        <ScaleShipment getScaleShipmentTrackingForUser={getScaleShipmentTrackingForUser} user={user} />
      )}
    </div>
  );
};

UserStatus.propTypes = {
  user: PropTypes.shape({
    status: PropTypes.string.isRequired,
    hibernate: PropTypes.bool,
    pausedUntil: PropTypes.string,
    subscriptionType: PropTypes.string,
  }).isRequired,
  showScaleShipmentStatus: PropTypes.bool,
  getScaleShipmentTrackingForUser: PropTypes.func.isRequired,
};
