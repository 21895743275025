import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { ErrorBoundary } from '@bottomless/common/components';
import { Menu } from './components';
import './Panel.scss';

const PanelComponent = ({ children, vendor }) => {
  return (
    <>
      <div className="panel-layout">
        <Menu vendor={vendor} />
        <div className="panel-wrapper">
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </div>
    </>
  );
};

PanelComponent.propTypes = {
  children: PropTypes.node.isRequired,
  vendor: PropTypes.object,
};

export const Panel = connect(({ vendorPortal }) => ({
  vendor: vendorPortal.vendor,
}))(PanelComponent);
