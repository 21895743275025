import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Radio } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  type: Yup.string().required(),
  deliveryPrice: Yup.number().required(),
});

export const ShippingPriceForm = ({ user, onSubmitSuccess, updateShipping }) => {
  const onSubmit = useCallback(data => updateShipping(user._id, data), [updateShipping, user]);
  const type = useMemo(
    () => (user.deliveryPrice !== null && user.deliveryPrice !== undefined ? 'fixed' : 'automatic'),
    [user]
  );

  return (
    <Form
      initialValues={{
        type,
        deliveryPrice: type === 'fixed' ? Number(user.deliveryPrice).toFixed(2) : 0,
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting, values }) => (
        <>
          <Radio label="Dynamic" type="radio" name="type" value="automatic" />
          <div>
            <div>
              The shipping price will be automatically calculated based on your delivery and shipping rules for each
              order.
            </div>
          </div>
          <hr />
          <Radio label="Fixed Price" type="radio" name="type" value="fixed" />
          <Field name="deliveryPrice" label="Shipping Price" type="field" disabled={values.type !== 'fixed'} />
          <SubmitButton color="dark" disabled={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

ShippingPriceForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmitSuccess: PropTypes.func,
  updateDumbSubscription: PropTypes.func,
  updateShipping: PropTypes.func.isRequired,
};
