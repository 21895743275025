import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetTempUserByUserIdTypes = createAction('Vendor temp users', 'Get by user id');

export const getTempUserByUserIdAction = userId => ({
  [RSAA]: {
    endpoint: `vendor-portal/temp-users/${userId}/user-id`,
    method: 'GET',
    types: [GetTempUserByUserIdTypes.REQUEST, GetTempUserByUserIdTypes.SUCCESS, GetTempUserByUserIdTypes.FAILURE],
  },
});
