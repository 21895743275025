import { get } from 'lodash-es';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Printer } from 'react-feather';
import { Badge, Col, Row } from 'reactstrap';
import { Tracking } from '@bottomless/common/components';
import { FulifillmentDate } from './FulfillmentDate';
import { Box, BoxCollapsible } from '../../../../components/Box';
import { SimpleAddress } from '../../../../components/SimpleAddress';

export const Order = ({ order, toggle, withDate, unscanned, fulfilledInShopify }) => {
  const orderedProduct = get(order, 'subproduct_id.product');
  const variant = get(order, 'subproduct_id.product.variants', []).find(v => v._id === order.subproduct_id.variant);
  const address = get(order, 'user_id.verifiedAddress');
  const user = get(order, 'user_id');

  const isNon12ozVariant = variant.size !== 12;
  const isNonWholeBeanGrind = order.grind && order.grind.name !== 'Whole Beans';

  return (
    <Box forceOpen={unscanned}>
      <Row>
        <Col xs="10">
          <div>
            {user && (
              <span>
                {user.first_name} {user.last_name}
              </span>
            )}
            {orderedProduct && variant && (
              <>
                <div className="text-secondary">
                  <span>
                    {orderedProduct.name}
                    <span className="mx-2 text-secondary">|</span>
                    <span className={isNon12ozVariant ? 'non-12oz-variant' : ''}>{variant.size} oz</span>
                    {order.grind && (
                      <>
                        <span className="mx-2 text-secondary">|</span>
                        <span className={classNames({ 'non-whole-beans-grind': isNonWholeBeanGrind })}>
                          {order.grind.name}
                        </span>
                      </>
                    )}
                    {order.sentWithScale && (
                      <>
                        <span className="mx-2 text-secondary">|</span>
                        <span> + Scale</span>
                      </>
                    )}
                  </span>
                </div>
                {order.lineItems?.length > 0 && <div className="text-secondary">+{order.lineItems.length} more</div>}
              </>
            )}
          </div>
          {withDate && (
            <div className="small text-secondary">
              Order date: <FulifillmentDate dateFulfilled={order.date_fulfilled} />
            </div>
          )}
        </Col>
        {order.usps_tracking && (
          <Col xs="2" className="text-right">
            <Badge
              className="usps-status text-capitalize"
              pill
              color={classNames({
                danger: ['return_to_sender', 'alert'].includes(order.usps_tracking.status),
                success: order.usps_tracking.status === 'delivered',
                primary: order.usps_tracking.status === 'in_transit',
                warning: order.usps_tracking.status === 'pre_transit',
                info: order.usps_tracking.status === 'accepted',
                secondary: order.usps_tracking.status === 'unknown',
              })}
            >
              {get(order, 'usps_tracking.status', '---').replace(/_/g, ' ')}
            </Badge>
            {fulfilledInShopify && (
              <Badge className="usps-status text-capitalize" pill color="primary">
                Fulfilled via Shopify
              </Badge>
            )}
          </Col>
        )}
      </Row>
      <BoxCollapsible>
        <Row className="d-flex">
          {address && (
            <Col>
              <SimpleAddress address={address} />
            </Col>
          )}
          <Col>
            {order.label_url && (
              <div>
                Shipping label:{' '}
                <a href={order.label_url} rel="noopener noreferrer" target="_blank" className="text-primary">
                  label
                </a>
                <span onClick={toggle} className="ml-2 cursor-pointer">
                  <Printer size="14" />
                </span>
              </div>
            )}
            {order.usps_tracking && order.usps_tracking.tracking_number && (
              <div>
                {order.usps_tracking.tracking_number && (
                  <Tracking
                    label="Tracking"
                    number={order.usps_tracking.tracking_number}
                    shippingService={order.shipping_service}
                    trackingUrl={order.tracking_url}
                  />
                )}
              </div>
            )}
          </Col>
        </Row>
      </BoxCollapsible>
    </Box>
  );
};

Order.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    label_url: PropTypes.string,
    tracking_number: PropTypes.string,
    date_fulfilled: PropTypes.string,
    subproduct_id: PropTypes.object,
    user_id: PropTypes.object,
    sentWithScale: PropTypes.bool,
    grind: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    usps_tracking: PropTypes.shape({
      tracking_number: PropTypes.string,
      status: PropTypes.string,
    }),
    shipping_service: PropTypes.string,
    tracking_url: PropTypes.string,
    lineItems: PropTypes.string,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
  withDate: PropTypes.bool,
  unscanned: PropTypes.bool,
  fulfilledInShopify: PropTypes.bool,
};

Order.defaultProps = {
  withDate: true,
  unscanned: false,
};
