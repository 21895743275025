import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ButtonDropdown, DropdownItem, DropdownMenu as BootstrapDropdownMenu, DropdownToggle } from 'reactstrap';
import { MoreVertical } from 'react-feather';
import { useToggle } from '@bottomless/common/hooks';
import { useState } from 'react';

export const DropdownMenu = ({ items }) => {
  const [open, toggle] = useToggle();
  const [isLoading, setLoading] = useState(false);

  const onClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onActionClick = useCallback(
    action => async e => {
      e.preventDefault();
      e.stopPropagation();

      setLoading(true);

      try {
        const result = await action(e);
        setLoading(false);
        return result;
      } catch (e) {
        setLoading(true);
        throw e;
      }
    },
    []
  );

  if (!items.length) {
    return null;
  }

  return (
    <ButtonDropdown isOpen={open} toggle={toggle} className="menu-actions">
      <DropdownToggle color="text" onClick={onClick} className="shadow-none pr-0">
        <MoreVertical />
      </DropdownToggle>
      <BootstrapDropdownMenu right>
        {items.map((item, i) => (
          <DropdownItem
            key={i}
            disabled={item.disabled}
            onClick={onActionClick(item.action)}
            className={item.className}
          >
            {isLoading ? 'Processing...' : item.label}
          </DropdownItem>
        ))}
      </BootstrapDropdownMenu>
    </ButtonDropdown>
  );
};

DropdownMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      action: PropTypes.func.isRequired,
      className: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
};
