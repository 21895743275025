import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { ProductChangeSet } from '../../../components/ProductChangeSet/ProductChangeSet';
import {
  getVendorProposedProductAction,
  getVendorProductOptionsAction,
  requestProductChangeAction,
} from '../../../store/vendor-portal';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import { ProductForm } from './components/ProductForm';
import './Products.scss';

const ProductDetailsPageComponent = ({ getProduct, getProductOptions, requestProductChange, addToast }) => {
  const [product, setProduct] = useState();
  useDataEffect(getProduct, setProduct);

  const [productOptions, setProductOptions] = useState();
  useDataEffect(getProductOptions, setProductOptions);

  const onSuccess = data => {
    setProduct(data);
    addToast('Request change has been successfully sent');
  };

  const assignedProduct =
    product &&
    (product.product || {
      name: product.name,
      description: product.description,
      variants: [{ size: null, available: true }],
    });

  return (
    <Dashboard title={product?.name} back="/vendor/dashboard/products" className="page-vendor-products">
      <>
        <DataLoading count={product && productOptions ? 1 : 0} isLoading={!product || !productOptions}>
          Cannot find product
        </DataLoading>
        {product && productOptions && (
          <>
            {product.vendor_change_set && (
              <div className="mb-4">
                <h5>Pending request:</h5>
                <ProductChangeSet changeSet={product.vendor_change_set} productOptions={productOptions} />
              </div>
            )}
            <Alert color="info" className="mb-5">
              <strong>Info:</strong>{' '}
              <span>
                {' '}
                You can request{' '}
                {product.product && product.product._id
                  ? 'product changes'
                  : 'a new product to be added to Bottomless'}{' '}
                here and will be sent to the Bottomless team for approval. We will notify you once accepted.
              </span>
            </Alert>
            <ProductForm
              product={assignedProduct}
              productOptions={productOptions}
              onSubmit={requestProductChange}
              onSuccess={onSuccess}
            />
          </>
        )}
      </>
    </Dashboard>
  );
};

ProductDetailsPageComponent.propTypes = {
  getProduct: PropTypes.func.isRequired,
  getProductOptions: PropTypes.func.isRequired,
  requestProductChange: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const ProductDetailsPage = withMetaTags({
  title: 'Bottomless.com: Vendor Portal',
})(
  connect(null, (dispatch, { match: { params: { id } } }) => ({
    getProduct: () => dispatch(getVendorProposedProductAction(id)),
    requestProductChange: data => dispatch(requestProductChangeAction(id, data)),
    getProductOptions: () => dispatch(getVendorProductOptionsAction()),
    addToast: message => dispatch(addToastAction(message)),
  }))(ProductDetailsPageComponent)
);
