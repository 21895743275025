import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FulfillmentCol } from './FulfillmentCol';
import { Card, Row } from 'reactstrap';

const FulfillmentTimesComponent = ({ fulfillmentTimes, isLoading, fulfillmentTimelines }) => {
  if (isLoading || !fulfillmentTimes || !fulfillmentTimelines) {
    return null;
  }

  return (
    <div>
      <div className="mb-2 text-center font-weight-bold text-uppercase">Current fulfillment times</div>
      <Card className="box flex-row">
        <Row className="p-3">
          {['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day, i) => (
            <>{fulfillmentTimelines[day] && <FulfillmentCol day={day} curFulfillmentTime={fulfillmentTimes[i]} />}</>
          ))}
        </Row>
      </Card>
    </div>
  );
};

FulfillmentTimesComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fulfillmentTimes: PropTypes.object.isRequired,
  fulfillmentTimelines: PropTypes.object.isRequired,
};

export const FulfillmentTimes = connect(
  ({ vendorPortal }) => ({
    fulfillmentTimes: vendorPortal.vendor.fulfillment_times,
    isLoading: vendorPortal.isLoading,
    fulfillmentTimelines: vendorPortal.vendor.fulfillment_timelines,
  }),
  null
)(FulfillmentTimesComponent);
