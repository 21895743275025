import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DateFormat } from '@bottomless/common/components';
import { Nl2Br } from '../../Nl2Br/Nl2Br';

const ActivityComponent = ({ activity, isComment }) => {
  return (
    <div
      className={classNames('', {
        'comment-container': isComment,
        'py-3': !isComment,
        'activity-container': !isComment,
      })}
    >
      <div className="timeline-node">
        <div className="circle"></div>
        <div className="line"></div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="flex-grow-1 mr-3">{activity.content && <Nl2Br>{activity.content}</Nl2Br>}</div>
        <div className="small text-secondary text-nowrap">
          <DateFormat
            date={activity.created_at}
            format="h:mm a"
            toTimezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
          />
        </div>
      </div>
    </div>
  );
};

ActivityComponent.propTypes = {
  activity: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    content: PropTypes.string,
    user: PropTypes.string,
    order: PropTypes.string,
    type: PropTypes.string,
    author: PropTypes.shape({
      local: PropTypes.shape({
        email: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  isComment: PropTypes.bool,
};

export const Activity = memo(ActivityComponent);
