import PropTypes from 'prop-types';
import React from 'react';
import { Edit } from 'react-feather';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { DateFormat, Image } from '@bottomless/common/components';

export const Product = ({ product, back }) => {
  return (
    <Card className="mb-3 flex-row align-items-center admin-product-card">
      <div className="card-media">
        {product.small_image_src && (
          <Image src={product.small_image_src} alt={product.small_image_src} maxWidth="120" maxHeight="120" />
        )}
        {!product.small_image_src && product.image_src && (
          <Image src={product.image_src} alt={product.image_src} maxWidth="120" maxHeight="120" />
        )}
      </div>
      <CardBody>
        <div>
          <span className="text-secondary text-sm d-block">#{product._id}</span>
        </div>
        <div className="d-flex justify-content-end">
          <div className="mr-auto">
            <span className="text-secondary text-sm d-block">{product.vendor_name}</span>
            <span>{product.name}</span>
          </div>
          <div className="ml-3">
            {product.date_added && (
              <span className="text-sm d-block">
                Added: <DateFormat withTime date={product.date_added} />
              </span>
            )}
            {product.updated_at && (
              <span className="text-sm d-block">
                Updated: <DateFormat withTime date={product.updated_at} />
              </span>
            )}
          </div>
          <div className="ml-4">
            <Link
              to={`/vendor/dashboard/products-v2/${product._id}${back ? '?back=' + back : ''}`}
              className="text-info"
            >
              <Edit size="20" />
            </Link>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    image_src: PropTypes.string,
    small_image_src: PropTypes.string,
    status: PropTypes.string,
    active: PropTypes.bool,
    hidden: PropTypes.bool,
    date_added: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
  back: PropTypes.string,
};
