/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { RefreshCw } from 'react-feather';
import { Button } from 'reactstrap';
import { useCallback } from 'react';
import { downloadCSV } from '@bottomless/common/src/utils/csv';
import { Tooltip } from '@bottomless/common/components';

const BaseUrl = 'https://charts.mongodb.com/charts-bottomless-jbvvz';
const DashboardId = 'a336c5dc-c023-4311-899c-c24779934625';

export const MongoChart = ({ getUserToken }) => {
  const sdk = new ChartsEmbedSDK({ baseUrl: BaseUrl });
  const dashboardDiv = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const [isDashboardRendered, setDashboardRendered] = useState(false);
  const [selectedChart, selectChart] = useState();
  const [isDownloading, setDownloading] = useState();

  const [dashboard] = useState(
    sdk.createDashboard({
      dashboardId: DashboardId,
      height: 1000,
      getUserToken,
      showAttribution: false,
      autoRefresh: false,
      widthMode: 'fixed',
      background: 'transparent',
    })
  );

  useEffect(() => {
    dashboard
      .render(dashboardDiv.current)
      .then(() => {
        setLoading(false);
        setDashboardRendered(true);
      })
      .catch(err => console.log('Error during Charts rendering.', err));
  }, [dashboard]);

  useEffect(() => {
    if (isDashboardRendered) {
      const interval = setInterval(async () => {
        const charts = await dashboard.getAllCharts();
        if (charts.length) {
          charts.forEach(chart => {
            chart.addEventListener('click', data => {
              selectChart({
                id: data.chartId,
                title: data.chartTitle,
              });
            });
          });
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDashboardRendered]);

  const handleRefresh = useCallback(async () => {
    setLoading(true);
    try {
      await dashboard.refresh();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [dashboard]);

  const handleDownload = useCallback(async () => {
    setDownloading(true);
    const chart = await dashboard.getChart(selectedChart.id);
    const data = await chart.getData();
    downloadCSV(data.documents, `${selectedChart.title}.csv`, data.fields);
    setDownloading(false);
  }, [dashboard, selectedChart]);

  return (
    <div className="mb-5">
      <div className="d-flex justify-content-end mb-3">
        <Tooltip content="Click on chart to select data to download">
          <Button className="mr-2" color="success" onClick={handleDownload} disabled={!selectedChart || isDownloading}>
            <span>{selectedChart ? `Download ${selectedChart.title}'s data` : 'Download data'}</span>
          </Button>
        </Tooltip>
        <Button onClick={handleRefresh} disabled={isLoading}>
          <RefreshCw className={isLoading && 'rotate'} />
        </Button>
      </div>
      <div className="chart" ref={dashboardDiv} />
    </div>
  );
};

MongoChart.propTypes = {
  filter: PropTypes.object.isRequired,
  getUserToken: PropTypes.func.isRequired,
};
