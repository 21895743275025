import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Checkbox } from '@bottomless/common/components';
import { Alert } from 'reactstrap';

const Schema = Yup.object().shape({
  path: Yup.string(),
  withShort: Yup.bool(),
});

export const MagicLinkForm = ({ onSubmit, onSubmitSuccess }) => (
  <Form
    initialValues={{
      path: '/profile',
      withShort: false,
      forceBottomless: false,
    }}
    validationSchema={Schema}
    onSubmit={onSubmit}
    onSuccess={onSubmitSuccess}
  >
    {({ isSubmitting }) => (
      <>
        <Field name="path" label="Path" />
        <Checkbox name="withShort" label="Short link" />

        <Alert color="danger" className="mt-3 small">
          <strong>Warning:</strong> this link will log you into a user&apos;s account. Please only use this link in a
          fresh incognito window, that you close after use.
        </Alert>

        <div className="mt-3">
          <SubmitButton color="dark" isSubmitting={isSubmitting}>
            Create
          </SubmitButton>
        </div>
      </>
    )}
  </Form>
);

MagicLinkForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  showForceBottomless: PropTypes.bool,
};
