import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { Order } from './Order';
import { Box, BoxCollapsible } from '../../../../components/Box';
import { DateFormat } from '@bottomless/common/components';

const sortOrder = {
  delivered: 1,
  out_for_delivery: 2,
  in_transit: 3,
  accepted: 4,
  pre_transit: 5,
  unknown: 6,
};

const BatchComponent = ({ batch, onUpdate, onSyncTracking, orderNotes, onCreateNote }) => {
  const orders = useMemo(
    () =>
      batch.orders.sort((a, b) => (sortOrder[get(a, 'usps.status', 5)] > sortOrder[get(b, 'usps.status', 5)] ? 1 : -1)),
    [batch]
  );

  return (
    <>
      <Box>
        <div>
          <div>{batch.date ? <DateFormat date={batch.date} withTime /> : 'Pending'}</div>
          <div className="small text-secondary">
            {batch.date && (
              <>
                {batch.orders.length} in transit, {batch.total - batch.orders.length} delivered
              </>
            )}
            {!batch.date && <>{batch.orders.length} pending</>}
          </div>
        </div>

        <BoxCollapsible>
          {orders.map(order => (
            <Order
              key={order._id}
              order={{ ...order, notes: orderNotes ? orderNotes[order._id] : undefined }}
              onUpdate={onUpdate}
              onSyncTracking={onSyncTracking}
              onCreateNote={onCreateNote}
            />
          ))}
        </BoxCollapsible>
      </Box>
    </>
  );
};

BatchComponent.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onSyncTracking: PropTypes.func.isRequired,
  batch: PropTypes.shape({
    _id: PropTypes.string,
    total: PropTypes.number.isRequired,
    orders: PropTypes.array.isRequired,
    date: PropTypes.string,
  }).isRequired,
  onBatchSelect: PropTypes.func,
  orderNotes: PropTypes.object,
  onCreateNote: PropTypes.func,
};

export const Batch = memo(BatchComponent);
