import * as qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetVendorUnscannedOrdersTypes = createAction('Vendor Portal', 'Get unscanned orders');

export const GetVendorUnscannedOrdersAction = params => ({
  [RSAA]: {
    endpoint: `/vendor-portal/orders/unscanned${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [
      GetVendorUnscannedOrdersTypes.REQUEST,
      GetVendorUnscannedOrdersTypes.SUCCESS,
      GetVendorUnscannedOrdersTypes.FAILURE,
    ],
  },
});

export const GetVendorUnprocessedOrdersTypes = createAction('Vendor Portal', 'Get unprocessed orders');

export const getVendorUnprocessedOrdersAction = params => ({
  [RSAA]: {
    endpoint: `/vendor-portal/orders/unprocessed${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [
      GetVendorUnprocessedOrdersTypes.REQUEST,
      GetVendorUnprocessedOrdersTypes.SUCCESS,
      GetVendorUnprocessedOrdersTypes.FAILURE,
    ],
  },
});

export const GetVendorTypes = createAction('Vendor Portal', 'Get vendor');

export const getVendorAction = () => ({
  [RSAA]: {
    endpoint: `/vendor-portal`,
    method: 'GET',
    types: [GetVendorTypes.REQUEST, GetVendorTypes.SUCCESS, GetVendorTypes.FAILURE],
  },
});

export const CreateVendorOrderShipmentTypes = createAction('Vendor Portal', 'Create order shipment');

export const createVendorOrderShipmentAction = id => ({
  [RSAA]: {
    endpoint: `/vendor-portal/orders/${id}/shipment`,
    method: 'POST',
    types: [
      CreateVendorOrderShipmentTypes.REQUEST,
      CreateVendorOrderShipmentTypes.SUCCESS,
      CreateVendorOrderShipmentTypes.FAILURE,
    ],
  },
});

export const InitiateBatchShipmentTypes = createAction('Vendor Portal', 'Initiate batch shipment');

export const initiateBatchShipmentAction = () => ({
  [RSAA]: {
    endpoint: `/vendor-portal/orders/initiate-shipment`,
    method: 'POST',
    types: [InitiateBatchShipmentTypes.REQUEST, InitiateBatchShipmentTypes.SUCCESS, InitiateBatchShipmentTypes.FAILURE],
  },
});

export const GetBatchShipmentType = createAction('Vendor Portal', 'Get batch shipment');

export const getBatchShipmentAction = id => ({
  [RSAA]: {
    endpoint: `/vendor-portal/orders/batch-shipment/${id}`,
    method: 'GET',
    types: [GetBatchShipmentType.REQUEST, GetBatchShipmentType.SUCCESS, GetBatchShipmentType.FAILURE],
  },
});

export const GetHistoryTypes = createAction('Vendor Portal', 'Get orders history');

export const getHistoryAction = () => ({
  [RSAA]: {
    endpoint: `/vendor-portal/orders/history`,
    method: 'GET',
    types: [GetHistoryTypes.REQUEST, GetHistoryTypes.SUCCESS, GetHistoryTypes.FAILURE],
  },
});

export const GetVendorProposedProductsType = createAction('Vendor Portal', 'Get proposed products');

export const getVendorProposedProductsAction = () => ({
  [RSAA]: {
    endpoint: `/vendor-portal/products/proposed`,
    method: 'GET',
    types: [
      GetVendorProposedProductsType.REQUEST,
      GetVendorProposedProductsType.SUCCESS,
      GetVendorProposedProductsType.FAILURE,
    ],
  },
});

export const GetVendorProposedProductType = createAction('Vendor Portal', 'Get proposed product');

export const getVendorProposedProductAction = id => ({
  [RSAA]: {
    endpoint: `/vendor-portal/products/proposed/${id}`,
    method: 'GET',
    types: [
      GetVendorProposedProductType.REQUEST,
      GetVendorProposedProductType.SUCCESS,
      GetVendorProposedProductType.FAILURE,
    ],
  },
});

export const GetVendorProductOptionsType = createAction('Vendor Portal', 'Get product options');

export const getVendorProductOptionsAction = () => ({
  [RSAA]: {
    endpoint: `/vendor-portal/products/options`,
    method: 'GET',
    types: [
      GetVendorProductOptionsType.REQUEST,
      GetVendorProductOptionsType.SUCCESS,
      GetVendorProductOptionsType.FAILURE,
    ],
  },
});

export const RequestProductChangeType = createAction('Vendor Portal', 'Request product change');

export const requestProductChangeAction = (id, data) => ({
  [RSAA]: {
    endpoint: `/vendor-portal/products/proposed/${id}/change-set`,
    method: 'POST',
    body: data,
    types: [RequestProductChangeType.REQUEST, RequestProductChangeType.SUCCESS, RequestProductChangeType.FAILURE],
  },
});

export const GetStripeLinkType = createAction('Vendor Portal', 'Get stripe link');

export const getStripeLinkAction = () => ({
  [RSAA]: {
    endpoint: `/vendor-portal/accounting/stripe-link`,
    method: 'GET',
    types: [GetStripeLinkType.REQUEST, GetStripeLinkType.SUCCESS, GetStripeLinkType.FAILURE],
  },
});

export const GetBatchShipmentsTypes = createAction('Vendor Portal', 'Get all');

export const getBatchShipmentsAction = () => ({
  [RSAA]: {
    endpoint: `/vendor-portal/batch-shipments`,
    method: 'GET',
    types: [GetBatchShipmentsTypes.REQUEST, GetBatchShipmentsTypes.SUCCESS, GetBatchShipmentsTypes.FAILURE],
  },
});

export const GenerateReceiptTypes = createAction('Vendor Portal', 'Generate receipt');

export const generateReceiptAction = data => ({
  [RSAA]: {
    endpoint: `/vendor-portal/batch-shipments/generate-receipt`,
    method: 'POST',
    body: data,
    types: [GenerateReceiptTypes.REQUEST, GenerateReceiptTypes.SUCCESS, GenerateReceiptTypes.FAILURE],
  },
});

export const UpdateOnHandInventoryTypes = createAction('Vendor Portal', 'Generate receipt');

export const updateOnHandInventoryAction = data => ({
  [RSAA]: {
    endpoint: `/vendor-portal/packaging`,
    method: 'PATCH',
    body: data,
    types: [UpdateOnHandInventoryTypes.REQUEST, UpdateOnHandInventoryTypes.SUCCESS, UpdateOnHandInventoryTypes.FAILURE],
  },
});

export const GetVendorShipmentProviderTypes = createAction('Vendor Portal', 'Get vendor shipment provider');

export const getVendorShipmentProviderAction = () => ({
  [RSAA]: {
    endpoint: `/vendor-portal/shipment-provider`,
    method: 'GET',
    types: [
      GetVendorShipmentProviderTypes.REQUEST,
      GetVendorShipmentProviderTypes.SUCCESS,
      GetVendorShipmentProviderTypes.FAILURE,
    ],
  },
});

export const FulfillOrderInShopifyTypes = createAction('Vendor Portal', 'Fulfill orders in shopify');

export const fulfillOrderInShopifyAction = data => ({
  [RSAA]: {
    endpoint: `/vendor-portal/orders/shopify/fulfill`,
    method: 'POST',
    body: data,
    types: [FulfillOrderInShopifyTypes.REQUEST, FulfillOrderInShopifyTypes.SUCCESS, FulfillOrderInShopifyTypes.FAILURE],
  },
});

export const CreateAnalyticsTokenTypes = createAction('Vendor Portal', 'Create analytics token');

export const createAnalyticsTokenAction = data => ({
  [RSAA]: {
    endpoint: `/vendor-portal/analytics`,
    method: 'POST',
    body: data,
    types: [CreateAnalyticsTokenTypes.REQUEST, CreateAnalyticsTokenTypes.SUCCESS, CreateAnalyticsTokenTypes.FAILURE],
  },
});
