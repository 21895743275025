import { GetUsersTypes } from './user.actions';

const initialState = {
  data: {
    docs: [],
    total: 0,
    limit: 100,
    pages: 0,
    page: 0,
  },
  isLoading: false,
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetUsersTypes.REQUEST:
      return { ...initialState, isLoading: true };

    case GetUsersTypes.SUCCESS:
      return {
        data: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
