/* global hj */
import * as Sentry from '@sentry/browser';
import React, { Component, Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Loader } from '@bottomless/common/components';
import { browserMatches } from '@bottomless/common/utils';
import { LogoutTypes } from '@bottomless/common/store';
import { FullStoryWrapper } from './components/FullStory/FullStory';
import { FallbackRouter } from './router/FallbackRouter';
import { VendorOnboardingPage } from './pages/VendorPortal/Onboarding/Onboarding';
import { HistoryPage } from './pages/VendorPortal/Orders/History';
import { UnprocessedOrdersPage } from './pages/VendorPortal/Orders/UnprocessedOrders';
import { UnscannedOrdersPage } from './pages/VendorPortal/Orders/UnscannedOrders';
import { ProductsPage } from './pages/VendorPortal/Products/Products';
import { ProductDetailsPage } from './pages/VendorPortal/Products/ProductDetails';
import { RefreshPage } from './pages/VendorPortal/Stripe/Refresh';
import { PayoutHistoryPage } from './pages/VendorPortal/Payout/PayoutHistory';
import { AnalyticsPage } from './pages/VendorPortal/Analytics/Analytics';
import { Panel } from './layouts/Panel/Panel';
import { UsersPage } from './pages/VendorPortal/Users/';
import { UserDetailsPage } from './pages/VendorPortal/UserDetails/UserDetails';
import { ProductsPageV2 } from './pages/VendorPortal/ProductsV2/Products';
import { ProductDetailV2Page } from './pages/VendorPortal/ProductDetailV2/ProductDetailV2';
import { OrderTrackingPage } from './pages/VendorPortal/OrderTracking/OrderTracking';
import { ScaleShipmentPage } from './pages/VendorPortal/ScaleShipments/ScaleShipments';
import { ProductFeedbackPage } from './pages/VendorPortal/ProductFeedback/ProductFeedback';
import { createStore } from './store';
import { GiftsPage } from './pages/VendorPortal/GiftPurchases/GiftPurchases';
import { GiftPurchaseDetailsPage } from './pages/VendorPortal/GiftPurchases/GiftPurchasesDetails';

export class App extends Component {
  constructor(props) {
    super(props);

    const { store, persistor } = createStore();
    this.store = store;
    this.persistor = persistor;
  }

  componentDidCatch(error, errorInfo) {
    const errorAlreadyExists = window.location.href.match(/error/);
    const isChunkLoadError = error.name === 'ChunkLoadError';
    const isFirstChunkError = isChunkLoadError && !errorAlreadyExists;

    if (process.env.NODE_ENV === 'production' && !isFirstChunkError) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);

        if ('hj' in window && hj.globals) scope.setExtra('hotjar_user_id', hj.globals.get('userId'));

        Sentry.captureException(error);
      });

      if ('hj' in window) {
        window.hj('tagRecording', ['Error']);
      }
    }

    if (!errorAlreadyExists) {
      if (!isChunkLoadError) {
        this.store.dispatch({ type: LogoutTypes.SUCCESS });
      }

      const sufix = window.location.href.match(/\?/) ? '&error' : '?error';
      window.location.replace(`${window.location.href}${sufix}`);
    } else if (!browserMatches()) {
      window.location.replace(`/update-browser.html`);
    }
  }

  render() {
    const { store, persistor } = this;

    return (
      <CookiesProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<Loader />}>
              <Router>
                <FullStoryWrapper>
                  <Panel>
                    <Switch>
                      <Redirect from="/" exact to="/vendor/dashboard" />
                      <Route path="/vendor/dashboard/onboarding" exact component={VendorOnboardingPage} />
                      <Route path="/vendor/dashboard/history" exact component={HistoryPage} />
                      <Redirect from="/vendor/dashboard" exact to="/vendor/dashboard/unprocessed" />
                      <Route path="/vendor/dashboard/unprocessed" exact component={UnprocessedOrdersPage} />
                      <Route path="/vendor/dashboard/unscanned" exact component={UnscannedOrdersPage} />
                      <Route path="/vendor/dashboard/analytics" exact component={AnalyticsPage} />
                      <Route path="/vendor/dashboard/products-feedback" exact component={ProductFeedbackPage} />
                      <Route path="/vendor/dashboard/products" exact component={ProductsPage} />
                      <Route path="/vendor/dashboard/products/:id" exact component={ProductDetailsPage} />
                      <Route path="/vendor/dashboard/stripe/refresh" exact component={RefreshPage} />
                      <Redirect from="/vendor/dashboard/stripe" to="/vendor/dashboard/payout" />
                      <Route path="/vendor/dashboard/payout" exact component={PayoutHistoryPage} />
                      <Route path="/vendor/dashboard/users" exact component={UsersPage} />
                      <Route path="/vendor/dashboard/users/:id" exact component={UserDetailsPage} />
                      <Route path="/vendor/dashboard/products-v2" exact component={ProductsPageV2} />
                      <Route path="/vendor/dashboard/products-v2/:id" exact component={ProductDetailV2Page} />
                      <Route path="/vendor/dashboard/tracking/:status" exact component={OrderTrackingPage} />
                      <Route path="/vendor/dashboard/scale-shipments" exact component={ScaleShipmentPage} />
                      <Route path="/vendor/dashboard/gifts" exact component={GiftsPage} />
                      <Route path="/vendor/dashboard/gifts/:id" exact component={GiftPurchaseDetailsPage} />
                      <Route path="/:slug" component={FallbackRouter} />
                    </Switch>
                  </Panel>
                </FullStoryWrapper>
              </Router>
            </Suspense>
          </PersistGate>
        </Provider>
      </CookiesProvider>
    );
  }
}
