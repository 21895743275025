import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { SubmitButton, Form, Field, DateFormat } from '@bottomless/common/components';
import { OrderSources, OrderStatuses } from '@bottomless/common/constants';
import { utcDate } from '@bottomless/common/utils';
import { useLastOrderDate } from '../../hooks';
import { READABLE_FORMAT } from '../../../../../utils/dates';

const Schema = Yup.object().shape({
  override_fulfillment_date: Yup.date().required('This field is required'),
});

export const SkipOrderForm = ({ orders, addToast, toggleModal, user, vendor, skipOrder }) => {
  const onSuccess = useCallback(() => {
    addToast(`Order has been skipped`);
    toggleModal();
  }, [addToast, toggleModal]);

  const onSubmit = useCallback(data => skipOrder(user._id, data), [skipOrder, user]);

  const lastOrder = useMemo(
    () =>
      orders.find(
        order =>
          order.date_arrived &&
          ![OrderStatuses.Cancelled, OrderStatuses.Offboarded].includes(order.status) &&
          ![OrderSources.REPLACED, OrderSources.LOST].includes(order.source)
      ),
    [orders]
  );

  const upcomingOrders = useMemo(
    () =>
      orders
        .filter(
          order =>
            !order.date_arrived &&
            ![OrderStatuses.Cancelled, OrderStatuses.Offboarded].includes(order.status) &&
            ![OrderSources.REPLACED, OrderSources.LOST].includes(order.source)
        )
        .map(order => ({ order })),
    [orders]
  );

  const { getNextOrderDate } = useLastOrderDate({
    user,
    lastOrder: { data: lastOrder },
    upcomingOrders: { data: { orders: upcomingOrders, vendor } },
  });

  const fulfillmentDate = utcDate(getNextOrderDate(user.dumb_period, false, 2, user));

  return (
    <>
      <Form
        initialValues={{ override_fulfillment_date: fulfillmentDate }}
        validationSchema={Schema}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
      >
        {({ isSubmitting, values }) => (
          <>
            <Field type="hidden" name="override_fulfillment_date" />
            <div className="d-flex justify-content-between mb-4">
              <div className="font-weight-bolder order-heading">New reorder date</div>
              <div>
                <DateFormat date={values.override_fulfillment_date} format={READABLE_FORMAT} />
              </div>
            </div>
            <div className="mb-3 text-center">
              <SubmitButton color="dark" isSubmitting={isSubmitting}>
                Skip order
              </SubmitButton>
            </div>
          </>
        )}
      </Form>
    </>
  );
};

SkipOrderForm.propTypes = {
  skipOrder: PropTypes.func.isRequired,
  orders: PropTypes.array,
  user: PropTypes.object,
  vendor: PropTypes.object,
  addToast: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
