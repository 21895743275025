import PropTypes from 'prop-types';
import React from 'react';
import { Loader } from 'react-feather';
import { Col, Row } from 'reactstrap';
import { DateFormat } from '@bottomless/common/components';
import { isWholeNumber } from '@bottomless/common/utils';

const ScaleConnected = status => (
  <>
    <div className="d-flex align-items-center">
      {(status.scale_last_connected || status.scale_last_weight) && <i className="scale-circle connected mr-2" />}
      <span className="text-capitalize">{status.scale_status}</span>
      {status.scale_last_connected && (
        <span className="ml-2 connected-date">
          as of <DateFormat date={status.scale_last_connected} toSeattle />
        </span>
      )}
    </div>
    {status.scale_last_weight && (
      <div className="small text-secondary last-weight">
        Last weight: {parseFloat(status.scale_last_weight).toFixed(2)}oz
      </div>
    )}
  </>
);

const ScaleDisconnected = status => (
  <>
    <div className="d-flex align-items-center flex-wrap">
      {(status.scale_last_connected || status.scale_last_weight) && <i className="scale-circle disconnected mr-2" />}
      <span className="text-capitalize mr-2">{status.scale_status}</span>
      {status.scale_last_connected && (
        <span>
          since <DateFormat date={status.scale_last_connected} toSeattle />
        </span>
      )}
    </div>
    {isWholeNumber(status.scale_last_weight) && (
      <div className="small text-secondary">Last weight: {parseFloat(status.scale_last_weight).toFixed(2)}oz</div>
    )}
  </>
);

const ScalePending = () => (
  <div className="d-flex align-items-center">
    <i className="scale__circle" />
    <span className="mr-1">
      connected
      <span>
        <Loader size="16" className="spin ml-2" />
      </span>
    </span>
    <span>Your data is refreshing, it can take several hours</span>
  </div>
);

const ScaleSetupPending = status => (
  <>
    <div className="d-flex align-items-center">
      <span className="text-capitalize">{status.scale_status}</span>
    </div>
  </>
);

export const ScaleStatus = ({ status }) => {
  return (
    <section className={`scale__status scale__status--${status.scale_status}`}>
      <Row>
        <Col xs="12">
          {status.scale_status === 'pending' && ScalePending(status)}
          {status.scale_status === 'connected' && ScaleConnected(status)}
          {status.scale_status === 'disconnected' && ScaleDisconnected(status)}
          {status.scale_status === 'setup pending' && ScaleSetupPending(status)}
        </Col>
      </Row>
    </section>
  );
};

ScaleStatus.propTypes = {
  status: PropTypes.shape({
    scale_status: PropTypes.string.isRequired,
    scale_last_connected: PropTypes.string,
    scale_last_weight: PropTypes.number,
    scale_battery_level: PropTypes.number,
    lastReplacement: PropTypes.object,
  }).isRequired,
};
