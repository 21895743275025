import React, { memo } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const inventorySchema = Yup.object().shape({
  packagingCount: Yup.object()
    .shape({
      '12': Yup.number()
        .min(0)
        .integer(),
      '32': Yup.number()
        .min(0)
        .integer(),
    })
    .required('This field is required'),
});

const UpdateOnHandInventory = ({ toggle, isOpen, vendor, updateOnHandInventory, onUpdateOnHandInventorySuccess }) => {
  const { packagingCount } = vendor || {};

  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>On-hand inventory</ModalHeader>
      <ModalBody>
        <Form
          initialValues={{ packagingCount: {} }}
          onSubmit={updateOnHandInventory}
          onSuccess={onUpdateOnHandInventorySuccess}
          validationSchema={inventorySchema}
        >
          {({ isSubmitting }) => (
            <>
              <Row>
                <p className="pl-3">Please confirm your on-hand inventory of:</p>
                {Object.keys(packagingCount).map((size, i) =>
                  packagingCount[size] ? (
                    <Col xs="6" key={i}>
                      <Field name={`packagingCount[${size}]`} type="number" label={`${size} oz mailers`} />
                    </Col>
                  ) : (
                    ''
                  )
                )}
              </Row>
              <div className="d-flex justify-content-end">
                <SubmitButton color="dark" isSubmitting={isSubmitting}>
                  Save
                </SubmitButton>
              </div>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

UpdateOnHandInventory.propTypes = {
  vendor: PropTypes.shape({
    _id: PropTypes.string,
    packagingCount: PropTypes.object.isRequired,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  updateOnHandInventory: PropTypes.func.isRequired,
  onUpdateOnHandInventorySuccess: PropTypes.func.isRequired,
};

export const UpdateOnHandInventoryModal = memo(UpdateOnHandInventory);
