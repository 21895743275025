import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Check, X } from 'react-feather';
import { connect } from 'react-redux';
import { Button, Col, Row, Table } from 'reactstrap';
import { DataLoading, Price } from '@bottomless/common/components';
import { useConditionalDataEffect, useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import {
  applyProposedProductChangesAction,
  getVendorProposedProductAction,
  createProductFromProposedAction,
  getProductAction,
  updateProductAction,
  getTaxCategoriesAction,
} from '../../../store';
import { CreateProductForm } from './components/CreateProductForm';
import { UpdateForm } from './components/UpdateForm';

const ProductDetailV2PageComponent = ({
  getProposedProduct,
  getProduct,
  createProduct,
  updateProduct,
  applyChanges,
  addToast,
  match,
  getTaxCategories,
  vendor,
}) => {
  const [product, setProduct] = useState(null);
  const [shopProduct, setShopProduct] = useState(null);

  const { id } = match.params;

  useDataEffect(getProposedProduct, setProduct, id, null);
  const { data: taxCategories } = useDataEffect(getTaxCategories, undefined, undefined, null);

  useConditionalDataEffect(product && product.product, getProduct, setShopProduct, (product || {}).product?._id);

  const onUpdateSuccess = updated => {
    addToast('Product has been successfully saved');
    setShopProduct({ ...shopProduct, ...updated });
  };

  const onCreateNewProduct = async () => {
    const { payload } = await createProduct(id);
    setProduct(payload);
    addToast('New shop product has been successfully created');
  };

  const onApplyChanges = async () => {
    const { payload } = await applyChanges(product._id);
    setProduct({ ...product, ...payload, change_set: undefined });
    addToast('Changes have been successfully applied');
  };

  return (
    <Dashboard title={product?.name}>
      <DataLoading count={product ? 1 : 0} isLoading={Boolean(product)} />
      {product && taxCategories && (
        <>
          <Row>
            <Col xs="12" sm="4">
              <p>{product.description}</p>
              <h6>Variants:</h6>
              <Table responsive className="small">
                <thead>
                  <tr>
                    <th>Variant</th>
                    <th>Attributes</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {product.variants?.map((variant, i) => (
                    <tr key={i}>
                      <td>
                        <div>{variant.original_id}</div>
                        <div className="font-weight-bold">
                          <Price value={variant.price} />
                        </div>
                      </td>
                      <td>
                        {variant.option1 && <div>{variant.option1}</div>}
                        {variant.option2 && <div>{variant.option2}</div>}
                        {variant.option3 && <div>{variant.option3}</div>}
                      </td>
                      <td className={`text-right text-${variant.available ? 'success' : 'danger'}`}>
                        {variant.available ? <Check size="20" /> : <X size="20" />}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {product.original_image && (
                <a href={product.original_image} target="_blank" rel="noopener noreferrer">
                  <img src={product.original_image} alt="Missing product image" className="img-fluid" />
                </a>
              )}
              <div className="mt-2 text-center">
                <a href={product.reference_url} title="See vendor's page" target="_blank" rel="noopener noreferrer">
                  Reference url
                </a>
              </div>
              {product.change_set && (
                <>
                  <div className="mt-4 mb-2 d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Changes:</h5>
                    <Button color="success" outline onClick={onApplyChanges} size="sm" className="mr-3">
                      Apply changes
                    </Button>
                  </div>
                  <pre id="json">{JSON.stringify(product.change_set, undefined, 2)}</pre>
                </>
              )}
            </Col>
            <Col xs="12" sm="8">
              <CreateProductForm product={product} onCreateNewProduct={onCreateNewProduct} />

              {shopProduct && (
                <UpdateForm
                  onSubmit={data => updateProduct(shopProduct._id, data)}
                  onSuccess={onUpdateSuccess}
                  product={shopProduct}
                  proposedProduct={product}
                  vendor={vendor}
                  taxCategories={taxCategories}
                  addToast={addToast}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </Dashboard>
  );
};

ProductDetailV2PageComponent.propTypes = {
  addToast: PropTypes.func.isRequired,
  getProposedProduct: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
  mapToShop: PropTypes.func.isRequired,
  createProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  applyChanges: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  getCategories: PropTypes.func.isRequired,
  getTaxCategories: PropTypes.func.isRequired,
  vendor: PropTypes.object,
};

export const ProductDetailV2Page = connect(
  ({ vendorPortal }) => ({
    vendor: vendorPortal.vendor,
  }),
  dispatch => ({
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    getProposedProduct: id => dispatch(getVendorProposedProductAction(id)),
    getProduct: id => dispatch(getProductAction(id)),
    createProduct: id => dispatch(createProductFromProposedAction(id)),
    updateProduct: (id, data) => dispatch(updateProductAction(id, data)),
    applyChanges: id => dispatch(applyProposedProductChangesAction(id)),
    getTaxCategories: () => dispatch(getTaxCategoriesAction()),
  })
)(ProductDetailV2PageComponent);
