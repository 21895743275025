import { get, has } from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as momentTimezone from 'moment-timezone';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Link } from 'react-router-dom';
import { Badge, Col, Collapse, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {
  Address,
  CopyToClipboard,
  DateFormat,
  Price,
  VariantPrice,
  VariantAttributes,
} from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { getTrackingLink } from '@bottomless/common/utils';
import {
  OrderSources,
  OrderStatuses,
  SubscriptionType,
  UnfulfilledOrderStatuses,
} from '@bottomless/common/src/constants';
import { ReplaceOrderForm } from '../../../../components/Order';
import { StaticTracking } from '../../../../components/UspsTracking/StaticTracking';
import { Box, BoxCollapsible } from '../../../../components/Box';
import { useOrderActions } from '../hooks/use-order-actions.hook';
import { SkipOrderForm } from './forms/SkipOrderForm';
import { EditFulfillmentDateForm } from './EditFulfillmentDateForm';
import { EditOrderForm } from './EditOrderForm';
import { ProcessPaymentForm } from './forms/ProcessPaymentForm';
import { CancelOrderForm } from './forms/CancelOrderForm';
import { LineItems } from '../components/LineItems';
import { OrderStatus } from './OrderStatus';

const MODAL_EDIT = 'EDIT';
const MODAL_REPLACE = 'REPLACE';
const MODAL_PROCESS = 'PROCESS';
const MODAL_CANCEL = 'CANCEL';
const MODAL_SKIP = 'SKIP';
const MODAL_EDIT_FULFILLMENT_DATE = 'MODAL_EDIT_FULFILLMENT_DATE';

const ProductVariant = ({ productVariantSchema, user, grind, hidePrice, dynamicPricing, isRotating, quantity }) => {
  const variant = has(productVariantSchema, 'product.variants')
    ? productVariantSchema.product.variants.find(v => v._id === productVariantSchema.variant)
    : null;

  const dynamicPrice = dynamicPricing?.price;

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div>
        {productVariantSchema?.product?._id && !isRotating ? (
          <Link to={`/vendor/dashboard/products/${productVariantSchema?.product?._id}`} target="_blank">
            {get(productVariantSchema, 'product.name', '---')}{' '}
            {get(productVariantSchema, 'product.name') && quantity && <span>(x{quantity})</span>}
          </Link>
        ) : (
          <div>
            {get(productVariantSchema, 'product.name', '---')}{' '}
            {get(productVariantSchema, 'product.name') && quantity && <span>(x{quantity})</span>}
          </div>
        )}
        <div>
          <div className="text-secondary">
            <VariantAttributes productVariant={productVariantSchema} grind={grind} />
          </div>
        </div>
      </div>
      {variant && !hidePrice && (
        <div>{dynamicPrice ? <Price value={dynamicPrice} /> : <VariantPrice user={user} variant={variant} />}</div>
      )}
    </div>
  );
};

ProductVariant.propTypes = {
  productVariantSchema: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    product: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      vendor_name: PropTypes.string.isRequired,
      variants: PropTypes.array.isRequired,
    }).isRequired,
  }),
  user: PropTypes.object,
  hidePrice: PropTypes.bool,
  dynamicPricing: PropTypes.shape({
    price: PropTypes.number.isRequired,
  }),
  isRotating: PropTypes.bool,
  quantity: PropTypes.number,
  grind: PropTypes.string,
};

export const Order = ({
  order: rawOrder,
  user,
  products,
  onUpdate,
  onReplaceOrder,
  options,
  hideFooter,
  vendor,
  addToast,
  processOrder,
  cancelOrder,
  skipOrder,
  orders,
}) => {
  const tomorrow = momentTimezone().add(1, 'days'); // TODO: UPDATE!

  const [isCollapsed, collapse] = useToggle(true);
  const [order, setOrder] = useState(rawOrder);

  const product = get(order, 'product_id');

  const [isOpen, setOpen] = useState(false);
  const [modalType, setType] = useState(null);

  const isFulfilledOrder = order && order.status === 'fulfilled';
  const address = isFulfilledOrder ? order.address : undefined;

  const userProductShouldHaveGrind = useMemo(
    () => user.product.product?.category?.manifest?.attributes?.includes('grind'),
    [user]
  );

  const isPickup = useMemo(() => (address ? address.pickup : user.verifiedAddress.pickup), [address, user]);

  const toggle = useCallback(
    type => {
      setType(type);
      setOpen(!isOpen);
    },
    [setType, setOpen, isOpen]
  );

  useEffect(() => {
    setOrder(rawOrder);
  }, [rawOrder]);

  const shopifyOrderUrl = useMemo(() => {
    if (!vendor.scraper?.url || !order.external_id) {
      return;
    }

    let baseUrl = vendor.scraper?.url;
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.slice(0, -1);
    }

    return `${baseUrl}/admin/orders/${order.external_id.split('/').pop()}`;
  }, [order, vendor]);

  const orderActions = useOrderActions({
    order,
    orders,
    user,
    cancelAction: () => toggle(MODAL_CANCEL),
    skipAction: () => toggle(MODAL_SKIP),
  });

  const isGiftSubscription = useMemo(() => user.subscriptionType === SubscriptionType.Prepaid, [user]);

  const menuItems = useMemo(() => {
    if (hideFooter) {
      return undefined;
    }

    return [
      ...(!['initiated'].includes(order.status)
        ? [
            { label: 'Edit', action: () => toggle(MODAL_EDIT) },
            { label: 'Replace order', action: () => toggle(MODAL_REPLACE) },
          ]
        : []),
      ...(UnfulfilledOrderStatuses.includes(order.status)
        ? [{ label: 'Edit Fulfillment Date', action: () => toggle(MODAL_EDIT_FULFILLMENT_DATE) }]
        : []),
      ...orderActions,
      ...(['initiated', 'subproduct_generated'].includes(order.status) &&
      (!order.product_id?.product?.rotating || !!order.subproduct_id?.product)
        ? [
            {
              label: isGiftSubscription ? 'Process Order' : 'Process payment',
              action: () => toggle(MODAL_PROCESS),
            },
          ]
        : []),
    ];
  }, [hideFooter, order, toggle, orderActions, isGiftSubscription]);

  const forceOpen = useMemo(
    () =>
      !order.date_arrived &&
      ![OrderStatuses.Cancelled, OrderStatuses.Replaced, OrderStatuses.Refunded].includes(order.status),
    [order]
  );

  const fulfillmentError = useMemo(() => {
    if (![OrderStatuses.Initiated, OrderStatuses.SubproductGenerated].includes(order.status)) {
      return null;
    }

    const fields = ['vendor', 'shipment', 'shopify', 'charge', 'subproduct'];

    return fields.map(field => get(order, ['fulfillmentErrors', field, 'error'])).find(Boolean);
  }, [order]);

  return (
    <div className="mb-4">
      <Box menuItems={menuItems} initialOpen={forceOpen}>
        <div className="d-flex justify-content-between align-items-start">
          <div className="d-flex align-items-center justify-content-between">
            {!order.date_fulfilled && (
              <div>
                Order created{' '}
                <span className="font-weight-semi-bold">
                  <DateFormat date={order.date_initiated || order.created_at} />
                </span>{' '}
                to be processed on{' '}
                <span className="font-weight-semi-bold">
                  <DateFormat date={order.override_fulfillment_date || tomorrow} />
                </span>
              </div>
            )}
            {order.date_fulfilled && !order.date_arrived && (
              <div>
                Order Number:{' '}
                {order.shopify_name && (
                  <>
                    <a href={shopifyOrderUrl} rel="noopener noreferrer" target="_blank" className="text-primary">
                      {order.shopify_name}
                    </a>
                  </>
                )}{' '}
                <span>(Bottomless #{order._id})</span>
              </div>
            )}
            {order.date_fulfilled && order.date_arrived && (
              <div>
                Order created{' '}
                <span className="font-weight-semi-bold">
                  <DateFormat date={order.date_initiated || order.created_at} />
                </span>{' '}
                - {order.subproduct_id.product.name}
                {order.shipping_status && <span className="ml-4">Status: {order.shipping_status}</span>}
              </div>
            )}
          </div>
          <div>
            {order.source === OrderSources.USER_ONE_OFF && (
              <Badge pill color="dark" className="text-capitalize mr-2">
                One-off
              </Badge>
            )}
            <OrderStatus order={order} fulfillmentError={fulfillmentError} />
          </div>
        </div>
        <BoxCollapsible>
          <Row>
            <Col md="5" className="border-right">
              <div className="pr-2">
                {order.replacing && (
                  <div className="text-success mb-4">
                    <div className="mb-2">Replacement of #{order.replacing}</div>
                  </div>
                )}
                {order.replaced_by && (
                  <div className="text-danger mb-4">
                    <div className="mb-2">Replaced by #{order.replaced_by}</div>
                  </div>
                )}
                {fulfillmentError && (
                  <div className="text-danger mb-4">
                    <div className="mb-2 font-weight-semi-bold">Processing error</div>
                    <div>{fulfillmentError}</div>
                  </div>
                )}
                <div>
                  <div className="mb-2 font-weight-semi-bold">Product</div>
                  {!order.subproduct_id && (
                    <>
                      <ProductVariant
                        productVariantSchema={order.product_id}
                        user={user}
                        grind={order.grind?.name}
                        dynamicPricing={order.dynamicPricing}
                        isRotating={order.product_id?.product?.rotating}
                      />
                      <LineItems user={user} />
                    </>
                  )}
                  {order.subproduct_id && (
                    <>
                      <ProductVariant
                        productVariantSchema={order.subproduct_id}
                        user={user}
                        grind={order.grind?.name}
                        hidePrice={order.product_id?.product?.rotating}
                        quantity={order.quantity ? order.quantity : undefined}
                      />
                      <LineItems user={{ ...order, product: order.subproduct_id }} />
                    </>
                  )}
                </div>
                {order.amount_paid !== undefined && (
                  <div className="mt-4">
                    <div className="font-weight-semi-bold mb-2">Payment</div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Subtotal:</span>
                      <Price value={order.gross_price || 0} cents />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Shipping:</span>
                      <Price value={order.shipment_paid || 0} cents />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Tax: </span>
                      <Price value={order.tax_paid || 0} cents />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Discount:</span>
                      <Price value={order.discount_amount || 0} cents />
                    </div>
                    {order.discountCode && (
                      <div className="mt-1 d-flex align-items-center justify-content-between">
                        <div>Discount Code:</div>
                        <Badge color="primary">{order.discountCode.title}</Badge>
                      </div>
                    )}
                    <hr />
                    <div className="d-flex align-items-center justify-content-between font-weight-semi-bold">
                      <span>Total:</span>
                      <Price
                        value={(order.amount_paid || 0) + (order.tax_paid || 0) + (order.shipment_paid || 0)}
                        cents
                      />
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col md="4" className="border-right">
              <div className="px-2">
                {user.shopifyCustomerId && order.external_id && (
                  <div className="mb-4">
                    <div className="mb-2 font-weight-semi-bold">Order number</div>
                    <div>
                      {order.shopify_name}
                      <span className="view-in-shopify ml-2">
                        <a
                          href={shopifyOrderUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="small text-primary"
                        >
                          View in Shopify
                        </a>
                      </span>
                    </div>
                  </div>
                )}
                <div className="mb-2 font-weight-semi-bold">
                  {order.status === OrderStatuses.Fulfilled ? 'Shipped to' : 'Shipping address'}
                </div>
                <div>
                  {user.first_name} {user.last_name}
                </div>
                <Address address={order.address || user.verifiedAddress} />
                {order.tracking_number && (
                  <div className="mt-4">
                    <div className="font-weight-semi-bold mb-2">Tracking</div>
                    <div>
                      <a
                        href={getTrackingLink(order.shipping_service, order.tracking_number, order.tracking_url)}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="text-primary"
                      >
                        {order.tracking_number}
                      </a>
                    </div>
                    {order.shipping_status && (
                      <div>
                        Status: <span className="text-capitalized">{order.shipping_status}</span>
                      </div>
                    )}
                    {order.usps_tracking && (
                      <Row>
                        <Col>
                          <div onClick={collapse} className="cursor-pointer">
                            <span className="mr-1">Tracking Details</span>
                            {isCollapsed ? <ChevronDown size="15" /> : <ChevronUp size="15" />}
                          </div>
                          <Collapse isOpen={!isCollapsed}>
                            <StaticTracking usps_tracking={order.usps_tracking} />
                          </Collapse>
                        </Col>
                      </Row>
                    )}
                  </div>
                )}
                {isPickup && <div>Pickup at {address?.title || user.verifiedAddress.title}</div>}
              </div>
            </Col>
            <Col md="3">
              <div className="pl-2">
                <div className="font-weight-semi-bold mb-2">Timeline</div>
                {order.date_arrived && (
                  <div className="d-flex align-items-center justify-content-between">
                    <span>Arrived</span>
                    <span className="text-secondary small">
                      <DateFormat date={order.date_arrived} />
                    </span>
                  </div>
                )}
                {order.date_fulfilled && (
                  <div className="d-flex align-items-center justify-content-between">
                    <span>Processed</span>
                    <span className="text-secondary small">
                      <DateFormat date={order.date_fulfilled} />
                    </span>
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-between">
                  <span>Initiated</span>
                  <span className="text-secondary small">
                    <DateFormat date={order.date_initiated || order.created_at} />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </BoxCollapsible>
      </Box>

      {isOpen && products && product && (
        <>
          <Modal isOpen={isOpen} toggle={toggle} size={[MODAL_REPLACE, MODAL_EDIT].includes(modalType) ? 'lg' : 'sm'}>
            {modalType === MODAL_REPLACE && (
              <>
                <ModalHeader toggle={() => toggle(MODAL_REPLACE)}>
                  <div>Order to be replaced</div>
                  <div className="text-sm text-secondary">
                    {order._id} <CopyToClipboard text={order._id} />
                  </div>
                </ModalHeader>
                <ModalBody>
                  <ReplaceOrderForm
                    products={products}
                    order={order}
                    user={user}
                    onSubmit={data => onReplaceOrder(order._id, data)}
                    onSubmitSuccess={() => toggle()}
                  />
                </ModalBody>
              </>
            )}

            {modalType === MODAL_EDIT && (
              <>
                <ModalHeader toggle={toggle}>Edit Order</ModalHeader>
                <ModalBody>
                  <EditOrderForm
                    onSubmit={data => onUpdate(order._id, data)}
                    order={order}
                    withGrind={userProductShouldHaveGrind}
                    options={options}
                    onSubmitSuccess={() => toggle()}
                  />
                </ModalBody>
              </>
            )}

            {modalType === MODAL_EDIT_FULFILLMENT_DATE && (
              <>
                <ModalHeader toggle={toggle}>Edit Fulfillment Date</ModalHeader>
                <ModalBody>
                  <EditFulfillmentDateForm
                    onSubmit={data => onUpdate(order._id, data)}
                    order={order}
                    onSubmitSuccess={() => toggle()}
                  />
                </ModalBody>
              </>
            )}

            {modalType === MODAL_PROCESS && (
              <>
                <ModalHeader toggle={toggle}>{isGiftSubscription ? 'Process Order' : 'Process Payment'}</ModalHeader>
                <ModalBody>
                  <ProcessPaymentForm
                    processOrder={processOrder}
                    order={order}
                    toggleModal={toggle}
                    addToast={addToast}
                    setOrder={setOrder}
                    isGiftSubscription={isGiftSubscription}
                  />
                </ModalBody>
              </>
            )}

            {modalType === MODAL_CANCEL && (
              <>
                <ModalHeader toggle={toggle}>Cancel Order</ModalHeader>
                <ModalBody>
                  <CancelOrderForm
                    cancelOrder={cancelOrder}
                    order={order}
                    toggleModal={toggle}
                    addToast={addToast}
                    setOrder={setOrder}
                  />
                </ModalBody>
              </>
            )}

            {modalType === MODAL_SKIP && (
              <>
                <ModalHeader toggle={toggle}>Skip Order</ModalHeader>
                <ModalBody>
                  <SkipOrderForm
                    skipOrder={skipOrder}
                    user={user}
                    order={order}
                    orders={orders}
                    toggleModal={toggle}
                    addToast={addToast}
                  />
                </ModalBody>
              </>
            )}
          </Modal>
        </>
      )}
    </div>
  );
};

Order.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onReplaceOrder: PropTypes.func.isRequired,
  products: PropTypes.array,
  options: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    external_id: PropTypes.string.isRequired,
    shopify_name: PropTypes.string.isRequired,
    status: PropTypes.string,
    easypost_id: PropTypes.string,
    shipping_status: PropTypes.string,
    source: PropTypes.string,
    date_arrived: PropTypes.string,
    usps_tracking: PropTypes.object,
    label_url: PropTypes.string,
    shipping_label: PropTypes.shape({
      label_url: PropTypes.string,
    }),
    amount_paid: PropTypes.number,
    tax_paid: PropTypes.number,
    free_bag: PropTypes.bool,
    updated_at: PropTypes.string,
    tracking_number: PropTypes.string,
    tracking_url: PropTypes.string,
    grind: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    user_id: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      verifiedAddress: PropTypes.object.isRequired,
    }).isRequired,
    product_id: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
      }).isRequired,
    }),
    product: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
      }).isRequired,
    }),
    ordered_product_id: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    subproduct_id: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
      }).isRequired,
    }),
    tracking_updates: PropTypes.array,
    subvendor_id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    address: PropTypes.object,
    date_initiated: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    override_fulfillment_date: PropTypes.string,
    date_fulfilled: PropTypes.string,
    shipping_service: PropTypes.string,
    predictions: PropTypes.array,
    need_support: PropTypes.bool,
    charge_subproduct: PropTypes.bool,
    quantity: PropTypes.number,
  }).isRequired,
  hideFooter: PropTypes.bool,
  vendor: PropTypes.object,
  addToast: PropTypes.func.isRequired,
  processOrder: PropTypes.func,
  cancelOrder: PropTypes.func,
  skipOrder: PropTypes.func,
  orders: PropTypes.func,
};

Order.defaultProps = {
  products: [],
  hideFooter: false,
};

const FulifillmentDate = ({ dateFulfilled }) => {
  if (!dateFulfilled) {
    return '---';
  }

  const diff = moment.duration(moment().diff(moment(dateFulfilled))).humanize();

  return (
    <>
      <DateFormat date={dateFulfilled} /> ({diff} ago)
    </>
  );
};

FulifillmentDate.propTypes = {
  dateFulfilled: PropTypes.string,
};

const VendorFulfillmentTime = ({ subvendor, dateFulfilled }) => {
  if (!subvendor || !subvendor.fulfillment_times || !dateFulfilled) {
    return '---';
  }

  const date = new Date(dateFulfilled);
  const fulfillDay = subvendor.fulfillment_times[date.getDay()];

  return `${fulfillDay.perc10} / ${fulfillDay.median} / ${fulfillDay.perc90}`;
};

VendorFulfillmentTime.propTypes = {
  subvendor: PropTypes.shape({
    fulfillment_times: PropTypes.object,
  }),
};
