import * as qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetUsersTypes = createAction('Vendor users', 'Get users');

export const getUsersAction = params => ({
  [RSAA]: {
    endpoint: `vendor-portal/users${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetUsersTypes.REQUEST, GetUsersTypes.SUCCESS, GetUsersTypes.FAILURE],
  },
});

export const GetUserChargesTypes = createAction('Vendor Users', 'Get User Charges');

export const getUserChargesAction = id => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/charges`,
    method: 'GET',
    types: [GetUserChargesTypes.REQUEST, GetUserChargesTypes.SUCCESS, GetUserChargesTypes.FAILURE],
  },
});

export const RefundOrderChargesTypes = createAction('Vendor Users', 'Refund User Order Charges');

export const refundOrderChargesAction = (userId, orderId, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${userId}/orders/${orderId}/refund`,
    method: 'POST',
    body: data,
    types: [RefundOrderChargesTypes.REQUEST, RefundOrderChargesTypes.SUCCESS, RefundOrderChargesTypes.FAILURE],
  },
});

export const CreateMagicLinkTypes = createAction('Vendor users', 'Create magic link');

export const createMagicLinkAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/magic-link`,
    method: 'POST',
    body: data,
    types: [CreateMagicLinkTypes.REQUEST, CreateMagicLinkTypes.SUCCESS, CreateMagicLinkTypes.FAILURE],
  },
});

export const PauseUserAccountTypes = createAction('Vendor Users', 'Pause user account');

export const updateAccountStatusAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/status`,
    method: 'PATCH',
    body: data,
    types: [PauseUserAccountTypes.REQUEST, PauseUserAccountTypes.SUCCESS, PauseUserAccountTypes.FAILURE],
  },
});

export const UpdateAddressTypes = createAction('Vendor Users', 'Update Address');

export const updateAddressAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/update-address`,
    method: 'POST',
    body: data,
    types: [UpdateAddressTypes.REQUEST, UpdateAddressTypes.SUCCESS, UpdateAddressTypes.FAILURE],
  },
});

export const UpdateEmailTypes = createAction('Vendor Users', 'Update Email');

export const updateEmailAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/update-email`,
    method: 'POST',
    body: data,
    types: [UpdateEmailTypes.REQUEST, UpdateEmailTypes.SUCCESS, UpdateEmailTypes.FAILURE],
  },
});

export const GetShopifyUserPaymentMethodsTypes = createAction('Vendor Users', 'Get Shopify payment methods');

export const getShopifyUserPaymentMethodsAction = email => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/by-email/${email}/shopify-payment-methods`,
    method: 'GET',
    types: [
      GetShopifyUserPaymentMethodsTypes.REQUEST,
      GetShopifyUserPaymentMethodsTypes.SUCCESS,
      GetShopifyUserPaymentMethodsTypes.FAILURE,
    ],
  },
});

export const MergeShopifyAccountsTypes = createAction('Vendor Users', 'Merge Shopify Accounts');

export const mergeShopifyAccountsAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/merge-shopify-accounts`,
    method: 'POST',
    body: data,
    types: [MergeShopifyAccountsTypes.REQUEST, MergeShopifyAccountsTypes.SUCCESS, MergeShopifyAccountsTypes.FAILURE],
  },
});

export const UpdatePhoneTypes = createAction('Vendor Users', 'Update Phone');

export const updatePhoneAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/update-phone`,
    method: 'POST',
    body: data,
    types: [UpdatePhoneTypes.REQUEST, UpdatePhoneTypes.SUCCESS, UpdatePhoneTypes.FAILURE],
  },
});

export const UpdateUserDumbPeriodTypes = createAction('Vendor Users', 'User Dumb Period Updated');

export const updateUserDumbPeriodAction = (id, data, place) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/update-dumb-period`,
    method: 'PATCH',
    body: data,
    ...(place ? { headers: { 'X-SWITCH-TO-SBU': place } } : {}),
    types: [UpdateUserDumbPeriodTypes.REQUEST, UpdateUserDumbPeriodTypes.SUCCESS, UpdateUserDumbPeriodTypes.FAILURE],
  },
});

export const GetPaymentUpdateLinkTypes = createAction('Vendor Users', 'Get payment update link');

export const sendPaymentUpdateLinkAction = id => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/payment-update-link`,
    method: 'POST',
    types: [GetPaymentUpdateLinkTypes.REQUEST, GetPaymentUpdateLinkTypes.SUCCESS, GetPaymentUpdateLinkTypes.FAILURE],
  },
});

export const UpdateNameTypes = createAction('Vendor Users', 'Update Name');

export const updateNameAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/full-name`,
    method: 'PATCH',
    body: data,
    types: [UpdateNameTypes.REQUEST, UpdateNameTypes.SUCCESS, UpdateNameTypes.FAILURE],
  },
});

export const SkipOrderTypes = createAction('Vendor Users', 'Skip order');

export const skipOrderAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/skip-order`,
    method: 'PATCH',
    body: data,
    types: [UpdateNameTypes.REQUEST, UpdateNameTypes.SUCCESS, UpdateNameTypes.FAILURE],
  },
});

export const UpdateProductTypes = createAction('Vendor Users', 'Update product');

export const updateProductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/product`,
    method: 'PATCH',
    body: data,
    types: [UpdateProductTypes.REQUEST, UpdateProductTypes.SUCCESS, UpdateProductTypes.FAILURE],
  },
});

export const GetShippingTypes = createAction('Vendor Users', 'Get Shipping');

export const UpdateShippingTypes = createAction('Vendor Users', 'Update Phone');

export const updateShippingAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/shipping`,
    method: 'PATCH',
    body: data,
    types: [UpdateShippingTypes.REQUEST, UpdateShippingTypes.SUCCESS, UpdateShippingTypes.FAILURE],
  },
});

export const UpdateUserShopifyNoteTypes = createAction('Vendor Users', 'User Update Shopify Note');

export const updateUserShopifyNoteAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/users/${id}/shopify-note`,
    method: 'PATCH',
    body: data,
    types: [UpdateUserShopifyNoteTypes.REQUEST, UpdateUserShopifyNoteTypes.SUCCESS, UpdateUserShopifyNoteTypes.FAILURE],
  },
});
