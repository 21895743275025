import classNames from 'classnames';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { memo, useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { DateFormat, DateDiffFormat, Tracking, InfoTooltip } from '@bottomless/common/components';
import { OrderedProduct } from '../../../../components/Order';
import { CustomerNotes } from '../../../../components/CustomerNotes/CustomerNotes';
import { Box, BoxCollapsible } from '../../../../components/Box';
import { SimpleAddress } from '../../../../components/SimpleAddress';
import { DeliveredForm } from './DeliveredForm';
import { NewTracking } from './NewTracking';

const MODAL_DELIVERED = 'modal_delivered';

const OrderComponent = ({ order, onUpdate, onSyncTracking, onCreateNote }) => {
  const [isOpen, setOpen] = useState(false);
  const [modalType, setType] = useState(null);

  const user = order.user_id;
  const latestTracking = useMemo(
    () => [...(order.tracking_updates || [])].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)).shift(),
    [order]
  );

  const toggle = useCallback(
    type => {
      setType(type);
      setOpen(!isOpen);
    },
    [setType, setOpen, isOpen]
  );

  const menuItems = useMemo(
    () =>
      order.tracking_number && !['delivered'].includes(order.shipping_status)
        ? [
            { label: 'Mark as delivered', action: () => toggle(MODAL_DELIVERED) },
            {
              label: (
                <div className="d-flex align-items-center">
                  Sync tracking
                  <InfoTooltip className="ml-2" size="sm">
                    Download latest shipping info from shipping service.
                  </InfoTooltip>
                </div>
              ),
              action: () => onSyncTracking(order._id),
            },
          ]
        : [],
    [order, toggle, onSyncTracking]
  );

  return (
    <>
      <Box className="order-shipment" menuItems={menuItems} actionsUp>
        <Row>
          {user && (
            <Col>
              <div>
                <Link to={`/vendor/dashboard/users/${user._id}`} target="_blank">
                  {user.first_name} {user.last_name}
                </Link>
              </div>
              <div>
                <SimpleAddress address={user.verifiedAddress} />
              </div>

              <div className="mt-2">
                <OrderedProduct order={order} user={user} />
              </div>
            </Col>
          )}
          <Col>
            {order.notes && (
              <>
                <div>
                  Notification Sent:{' '}
                  <span className="text-secondary">{order.notes.filter(el => el.type === 'notification').length}</span>
                </div>
                <div>
                  Last Notification:{' '}
                  <span className="text-secondary">
                    <DateFormat date={order.notes.filter(el => el.type === 'notification')[0]?.created_at} withTime />
                  </span>
                </div>
              </>
            )}
          </Col>
          <Col>
            {order.tracking_number && (
              <div>
                <p className="mb-0">
                  <span>Est. </span>
                  {latestTracking && <DateDiffFormat date={latestTracking.est_delivery_date} withColor fromZeroed />}
                </p>
              </div>
            )}
            <div>
              Shipped: <DateDiffFormat date={order.date_shipped} withColor />
            </div>
            {order.tracking_number && (
              <div>
                Tracking:{' '}
                <Tracking
                  number={order.tracking_number}
                  label=""
                  shippingService={order.shipping_service}
                  trackingUrl={order.tracking_url}
                />
              </div>
            )}
            {order.usps_tracking && (
              <div className="usps-status">
                New USPS status:{' '}
                <Badge
                  color={classNames({
                    danger: ['return_to_sender', 'alert'].includes(order.usps_tracking.status),
                    success: order.usps_tracking.status === 'delivered',
                    primary: order.usps_tracking.status === 'in_transit',
                    warning: order.usps_tracking.status === 'pre_transit',
                    info: order.usps_tracking.status === 'accepted',
                    secondary: order.usps_tracking.status === 'unknown',
                  })}
                >
                  {get(order, 'usps_tracking.status', '---')}
                </Badge>
              </div>
            )}
            {order.tracking_status && (
              <div>
                Tracking Status:{' '}
                <Badge
                  color={
                    ('success',
                    classNames({
                      danger: [
                        'pre_transit_2',
                        'accepted_2',
                        'in_transit_exceptions',
                        'out_for_delivery_2',
                        'alert_1',
                      ].includes(order.tracking_status),
                    }))
                  }
                >
                  {order.tracking_status}
                </Badge>
              </div>
            )}
          </Col>
        </Row>
        <BoxCollapsible>
          <NewTracking order={order} />
          <Row className="pt-3">
            <Col>
              <CustomerNotes order={order} onCreate={onCreateNote} />
            </Col>
          </Row>
        </BoxCollapsible>
      </Box>

      {isOpen && (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
          {modalType === MODAL_DELIVERED && (
            <>
              <ModalHeader toggle={toggle}>Mark as delivered</ModalHeader>
              <ModalBody>
                <DeliveredForm
                  onSubmit={data => {
                    onUpdate(order._id, data);
                    toggle();
                  }}
                  order={order}
                />
              </ModalBody>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

OrderComponent.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onSyncTracking: PropTypes.func.isRequired,
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    need_support: PropTypes.bool,
    source: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    shipping_status: PropTypes.string.isRequired,
    shipping_service: PropTypes.string.isRequired,
    tracking_url: PropTypes.string,
    user_id: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      scale_last_connected: PropTypes.string,
      scale_last_weight: PropTypes.number,
      verifiedAddress: PropTypes.object.isRequired,
      local: PropTypes.shape({
        email: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    product_id: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.string.isRequired,
    }).isRequired,
    subproduct_id: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
      }).isRequired,
    }),
    tracking_updates: PropTypes.array,
    tracking_status: PropTypes.string,
    last_tracking_update: PropTypes.string,
    tracking_number: PropTypes.string,
    subvendor_id: PropTypes.object,
    address: PropTypes.object,
    date_initiated: PropTypes.string.isRequired,
    override_fulfillment_date: PropTypes.string,
    date_fulfilled: PropTypes.string,
    date_shipped: PropTypes.string,
    predictions: PropTypes.array,
    notes: PropTypes.array,
    usps_tracking: PropTypes.shape({
      status: PropTypes.string,
      summary: PropTypes.array,
    }),
  }).isRequired,
  onCreateNote: PropTypes.func,
};

export const Order = memo(OrderComponent);
