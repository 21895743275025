import PropTypes from 'prop-types';
import React from 'react';
import { Field, OneLineAddress } from '@bottomless/common/components';

export const RecipientInformation = ({
  setFieldValue,
  values,
  errors,
  shouldShipScaleToRecipient,
  shouldGiftWrapScale,
  userId,
  address,
  isGiftRedeemed,
}) => (
  <div className="mb-3 mt-4">
    <div className="d-flex align-items-end justify-content-between">
      <div className="d-flex mb-3 align-items-center">
        <h4>Recipient information</h4>
      </div>
    </div>

    <div>
      <div className="recipient-name">
        <Field name="recipient.first_name" label="First Name" disabled={isGiftRedeemed} />
        <Field name="recipient.last_name" label="Last Name" disabled={isGiftRedeemed} />
      </div>
      <Field name="recipient.email" type="email" label="E-mail Address" disabled={isGiftRedeemed} />
      <Field name="recipient.date" type="date" label="Gift date" min={new Date()} disabled={isGiftRedeemed} />
      <Field name="recipient.phone" type="phone" label="Phone (Optional)" disabled={isGiftRedeemed} />
      {(address && address.current) ||
      (shouldShipScaleToRecipient && (shouldGiftWrapScale || (userId && userId._id))) ? (
        <OneLineAddress {...{ setFieldValue, values, errors }} fieldPrefix="recipient." disabled={isGiftRedeemed} />
      ) : (
        ''
      )}
      <Field
        name="recipient.info"
        type="textarea"
        component="textarea"
        rows="1"
        label="Leave a note for the recipient"
        disabled={isGiftRedeemed}
      />
    </div>
  </div>
);

RecipientInformation.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object,
  shouldShipScaleToRecipient: PropTypes.bool,
  shouldGiftWrapScale: PropTypes.bool,
  userId: PropTypes.object,
  address: PropTypes.object,
  isGiftRedeemed: PropTypes.bool,
};

RecipientInformation.defaultProps = {
  shouldShipScaleToRecipient: true,
  userId: null,
  address: null,
};
