import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { addToastAction } from '@bottomless/common/store';
import { getShipmentsTrackingAction, setShipmentToArrivedAction } from '../../../store';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import { Filters } from './components/Filters';
import { Batch } from './components/Batch';

const ScaleShipmentComponent = ({
  getShipmentsTracking,
  setShipmentToArrived,
  isLoading,
  location,
  batches,
  addToast,
}) => {
  const query = useMemo(() => qs.parse(location.search), [location.search]);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    getShipmentsTracking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getShipmentsTracking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const changeStatusToDelivered = async shipment => {
    await setShipmentToArrived(shipment._id);
    addToast('Scale Shipment status set to arrived', 'success');
  };

  useEffect(() => {
    if (batches) {
      setHasData(batches.some(batch => batch.shipments.length > 0));
    }
  }, [batches]);

  return (
    <>
      <Dashboard title="Scale shipments" className="page-scale-shipments">
        <div className="mb-4">
          <Filters isLoading={isLoading} hasData={batches && batches.length > 0} />
        </div>

        {!isLoading && batches && (
          <>
            {hasData && (
              <>
                {batches.map(
                  batch =>
                    batch.shipments.length > 0 && (
                      <div key={batch._id} className="mb-3">
                        <Batch batch={batch} changeStatusToDelivered={changeStatusToDelivered} />
                      </div>
                    )
                )}
              </>
            )}
          </>
        )}
      </Dashboard>
    </>
  );
};

ScaleShipmentComponent.propTypes = {
  getShipmentsTracking: PropTypes.func.isRequired,
  setShipmentToArrived: PropTypes.func.isRequired,
  batches: PropTypes.array,
  isLoading: PropTypes.bool,
  addToast: PropTypes.func.isRequired,
  acknowledgeScaleShipment: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};

export const ScaleShipmentPage = connect(
  ({ scaleShipment }) => ({
    batches: scaleShipment.batches,
    isLoading: scaleShipment.isLoading,
  }),
  (dispatch, { location: { search }, match: { params } }) => ({
    addToast: message => dispatch(addToastAction(message)),
    getShipmentsTracking: () => dispatch(getShipmentsTrackingAction({ ...params, ...qs.parse(search) })),
    setShipmentToArrived: id => dispatch(setShipmentToArrivedAction(id)),
  })
)(ScaleShipmentComponent);
