import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Pagination } from '@bottomless/common/components';
import { Filters } from './components/Filters';
import { User } from './components/User';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import { getUsersAction } from '../../../store';
import './Users.scss';

const UsersPageComponent = ({ users, getUsers, isLoading, location: { search } }) => {
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Dashboard title="Users">
      <div className="mb-4">
        <Filters isLoading={isLoading} hasData={users && users.docs && users.docs.length > 0} />
      </div>

      {users && (
        <>
          <Row className="page-vendor-users">
            {users.docs.map(user => (
              <Col xs="12" key={user._id}>
                <User user={user} />
              </Col>
            ))}
          </Row>
          <Pagination collection={users} onPageChange={getUsers} />
        </>
      )}
    </Dashboard>
  );
};

UsersPageComponent.propTypes = {
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.shape({
    docs: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
  }),
  isLoading: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export const UsersPage = connect(
  ({ users }) => ({
    users: users.data,
    isLoading: users.isLoading,
  }),
  (dispatch, { location: { search } }) => ({
    getUsers: params => dispatch(getUsersAction({ ...params, ...qs.parse(search) })),
  })
)(UsersPageComponent);
