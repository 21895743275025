import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { DataLoading, Pagination } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import {
  getUserChargesAction,
  getProductsAction,
  getUserOrderGenAction,
  getOrdersAction,
  replaceOrderAction,
  updateOrderAction,
  getProductOptionsAction,
  getCustomerNotesAction,
  getTempUserByUserIdAction,
  createMagicLinkAction,
  updateEmailAction,
  updateAccountStatusAction,
  updateUserDumbPeriodAction,
  updateAddressAction,
  updatePhoneAction,
  sendPaymentUpdateLinkAction,
  triggerOrderAction,
  processOrderAction,
  cancelOrderAction,
  updateShippingAction,
  updateNameAction,
  skipOrderAction,
  updateProductAction,
  getShopifyUserPaymentMethodsAction,
  mergeShopifyAccountsAction,
  getPickupLocationsAction,
  getPendingOrderAction,
  updateUserShopifyNoteAction,
  getScaleShipmentTrackingForUserAction,
} from '../../../store';
import { User } from './components/User';
import { Order } from './components/Order';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import './UserDetails.scss';
import { TriggerOrder } from './components/TriggerOrder';
import { ShopifyNote } from './components/ShopifyNote';
import { CreateShopifyNote } from './components/CreateShopifyNote';

const UserDetailsPageComponent = ({
  getUser,
  triggerOrder,
  addToast,
  getOrders,
  match,
  location,
  history,
  updateOrder,
  getProducts,
  replaceOrder,
  getProductsOptions,
  getUserCharges,
  getTempUserByUserId,
  updateEmail,
  updatePhone,
  updateAddress,
  updateAccountStatus,
  updateDumbSubscription,
  sendPaymentUpdateLink,
  vendor,
  processOrder,
  cancelOrder,
  createMagicLink,
  updateShipping,
  updateName,
  skipOrder,
  updateProduct,
  getShopifyUserPaymentMethods,
  mergeShopifyAccounts,
  getPickupLocations,
  getPendingOrder,
  updateUserShopifyNote,
  getScaleShipmentTrackingForUser,
}) => {
  const query = qs.parse(location.search);
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState(null);
  const [order, setOrder] = useState(query.orderId);
  const [tempUser, setTempUser] = useState(null);
  const [products, setProducts] = useState(null);
  const [options, setOptions] = useState(null);

  const bottomRef = useRef();

  const { error: setUserError } = useDataEffect(getUser, setUser);
  useDataEffect(getProductsOptions, setOptions);
  useDataEffect(getOrders, setOrders, { user_id: match.params.id }, {}, [user]);
  useDataEffect(getTempUserByUserId, setTempUser, match.params.id, null);
  useDataEffect(
    getProducts,
    response => {
      setProducts(get(response, 'docs', []));
    },
    { status: 'active', sort: 'name', dir: 1 }
  );

  useEffect(() => {
    setOrder(query.orderId);
    if (query.orderId) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [query]);

  const callApi = async (fn, message) => {
    try {
      const { payload: order, error } = await fn;

      if (error) {
        return addToast(error);
      }

      updateOrders(order);

      return addToast(message || 'Updated');
    } catch (e) {
      addToast(e.message);
    }
  };

  const selectOrder = orderId => {
    history.push({ pathname: location.pathname, search: orderId ? qs.stringify({ orderId }) : undefined });
  };

  const onTrigger = async data => {
    const res = await triggerOrder(data);
    const { payload } = await getUser();
    setUser(payload);
    return res;
  };
  const onCreateNote = note => setUser({ ...user, notes: [note, ...user.notes] });

  const updateOrders = order => {
    const index = orders.docs.findIndex(o => o._id === order._id);
    setOrders({
      ...orders,
      docs: [...orders.docs.slice(0, index), order, ...orders.docs.slice(index + 1)],
    });
  };

  const onUpdate = async (id, data) => callApi(updateOrder(id, data));
  const onReplaceOrder = async (id, data) => {
    return callApi(replaceOrder(id, data), 'Order replaced');
  };

  const handleSkipOrder = useCallback(
    async (...data) => {
      const result = await skipOrder(...data);
      setUser({ ...user });
      return result;
    },
    [user, skipOrder]
  );

  if (setUserError) {
    return (
      <Dashboard className="page-ordergen page-ordergen-details">
        <div className="text-danger text-large">
          {setUserError?.response?.message || setUserError?.message || 'Something went wrong'}
        </div>
        <div>
          <Link to="/vendor/dashboard/users">All users</Link>
        </div>
      </Dashboard>
    );
  }

  return (
    <Dashboard back="/vendor/dashboard/users" className="page-ordergen page-ordergen-details">
      <DataLoading count={user ? 1 : 0} isLoading={user === null} />
      {user && (
        <User
          user={user}
          setUser={setUser}
          key={user._id}
          getUserCharges={getUserCharges}
          onCreateNote={onCreateNote}
          addToast={addToast}
          getOrders={getOrders}
          updateEmail={updateEmail}
          updatePhone={updatePhone}
          updateAddress={updateAddress}
          updateAccountStatus={updateAccountStatus}
          updateDumbSubscription={updateDumbSubscription}
          sendPaymentUpdateLink={sendPaymentUpdateLink}
          vendor={vendor}
          createMagicLink={createMagicLink}
          updateShipping={updateShipping}
          updateName={updateName}
          skipOrder={handleSkipOrder}
          orders={orders?.docs}
          products={products}
          options={options}
          updateProduct={updateProduct}
          getShopifyUserPaymentMethods={getShopifyUserPaymentMethods}
          mergeShopifyAccounts={mergeShopifyAccounts}
          getPickupLocations={getPickupLocations}
          getScaleShipmentTrackingForUser={getScaleShipmentTrackingForUser}
        />
      )}

      {user && orders && (
        <>
          <div className="d-flex align-items-center justify-content-between mb-3 mt-5">
            <h4 className="font-weight-bold mb-0">Orders</h4>
            <div className="d-flex flex-gap-1">
              {!user.shopifyNote && (
                <CreateShopifyNote
                  user={user}
                  updateUserShopifyNote={updateUserShopifyNote}
                  addToast={addToast}
                  onUpdate={setUser}
                />
              )}
              <TriggerOrder
                user={user}
                addToast={addToast}
                onTrigger={onTrigger}
                products={products}
                getPendingOrder={getPendingOrder}
              />
            </div>
          </div>
          {user.shopifyNote && (
            <ShopifyNote
              user={user}
              updateUserShopifyNote={updateUserShopifyNote}
              addToast={addToast}
              onUpdate={setUser}
            />
          )}
          {orders.docs.length > 0 && (
            <>
              {order && (
                <Button onClick={() => selectOrder()} size="sm" color="dark" className="mb-2">
                  Clear filter
                </Button>
              )}
              {orders.docs
                .filter(o => !order || (order && order === o._id))
                .map(order => (
                  <Order
                    order={order}
                    user={user}
                    key={order._id}
                    onUpdate={onUpdate}
                    products={products}
                    onReplaceOrder={onReplaceOrder}
                    vendor={vendor}
                    options={options}
                    addToast={addToast}
                    processOrder={processOrder}
                    cancelOrder={cancelOrder}
                    skipOrder={handleSkipOrder}
                    orders={orders?.docs}
                  />
                ))}
            </>
          )}
          {orders.page === orders.pages && !orders.isLoading && tempUser && (
            <Order
              order={{ ...tempUser, _id: '---', source: '*TEMP USER*' }}
              user={user}
              onUpdate={onUpdate}
              products={products}
              onReplaceOrder={onReplaceOrder}
              options={options}
              vendor={vendor}
              addToast={addToast}
              hideFooter
            />
          )}
          <div className="mt-2">
            <Pagination collection={orders} onPageChange={getOrders} setter={setOrders} />
          </div>
          {orders.docs.length === 0 && !tempUser && <>User does not have any orders.</>}
        </>
      )}
      <div ref={bottomRef} />
    </Dashboard>
  );
};

UserDetailsPageComponent.propTypes = {
  getUser: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  triggerOrder: PropTypes.func.isRequired,
  getOrders: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  replaceOrder: PropTypes.func.isRequired,
  getProductsOptions: PropTypes.func.isRequired,
  getCustomerNotes: PropTypes.func.isRequired,
  getUserCharges: PropTypes.func.isRequired,
  getTempUserByUserId: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  updatePhone: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  updateAccountStatus: PropTypes.func.isRequired,
  updateDumbSubscription: PropTypes.func.isRequired,
  sendPaymentUpdateLink: PropTypes.func.isRequired,
  vendor: PropTypes.object.isRequired,
  processOrder: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  createMagicLink: PropTypes.func.isRequired,
  updateShipping: PropTypes.func.isRequired,
  updateName: PropTypes.func.isRequired,
  skipOrder: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  getShopifyUserPaymentMethods: PropTypes.func.isRequired,
  mergeShopifyAccounts: PropTypes.func.isRequired,
  getPickupLocations: PropTypes.func.isRequired,
  getPendingOrder: PropTypes.func.isRequired,
  updateUserShopifyNote: PropTypes.func.isRequired,
  getScaleShipmentTrackingForUser: PropTypes.func.isRequired,
};

export const UserDetailsPage = connect(
  ({ vendorPortal }) => ({
    vendor: vendorPortal.vendor,
  }),
  (dispatch, { match, location: { search } }) => ({
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    getUser: () => dispatch(getUserOrderGenAction(match.params.id)),
    getUserCharges: id => dispatch(getUserChargesAction(id)),
    triggerOrder: data => dispatch(triggerOrderAction(data)),
    updateOrder: (id, data) => dispatch(updateOrderAction(id, data)),
    getOrders: data => dispatch(getOrdersAction({ ...data, ...qs.parse(search), user_id: match.params.id, limit: 50 })),
    getPendingOrder: id => dispatch(getPendingOrderAction(id)),
    getProducts: params => dispatch(getProductsAction(params)),
    replaceOrder: (id, data) => dispatch(replaceOrderAction(id, data)),
    getProductsOptions: () => dispatch(getProductOptionsAction()),
    getCustomerNotes: (id, data) => dispatch(getCustomerNotesAction(id, data)),
    getTempUserByUserId: id => dispatch(getTempUserByUserIdAction(id)),
    updateDumbSubscription: (id, data, place) => dispatch(updateUserDumbPeriodAction(id, data, place)),
    updatePhone: (id, data) => dispatch(updatePhoneAction(id, data)),
    updateAddress: (id, data) => dispatch(updateAddressAction(id, data)),
    updateEmail: (id, data) => dispatch(updateEmailAction(id, data)),
    updateAccountStatus: (id, data) => dispatch(updateAccountStatusAction(id, data)),
    sendPaymentUpdateLink: id => dispatch(sendPaymentUpdateLinkAction(id)),
    processOrder: id => dispatch(processOrderAction(id)),
    cancelOrder: id => dispatch(cancelOrderAction(id)),
    createMagicLink: (id, data) => dispatch(createMagicLinkAction(id, data)),
    updateShipping: (id, data) => dispatch(updateShippingAction(id, data)),
    updateName: (id, data) => dispatch(updateNameAction(id, data)),
    skipOrder: (id, data) => dispatch(skipOrderAction(id, data)),
    updateProduct: (id, data) => dispatch(updateProductAction(id, data)),
    getShopifyUserPaymentMethods: email => dispatch(getShopifyUserPaymentMethodsAction(email)),
    mergeShopifyAccounts: (id, data) => dispatch(mergeShopifyAccountsAction(id, data)),
    getPickupLocations: (id, data) => dispatch(getPickupLocationsAction(id, data)),
    updateUserShopifyNote: (id, data) => dispatch(updateUserShopifyNoteAction(id, data)),
    getScaleShipmentTrackingForUser: id => dispatch(getScaleShipmentTrackingForUserAction(id)),
  })
)(UserDetailsPageComponent);
