import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Radio, CopyToClipboard } from '@bottomless/common/components';
import { DumbType, SubscriptionType } from '@bottomless/common/constants';
import { Button } from 'reactstrap';

const Schema = Yup.object().shape({
  subscriptionType: Yup.string().required('This field is required'),
  dumb_type: Yup.mixed().when('subscriptionType', {
    is: SubscriptionType.Fixed,
    then: Yup.string().required('This field is required'),
    otherwise: Yup.mixed(),
  }),
  dumb_period: Yup.number().when('dumb_type', {
    is: val => val === DumbType.Days,
    then: Yup.number()
      .typeError('Only natural numbers(1,2,3...) are allowed')
      .integer('Only natural numbers(1,2,3...) are allowed')
      .min(1, 'Only natural numbers(1,2,3...) are allowed')
      .required('This field is required'),
    otherwise: Yup.number().notRequired(),
  }),
  dumb_day: Yup.number().when('dumb_type', {
    is: val => val === DumbType.SameDayMonthly,
    then: Yup.number()
      .typeError('Only natural numbers(1,2,3...) are allowed')
      .integer('Only natural numbers(1,2,3...) are allowed')
      .min(1, 'Day must be between 1-28')
      .max(28, 'Day must be between 1-28')
      .required('Day is required'),
    otherwise: Yup.number().notRequired(),
  }),
});

export const UpdateSubscriptionTypeForm = ({ user, onSubmit, onSuccess, onClose, createMagicLink }) => {
  const handleSubmit = useCallback(
    ({ subscriptionType, ...data }) => onSubmit({ ...data, toDel: subscriptionType === SubscriptionType.Dynamic }),
    [onSubmit]
  );

  const [magicLink, setMagicLink] = useState(false);
  const [magicLinkError, setMagicLinkError] = useState(null);
  const [isMagicLinkLoading, setMagicLinkLoading] = useState(false);

  const onMagicLinkClick = useCallback(async () => {
    try {
      setMagicLinkLoading(true);
      setMagicLink(null);
      setMagicLinkError(null);
      const result = await createMagicLink(user._id, {
        path: '/switch-to-subscription-by-usage',
        withShort: false,
        forceBottomless: false,
      });

      setMagicLinkLoading(false);

      if (result.error) {
        setMagicLinkError(result.payload.response.message);
      } else {
        setMagicLink(result.payload.url);
      }
    } catch (e) {
      setMagicLinkError(e.message);
      setMagicLinkLoading(false);
    }
  }, [user, createMagicLink]);

  if (user.lineItems?.length > 0) {
    return (
      <>
        <div className="text-secondary">Users with multiple products are not allowed to switch subscription type.</div>
        <Button color="dark" size="sm" className="mt-4" onClick={onClose}>
          Close
        </Button>
      </>
    );
  }

  if (user.subscriptionType === SubscriptionType.Prepaid) {
    return (
      <>
        <div className="text-secondary">Prepaid users are not allowed to switch subscription type.</div>
        <Button color="dark" size="sm" className="mt-4" onClick={onClose}>
          Close
        </Button>
      </>
    );
  }

  return (
    <>
      <Form
        initialValues={{
          subscriptionType: user.subscriptionType,
          dumb_type: user.dumb_type,
          dumb_period: user.dumb_period,
          dumb_day: user.dumb_day,
        }}
        validationSchema={Schema}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
      >
        {({ isSubmitting, values }) => (
          <>
            <div className="d-flex mb-3">
              <div className="mr-3">
                <Radio name="subscriptionType" type="radio" value={SubscriptionType.Dynamic} label="By Usage" />
              </div>
              <div>
                <Radio name="subscriptionType" type="radio" value={SubscriptionType.Fixed} label="Fixed" />
              </div>
            </div>
            {values.subscriptionType === SubscriptionType.Fixed && (
              <>
                <hr />
                <div className="d-flex mb-3">
                  <div className="mr-3">
                    <Radio name="dumb_type" type="radio" value={DumbType.Days} label="Days" />
                  </div>
                  <div>
                    <Radio name="dumb_type" type="radio" value={DumbType.SameDayMonthly} label="Same day monthly" />
                  </div>
                </div>
                {values.dumb_type === DumbType.Days && (
                  <Field name="dumb_period" label="Frequency [days]" type="field" />
                )}
                {values.dumb_type === DumbType.SameDayMonthly && (
                  <Field name="dumb_day" label="Day of Month" type="field" />
                )}
              </>
            )}
            <div className="d-flex justify-content-start">
              <SubmitButton size="sm" color="dark" isSubmitting={isSubmitting}>
                Save
              </SubmitButton>
            </div>
            {user.subscriptionType !== SubscriptionType.Dynamic &&
              values.subscriptionType === SubscriptionType.Dynamic && (
                <div>
                  <div className="text-secondary my-4">or</div>
                  <div className="mb-2">
                    You can also let customer switch themselves. Generate a magic link to the page, where they can read
                    more details about Subscription by Usage and switch.
                  </div>
                  <Button color="dark" size="sm" onClick={onMagicLinkClick}>
                    {isMagicLinkLoading ? 'Generating...' : 'Generate magic link'}
                  </Button>
                  {magicLinkError && <div className="text-danger mt-2">{magicLinkError}</div>}
                </div>
              )}
          </>
        )}
      </Form>
      {magicLink && (
        <div className="d-flex flex-column text-sm mb-4 mt-5">
          <div className="mb-2">
            <code>{magicLink}</code>
          </div>
          <div>
            <CopyToClipboard text={magicLink} />
          </div>
        </div>
      )}
    </>
  );
};

UpdateSubscriptionTypeForm.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    subscriptionType: PropTypes.string.isRequired,
    dumb_period: PropTypes.string.isRequired,
    lineItems: PropTypes.array,
    dumb_type: PropTypes.string,
    dumb_day: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  createMagicLink: PropTypes.func.isRequired,
};
