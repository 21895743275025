import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useConditionalDataEffect, useDataEffect } from '@bottomless/common/hooks';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { getHistoryAction, getVendorShipmentProviderAction } from '../../../store/vendor-portal';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import { Batch } from './components/Batch';

const HistoryPageComponent = ({ getHistory, getShipmentProvider }) => {
  const [history, setHistory] = useState(null);
  const [shipmentProvider, setShipmentProvider] = useState();

  useConditionalDataEffect(!history, getHistory, setHistory);
  useDataEffect(getShipmentProvider, setShipmentProvider);

  const isFedexVendor = shipmentProvider && shipmentProvider.name === 'FedEx';

  return (
    <Dashboard title="History">
      {history && history.map(batch => <Batch key={batch._id} batch={batch} isFedexVendor={isFedexVendor} />)}
    </Dashboard>
  );
};

HistoryPageComponent.propTypes = {
  getHistory: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  getShipmentProvider: PropTypes.func.isRequired,
};

export const HistoryPage = withMetaTags({
  title: 'Bottomless.com: Vendor Portal',
})(
  connect(null, dispatch => ({
    getHistory: () => dispatch(getHistoryAction()),
    getShipmentProvider: () => dispatch(getVendorShipmentProviderAction()),
  }))(HistoryPageComponent)
);
