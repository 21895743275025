import MetaTags from 'react-meta-tags';
import React from 'react';

export const withMetaTags = ({
  title = 'Bottomless.com',
  description = 'Bottomless is a subscription by usage where instead of getting shipments on a set schedule, you get shipments sent based on your usage.',
  image = 'https://www.bottomless.com/images/infinity-black.png',
}) => Component =>
  function MetaTagsWrapper(props) {
    const titleValue = typeof title === 'function' ? title(props) : title;
    const descriptionValue = typeof description === 'function' ? description(props) : description;
    const imageValue = typeof image === 'function' ? image(props) : image;

    return (
      <>
        <MetaTags>
          <title>{titleValue}</title>
          <meta property="og:type" content="website" />
          <meta name="description" content={descriptionValue} />
          <meta property="og:title" content={titleValue} />
          <meta property="og:description" content={descriptionValue} />
          <meta property="og:image" content={imageValue} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@bottomless" />
          <meta name="twitter:title" content={titleValue} />
          <meta name="twitter:description" content={descriptionValue} />

          <meta name="twitter:image" content={imageValue} />
        </MetaTags>
        <Component {...props} />
      </>
    );
  };
