import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Form, Field } from '@bottomless/common/components';
import { ProductStatus } from './Status';
import { Col, Row } from 'reactstrap';

const Schema = Yup.object().shape({
  status: Yup.string(),
  search: Yup.string(),
});

export const Filter = ({ onChange }) => (
  <Form initialValues={{ status: 'active', search: '' }} validationSchema={Schema} onSubmit={onChange}>
    {({ submitForm }) => (
      <>
        <Row className="align-items-center">
          <Col xs="2">
            <Field
              name="status"
              label="Status"
              type="select"
              options={{
                '': 'any',
                new: 'New',
                [ProductStatus.Active]: 'Active',
                [ProductStatus.Archived]: 'Archived',
              }}
              onChange={() => setTimeout(submitForm)}
            />
          </Col>
          <Col>
            <Field className="w-100" name="search" label="Search" onChange={() => setTimeout(submitForm)} />
          </Col>
        </Row>
      </>
    )}
  </Form>
);

Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
};
