import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, CardBody, CardFooter, Collapse } from 'reactstrap';
import { ChevronDown, ChevronRight, ChevronUp, X } from 'react-feather';
import { DropdownMenu } from '../DropdownMenu';
import { BoxCollapsible } from './BoxCollapsible';
import { useToggle } from '@bottomless/common/hooks';
import { ButtonsMenu } from './components';
import './Box.scss';

const MENU_STYLE = { Dropdown: 'dropdown', Buttons: 'buttons' };

export const Box = ({
  children: rawChildren,
  forceOpen,
  to,
  menuItems,
  className,
  actionsUp,
  menuStyle = MENU_STYLE.Dropdown,
  initialOpen = false,
  showChevronRight,
  deleteAction,
}) => {
  const children = [];
  let collapseChildren = null;

  React.Children.forEach(rawChildren, child => {
    if (!React.isValidElement(child)) {
      return;
    }

    if (child.type === BoxCollapsible) {
      collapseChildren = child;
    } else {
      children.push(child);
    }
  });

  const history = useHistory();
  const [isOpen, toggle] = useToggle(initialOpen);

  const onClick = useCallback(
    e => {
      if (['a', 'label', 'input', 'textarea', 'button'].includes(e.target?.tagName?.toLowerCase())) {
        return undefined;
      }

      if (to) {
        return history.push(to);
      }

      if (collapseChildren) {
        e.preventDefault();
        e.stopPropagation();
        return toggle();
      }

      return undefined;
    },
    [history, to, toggle, collapseChildren]
  );

  return (
    <Card className={classNames('box mb-3', { 'box-actions-up': actionsUp }, className)}>
      <CardBody
        onClick={onClick}
        className={classNames({ 'cursor-pointer': (!!collapseChildren && !forceOpen) || to })}
      >
        <div className={classNames('d-flex', { 'align-items-center': !actionsUp })}>
          <div className="flex-grow-1">{children}</div>
          {menuItems?.length > 0 && (
            <>
              {menuStyle === MENU_STYLE.Dropdown && <DropdownMenu items={menuItems} />}
              {menuStyle === MENU_STYLE.Buttons && <ButtonsMenu items={menuItems} />}
            </>
          )}
          {(to || showChevronRight) && (
            <div className="px-3">
              <ChevronRight size="24" />
            </div>
          )}
          {deleteAction && (
            <div className="px-3">
              <X size="24" onClick={deleteAction} className="cursor-pointer" />
            </div>
          )}
          {!!collapseChildren && !forceOpen && (
            <div className="px-3 chevron">{isOpen ? <ChevronUp size="24" /> : <ChevronDown size="24" />}</div>
          )}
        </div>
      </CardBody>
      {collapseChildren && (
        <Collapse isOpen={isOpen || forceOpen}>
          <CardFooter className="bg-white">{collapseChildren}</CardFooter>
        </Collapse>
      )}
    </Card>
  );
};

Box.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
  menuItems: PropTypes.array,
  forceOpen: PropTypes.bool,
  actionsUp: PropTypes.bool,
  menuStyle: PropTypes.string,
  initialOpen: PropTypes.bool,
  showChevronRight: PropTypes.bool,
  deleteAction: PropTypes.func,
};
