import { get, has } from 'lodash-es';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useState, useMemo } from 'react';
import { Printer, Loader } from 'react-feather';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form, Field, DataLoading, Pagination } from '@bottomless/common/components';
import { useDataEffect, useToggle } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import {
  getVendorUnprocessedOrdersAction,
  initiateBatchShipmentAction,
  getBatchShipmentAction,
  updateOnHandInventoryAction,
  getVendorAction,
  getVendorShipmentProviderAction,
  fulfillOrderInShopifyAction,
} from '../../../store/vendor-portal';
import { Order } from './components/Order';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import { useHistory } from 'react-router-dom';
import { UpdateOnHandInventoryModal } from './components/UpdateOnHandInventoryModal';
import './Orders.scss';
import { FulfillOrderInShopifyModal } from './FulfillOrderInShopifyModal';

const Schema = Yup.object().shape({
  layout: Yup.string().required(),
});

const RedirectToUnscanned = () => {
  const { push } = useHistory();

  push('/vendor/dashboard/unscanned');
  return null;
};

const UnprocessedOrdersPageComponent = ({
  getOrders,
  initiateBatchShipment,
  getBatchShipment,
  vendor,
  updateOnHandInventory,
  addToast,
  getVendor,
  getShipmentProvider,
  fulfillOrderInShopify,
}) => {
  const [order, setOrder] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [shipmentLoading, setShipmentLoading] = useState(false);
  const [batchShipmentId, setBatchShipmentId] = useState(null);
  const [batchInterval, setBatchInterval] = useState(null);
  const [batchShipment, setBatchShipment] = useState(null);
  const [orders, setOrders] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [isOnHandInventoryModalOpen, setIsOnHandInventoryModalOpen] = useState(false);
  const [shipmentProvider, setShipmentProvider] = useState();
  const [isFulfillOrderInShopifyModalOpen, toggleFulfillOrderInShopifyModal] = useToggle();
  const [shopifyOrders, setShopifyOrders] = useState(null);

  const { requestInventory = false } = vendor || {};

  const isFedexVendor = shipmentProvider && shipmentProvider.name === 'FedEx';

  const toggleModal = () => {
    setIsOnHandInventoryModalOpen(!isOnHandInventoryModalOpen);
  };

  useEffect(() => {
    if (requestInventory) {
      setIsOnHandInventoryModalOpen(true);
    }
  }, [requestInventory]);

  useDataEffect(getOrders, setOrders, undefined, {}, [refresh]);
  useDataEffect(getShipmentProvider, setShipmentProvider);

  const toggle = () => {
    if (isOpen && order) {
      setOrder(null);
    }

    setOpen(!isOpen);
  };

  useEffect(() => {
    if (batchShipmentId) {
      if (batchInterval) {
        clearInterval(batchInterval);
      }

      const interval = setInterval(async () => {
        const { payload, error } = await getBatchShipment(batchShipmentId);
        if (payload.batch_id && payload.batch_id.length !== 0) {
          setBatchShipment(payload);
          setError(null);
          setShipmentLoading(false);
          if (
            vendor?.scraper?.type === 'shopify' &&
            vendor.scraper.accessToken &&
            vendor.scraper.skipShopify !== true
          ) {
            const filteredOrders = payload.orders?.filter(row => row.external_id);
            if (filteredOrders?.length) {
              setShopifyOrders(filteredOrders);
              toggleFulfillOrderInShopifyModal();
            }
          }
          if (payload.scan_form) {
            clearInterval(interval);
            setShipmentLoading(false);
          }
        }

        if (error) {
          clearInterval(interval);
          setError(payload.status === 404 ? { message: "Couldn't process a batch" } : payload);
          setShipmentLoading(false);
        }
      }, 3000);
      setBatchInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchShipmentId]);

  useEffect(() => {
    if (batchInterval) {
      return () => clearInterval(batchInterval);
    }
  }, [batchInterval]);

  const updateInventory = data => updateOnHandInventory(data);
  const onInventorySuccess = (data, { resetForm }) => {
    addToast('Updated on-hand inventory', 'success');
    getVendor();
    resetForm();
    toggleModal();
  };

  const shipmentHandler = async () => {
    setShipmentLoading(true);
    setError(undefined);

    try {
      const { payload, error } = await initiateBatchShipment();

      if (error) {
        setError(error);
      } else {
        setBatchShipmentId(payload.id);
      }
    } catch (e) {
      if (e.details) {
        if (e.details.refresh) {
          setRefresh(refresh + 1);
        } else if (e.details.id) {
          setBatchShipmentId(e.details.id);
        }
      }

      setError(e);
    }
  };

  const getLabels = ({ layout }) => {
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/vendor-portal/orders/batch-shipment/${batchShipment._id}/labels?layout=${layout}`
    );
  };
  const getScanForm = () => {
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/vendor-portal/orders/batch-shipment/${batchShipment._id}/scan-form`
    );
  };

  const getCsv = () => {
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/vendor-portal/orders/batch-shipment/${batchShipment._id}/csv`
    );
  };

  const shouldRenderSizeAlert = orders => {
    let has80ozOrder;
    const eightyOzOrder = orders.docs.find(order => {
      return (
        order.subproduct_id.product.variants
          .filter(v => v._id.toString() === order.subproduct_id.variant.toString())
          .shift().size === 80
      );
    });
    if (eightyOzOrder) has80ozOrder = true;
    return has80ozOrder;
  };

  const isShopify = useMemo(
    () => batchShipment && batchShipment.batch_id.find(batchId => batchId.includes('shopify')),
    [batchShipment]
  );

  return (
    <Dashboard showFulfillmentTimelines title="Unprocessed orders" className="page-vendor-unprocessed-orders">
      <>
        <DataLoading count={get(orders, 'docs', []).length} isLoading={orders === null}>
          <RedirectToUnscanned />
        </DataLoading>
        {has(orders, 'docs') && get(orders, 'docs', []).length > 0 && (
          <>
            <Row className="mb-4">
              <Col xs="6">
                {!shipmentLoading && !batchShipment && (
                  <Button onClick={shipmentHandler} color="success">
                    <span className="mr-2">Process and generate shipment</span>
                    <Printer size="14" />
                  </Button>
                )}
                {(shipmentLoading || error) && (
                  <div className="d-flex align-items-center">
                    {shipmentLoading && <Loader size="20" className="spin mr-2" />}
                    <div>
                      {error && <div className="text-danger">{error.message}</div>}
                      {shipmentLoading && batchShipment
                        ? 'Scan From is being generated. It can take a few more minutes...'
                        : "We're generating shipment, please wait for the labels..."}
                    </div>
                  </div>
                )}
                {batchShipment && !isShopify && (
                  <>
                    <Button onClick={toggle} color="success" className="mr-3">
                      <span className="mr-2">Get labels</span>
                      <Printer size="14" />
                    </Button>
                    {!isFedexVendor ? (
                      <Button
                        onClick={getScanForm}
                        color="success"
                        className="mr-3"
                        disabled={!batchShipment.scan_form}
                      >
                        <span className="mr-2">Get scan form</span>
                        <Printer size="14" />
                      </Button>
                    ) : (
                      ''
                    )}
                    <Button onClick={getCsv} color="success">
                      <span className="mr-2">Get CSV</span>
                      <Printer size="14" />
                    </Button>
                  </>
                )}
              </Col>
              <Col xs="6" className="d-flex align-items-center justify-content-end">
                <span>{orders.totalDocs} order(s)</span>
              </Col>
            </Row>
            {!batchShipment && shouldRenderSizeAlert(orders) ? (
              <Row className="large-order-alert">
                <Col xs="12">
                  <div>Heads up!</div>
                  <div className="mb-4">
                    This batch includes at least one 80oz (5lb) order. Please make sure to send the correct size(s).
                  </div>
                </Col>
              </Row>
            ) : (
              ''
            )}
            {orders.docs.map(order => (
              <Order
                key={order._id}
                order={order}
                toggle={() => {
                  setOrder(order);
                  toggle();
                }}
              />
            ))}
            <div className="mt-2">
              <Pagination collection={orders} onPageChange={getOrders} />
            </div>

            <Modal isOpen={isOpen} toggle={toggle} size="sm">
              <ModalHeader toggle={toggle}>Select format</ModalHeader>
              <ModalBody>
                <Form
                  initialValues={{ layout: 'labelPrinter' }}
                  validationSchema={Schema}
                  onSubmit={getLabels}
                  onSuccess={() => {}}
                >
                  {() => (
                    <>
                      <Field
                        type="select"
                        name="layout"
                        label="Format"
                        options={{ labelPrinter: 'Label Printer (4x6)', fullPage: 'Full page sheet' }}
                      />
                      <Button color="success">
                        <span className="mr-2">Print labels</span>
                        <Printer size="14" />
                      </Button>
                    </>
                  )}
                </Form>
              </ModalBody>
            </Modal>

            {shopifyOrders && (
              <FulfillOrderInShopifyModal
                isOpen={isFulfillOrderInShopifyModalOpen}
                toggle={toggleFulfillOrderInShopifyModal}
                fulfillOrderInShopify={fulfillOrderInShopify}
                addToast={addToast}
                orders={shopifyOrders}
              />
            )}

            {isOnHandInventoryModalOpen ? (
              <UpdateOnHandInventoryModal
                toggle={toggleModal}
                isOpen={isOnHandInventoryModalOpen}
                vendor={vendor}
                updateOnHandInventory={updateInventory}
                onUpdateOnHandInventorySuccess={onInventorySuccess}
              />
            ) : (
              ''
            )}
          </>
        )}
      </>
    </Dashboard>
  );
};

UnprocessedOrdersPageComponent.propTypes = {
  getOrders: PropTypes.func.isRequired,
  initiateBatchShipment: PropTypes.func.isRequired,
  getBatchShipment: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  vendor: PropTypes.object,
  updateOnHandInventory: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  getVendor: PropTypes.func.isRequired,
  getShipmentProvider: PropTypes.func.isRequired,
  fulfillOrderInShopify: PropTypes.func.isRequired,
};

UnprocessedOrdersPageComponent.defaultProps = {
  isLoading: false,
  vendor: null,
};

export const UnprocessedOrdersPage = withMetaTags({
  title: 'Bottomless.com: Vendor Portal',
})(
  connect(
    ({ vendorPortal }) => ({
      vendor: vendorPortal.vendor,
    }),
    (dispatch, { location: { search } }) => ({
      getOrders: () => dispatch(getVendorUnprocessedOrdersAction(qs.parse(search))),
      initiateBatchShipment: () => dispatch(initiateBatchShipmentAction()),
      getBatchShipment: id => dispatch(getBatchShipmentAction(id)),
      addToast: (id, data) => dispatch(addToastAction(id, data)),
      updateOnHandInventory: data => dispatch(updateOnHandInventoryAction(data)),
      getVendor: () => dispatch(getVendorAction()),
      getShipmentProvider: () => dispatch(getVendorShipmentProviderAction()),
      fulfillOrderInShopify: data => dispatch(fulfillOrderInShopifyAction(data)),
    })
  )(UnprocessedOrdersPageComponent)
);
