import * as FullStory from '@fullstory/browser';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const record = [
  '/filter_checkout',
  '/open_shop',
  '/public_shop',
  '/get_started',
  '/quiz',
  '/referral',
  '/vendor/dashboard',
];

export const FullStoryWrapper = ({ children }) => {
  const location = useLocation();

  if (location.pathname.match(`^${record.join('|')}`)) {
    FullStory.restart();
  }

  return children;
};

FullStoryWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
