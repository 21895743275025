import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { getStripeLinkAction } from '../../../store/vendor-portal';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';

const RefreshPageComponent = ({ getStripeLink }) => {
  const { data } = useDataEffect(getStripeLink);

  useEffect(() => {
    if (data && data.link) {
      window.location = data.link;
    }
  }, [data]);

  return (
    <Dashboard>
      <div className="py-4 text-center">
        <Card body>
          <DataLoading loadingText="Preparing a redirect" count={0} isLoading />
        </Card>
      </div>
    </Dashboard>
  );
};

RefreshPageComponent.propTypes = {
  getStripeLink: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const RefreshPage = withMetaTags({
  title: 'Bottomless.com: Vendor Portal',
})(
  connect(
    ({ vendorPortal }) => ({
      vendor: vendorPortal.vendor,
    }),
    dispatch => ({
      getStripeLink: () => dispatch(getStripeLinkAction()),
    })
  )(RefreshPageComponent)
);
