import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Checkbox, Radio } from '@bottomless/common/components';
import { useCallback } from 'react';
import { utcDate } from '@bottomless/common/utils';

const STATUSES = {
  Active: 'active',
  Paused: 'paused',
  Cancelled: 'cancelled',
};

const Schema = Yup.object().shape({
  status: Yup.string()
    .oneOf(['active', 'paused', 'cancelled'])
    .label('Status'),
  pausedUntil: Yup.date()
    .optional()
    .when('status', {
      is: STATUSES.Paused,
      then: Yup.date()
        .max(new Date('9999'), 'Future date not allowed')
        .required('This field is required'),
      otherwise: Yup.mixed(),
    }),
  notifyUser: Yup.bool(),
});

export const UpdateStatusForm = ({ user, onSubmit, onSubmitSuccess }) => {
  const now = new Date();

  const handleSubmit = useCallback(
    data => {
      const pausedUntil = data.pausedUntil ? moment(data.pausedUntil).format('YYYY-MM-DD') : null;

      return onSubmit({ ...data, pausedUntil });
    },
    [onSubmit]
  );

  return (
    <Form
      initialValues={{
        status: user.hibernate ? STATUSES.Cancelled : user.paused ? STATUSES.Paused : STATUSES.Active,
        pausedUntil: user.pausedUntil && !user.hibernate ? utcDate(user.pausedUntil) : null,
        notifyUser: false,
      }}
      validationSchema={Schema}
      onSubmit={handleSubmit}
      onSuccess={onSubmitSuccess}
    >
      {({ isSubmitting, values, dirty }) => (
        <>
          <div className="d-flex mb-3">
            {Object.entries(STATUSES).map(([label, value]) => (
              <div key={value} className="mr-3">
                <Radio name="status" type="radio" value={value} label={label} />
              </div>
            ))}
          </div>
          {values.status === STATUSES.Paused && <Field name="pausedUntil" type="date" label="Until" min={now} />}
          <div className="mb-3">
            <Checkbox name="notifyUser" label="Notify user" disabled={!dirty} />
          </div>

          <SubmitButton disabled={!dirty} color="dark" isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

UpdateStatusForm.propTypes = {
  user: PropTypes.shape({
    paused: PropTypes.bool,
    hibernate: PropTypes.bool,
    pausedUntil: PropTypes.instanceOf(Date),
  }),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
};
