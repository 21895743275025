import { GetAllTrackingBatchesTypes } from './order.actions';

const initialState = {
  trackingBatches: [],
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetAllTrackingBatchesTypes.REQUEST:
      return { ...state, trackingBatches: [], isLoading: true };

    case GetAllTrackingBatchesTypes.SUCCESS:
      return {
        ...state,
        trackingBatches: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
