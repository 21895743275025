import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { pick } from 'lodash-es';
import { Plus, X } from 'react-feather';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, Checkbox, InfoTooltip } from '@bottomless/common/components';

const Statuses = {
  draft: 'Draft',
  active: 'Active',
  archived: 'Archived',
};

const Schema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  description: Yup.string().required('This field is required'),
  roast: Yup.string().required('This field is required'),
  origin: Yup.string().required('This field is required'),
  region: Yup.string().nullable(),
  process: Yup.string().nullable(),
  status: Yup.mixed().oneOf(Object.keys(Statuses)),
  altitude_min: Yup.number()
    .min(0)
    .nullable(),
  altitude_max: Yup.number()
    .min(0)
    .nullable(),
  tasting_notes: Yup.array().of(Yup.string()),
  tags: Yup.array().of(Yup.string()),
  varietals: Yup.array().of(Yup.string()),
  variants: Yup.array()
    .of(
      Yup.object().shape({
        size: Yup.number().required('This field is required'),
        available: Yup.bool().required('This field is required'),
      })
    )
    .required(),
});

const excludeTags = ['5bcecdeef325036b0f66bb08', '5d8d9d61acdb795502d6ca74', '5bcecdf6f325036b0f66bb09'];

const createNewSelectOptions = data => {
  if (!data) {
    return [];
  }

  const option = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].items) {
      for (let j = 0; j < data[i].items.length; j++) {
        option.push({ value: data[i].items[j]._id, label: data[i].items[j].name });
      }
    } else {
      if (!excludeTags.includes(data[i]._id)) {
        option.push({ value: data[i]._id, label: data[i].name });
      }
    }
  }
  return option;
};

export const ProductForm = ({ product, onSubmit, onSuccess, productOptions }) => {
  const [productCopy, setProductCopy] = useState(product);
  const formRef = useRef();

  const roasts = createNewSelectOptions(productOptions ? productOptions.roasts : null, '-- Choose roast --');
  const origins = createNewSelectOptions(productOptions ? productOptions.origins : null, '-- Choose origin --');
  const regions = createNewSelectOptions(productOptions ? productOptions.regions : null, '-- Choose region --');
  const processes = createNewSelectOptions(productOptions ? productOptions.processes : null, '-- Choose process --');
  const tastingNotes = createNewSelectOptions(productOptions ? productOptions.tasting_notes : null);
  const tagOptions = createNewSelectOptions(productOptions ? productOptions.tags : null);
  const varietalsOptions = createNewSelectOptions(productOptions ? productOptions.varietals : null);

  const pickProps = data => ({
    name: data.name || '',
    description: data.description || '',
    roast: data.roast || '',
    origin: data.origin || '',
    region: data.region || '',
    process: data.process || '',
    altitude_min: data.altitude_min || '',
    altitude_max: data.altitude_max || '',
    tags: data.tags || '',
    status: data.status || '',
    tasting_notes: data.tasting_notes || '',
    decaf: data.tags ? data.tags.includes('5bcecdeef325036b0f66bb08') : false,
    ground: product.tags ? product.tags.includes('5d8d9d61acdb795502d6ca74') : false,
    espresso: product.tags ? product.tags.includes('5bcecdf6f325036b0f66bb09') : false,
    variants: data.variants ? data.variants.map(v => pick(v, ['size', 'available'])) : undefined,
  });

  const addNewVariant = () => {
    formRef.current.setFieldValue(`variants[${productCopy.variants.length}].available`, true);

    setProductCopy({
      ...productCopy,
      variants: [
        ...productCopy.variants,
        {
          notSaved: true,
          available: true,
        },
      ],
    });
  };

  const onRemoveVariant = index => () => {
    setProductCopy({
      ...productCopy,
      variants: [...productCopy.variants.slice(0, index), ...productCopy.variants.slice(index + 1)],
    });
  };

  return (
    <Form
      innerRef={formRef}
      initialValues={pickProps(product)}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <Row>
            <Col>
              <div>
                <span> Name </span>
              </div>
              <Field name="name" />
            </Col>
          </Row>
          <div>
            <span> Description </span>
          </div>
          <Field name="description" type="editor" />
          <Row>
            <Col>
              <div>
                <span> Altitude min. </span>
              </div>
              <Field name="altitude_min" type="number" min="0" />
            </Col>
            <Col>
              <div>
                <span> Altitude max. </span>
              </div>
              <Field name="altitude_max" type="number" min="0" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                name="origin"
                type="select-t"
                label="Origin"
                options={origins}
                isMulti={false}
                shouldDelete={true}
              />
            </Col>
            <Col>
              <Field
                name="region"
                type="select-t"
                options={regions}
                label="Region"
                isMulti={false}
                shouldDelete={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field name="roast" type="select-t" options={roasts} label="Roast" isMulti={false} shouldDelete={true} />
            </Col>
            <Col>
              <Field
                name="process"
                type="select-t"
                options={processes}
                label="Process"
                isMulti={false}
                shouldDelete={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md="auto">
              <Checkbox name="decaf" label="Decaf" />
            </Col>
            <Col md="auto">
              <Checkbox name="espresso" label="Espresso" />
            </Col>
            <Col md="auto">
              <Checkbox name="ground" label="Ground" />
            </Col>
          </Row>
          <Row>
            <Col className="tasting-notes-field">
              <Field name="tasting_notes" type="select-t" options={tastingNotes} label="Tasting notes" isMulti={true} />
            </Col>
            <Col>
              <Field name="tags" isTag={true} type="select-t" options={tagOptions} label="Tags" isMulti={true} />
              <Field name="varietal" type="select-t" options={varietalsOptions} label="Varietals" isMulti={true} />
            </Col>
          </Row>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <span> Variants </span>
            </div>
            <Button color="link" className="d-flex align-items-center" onClick={addNewVariant}>
              <Plus size="12" /> <span className="ml-2">Add new variant</span>
            </Button>
          </div>
          {productCopy.variants.map((variant, i) => (
            <Row key={i}>
              <Col>
                <Field name={`variants[${i}].size`} type="number" label="Size" />
              </Col>
              <Col className="d-flex align-items-center pt-2 mr-0 text-sm">
                <Checkbox
                  name={`variants[${i}].available`}
                  label={
                    <>
                      Available{' '}
                      <InfoTooltip size="sm" className="ml-1">
                        This determines if product is available in Bottomless marketplace and through Shopify widget (if
                        you have it turned on).
                      </InfoTooltip>
                    </>
                  }
                  value={variant.available}
                />
              </Col>
              {variant.notSaved && (
                <Col xs="1">
                  <Button color="link" className="text-danger" onClick={onRemoveVariant(i)}>
                    <X size="20" />
                  </Button>
                </Col>
              )}
            </Row>
          ))}

          <div className="d-flex justify-content-between">
            <SubmitButton color="dark" isSubmitting={isSubmitting}>
              Send
            </SubmitButton>
          </div>
        </>
      )}
    </Form>
  );
};

const OptionsProps = PropTypes.arrayOf(
  PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })
).isRequired;

ProductForm.propTypes = {
  product: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  productOptions: PropTypes.shape({
    tasting_notes: OptionsProps,
    roasts: OptionsProps,
    origins: OptionsProps,
    tags: OptionsProps,
    processes: OptionsProps,
    regions: OptionsProps,
    varietals: OptionsProps,
  }),
};

ProductForm.defaultProps = {
  product: {
    variants: [{ size: null, available: true }],
  },
};
