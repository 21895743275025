import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

export const PendingStripe = ({ vendor }) => {
  const errors = useMemo(() => vendor.accounting?.stripe_errors, [vendor]);

  const fieldsNeeded = useMemo(
    () => vendor.accounting?.stripe_fields_needed?.map(field => field.replace(/_/g, ' ').replace(/\./g, ' - ')),
    [vendor]
  );

  return (
    <>
      {errors?.length > 0 && (
        <div className="mb-4">
          <div className="text-danger">
            <strong>Errors:</strong>
          </div>
          {errors.map((error, key) => (
            <div key={key}>{error}</div>
          ))}
        </div>
      )}
      {fieldsNeeded?.length > 0 && (
        <div className="mb-4">
          <div className="text-danger">
            <strong>Missing fields:</strong>
          </div>
          {fieldsNeeded.map((field, key) => (
            <div key={key} className="text-capitalize">
              {field}
            </div>
          ))}
        </div>
      )}
      {!errors?.length && !fieldsNeeded?.length && (
        <div className="mb-4">Your profile is under verification. Please wait until it&apos;s finished.</div>
      )}
    </>
  );
};

PendingStripe.propTypes = {
  vendor: PropTypes.shape({
    accounting: PropTypes.shape({
      stripe_errors: PropTypes.arrayOf(PropTypes.string),
      stripe_fields_needed: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};
