import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetProductOptionsTypes = createAction('Vendor Product options', 'Get all');

export const getProductOptionsAction = vendorId => ({
  [RSAA]: {
    endpoint: `vendor-portal/product_options${vendorId ? `?vendor_id=${vendorId}` : ''}`,
    method: 'GET',
    types: [GetProductOptionsTypes.REQUEST, GetProductOptionsTypes.SUCCESS, GetProductOptionsTypes.FAILURE],
  },
});
