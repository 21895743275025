import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Price, DateFormat } from '@bottomless/common/components';
import './BatchShipment.scss';

export const BatchShipmentHistory = ({ batch }) => {
  const summary = useMemo(() => batch.orders.reduce((sum, order) => sum + (order.vendor_payout || 0), 0), [batch]);

  return (
    <div className="py-2 d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <div>
          <DateFormat date={batch.created_at} />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Price value={summary} cents />
      </div>
    </div>
  );
};

BatchShipmentHistory.propTypes = {
  batch: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    batch_id: PropTypes.string,
    orders: PropTypes.array.isRequired,
    payout_id: PropTypes.string,
    status: PropTypes.string.isRequired,
  }).isRequired,
};
