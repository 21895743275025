import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SubscriptionType } from '@bottomless/common/constants';

export const UserSubscriptionType = ({ user }) => {
  const subscriptionType = useMemo(() => {
    switch (user.subscriptionType) {
      case SubscriptionType.Dynamic:
        return 'By Usage';
      default:
        return user.subscriptionType;
    }
  }, [user]);

  return <span className="text-capitalize">{subscriptionType}</span>;
};

UserSubscriptionType.propTypes = {
  user: PropTypes.shape({
    subscriptionType: PropTypes.string.isRequired,
  }).isRequired,
};
