import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DumbType, OrderSources, OrderStatuses, SubscriptionType, UserStatuses } from '@bottomless/common/constants';
import { DateFormat } from '@bottomless/common/components';
import { utcDate } from '@bottomless/common/utils';
import { READABLE_FORMAT } from '../../../../utils/dates';
import { useLastOrderDate } from '../hooks';
import { SkipOrder } from './SkipOrder';

export const NextReorder = ({ user, orders, vendor, skipOrder, addToast }) => {
  const lastOrder = useMemo(
    () =>
      orders.find(
        order =>
          order.date_arrived &&
          ![OrderStatuses.Cancelled, OrderStatuses.Offboarded].includes(order.status) &&
          ![OrderSources.REPLACED, OrderSources.LOST].includes(order.source)
      ),
    [orders]
  );

  const upcomingOrders = useMemo(
    () =>
      orders
        .filter(
          order =>
            !order.date_arrived &&
            ![OrderStatuses.Cancelled, OrderStatuses.Offboarded].includes(order.status) &&
            ![OrderSources.REPLACED, OrderSources.LOST].includes(order.source)
        )
        .map(order => ({ order })),
    [orders]
  );

  const { getNextOrderDate } = useLastOrderDate({
    user,
    lastOrder: { data: lastOrder },
    upcomingOrders: { data: { orders: upcomingOrders, vendor } },
  });

  const isFixedDays = useMemo(
    () => user.subscriptionType !== SubscriptionType.Dynamic && user.dumb_type === DumbType.Days,
    [user]
  );

  const isActive = useMemo(
    () =>
      [UserStatuses.Active, UserStatuses.OnboardingPaid, UserStatuses.OnboardingScaleShipped].includes(user.status) &&
      !user.hibernate,
    [user]
  );

  const handleSkipOrder = useCallback(
    async data => {
      const result = await skipOrder(user._id, data);
      addToast('Order skipped.');
      return result;
    },
    [user, skipOrder, addToast]
  );

  if (!isActive || !isFixedDays) {
    return null;
  }

  return (
    <>
      <div className="mt-4">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="font-weight-semi-bold">Next reorder date</div>
          <SkipOrder
            skipOrder={handleSkipOrder}
            fulfillmentDate={utcDate(getNextOrderDate(user.dumb_period, false, 2, user))}
          />
        </div>
        <div>
          <DateFormat date={utcDate(getNextOrderDate(user.dumb_period, true, 1, user))} format={READABLE_FORMAT} />
        </div>
      </div>
    </>
  );
};

NextReorder.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    dumb_period: PropTypes.number,
    subscriptionType: PropTypes.string,
    shopifySubscriptionContractId: PropTypes.string,
    status: PropTypes.string,
    hibernate: PropTypes.bool,
    dumb_type: PropTypes.string,
  }).isRequired,
  skipOrder: PropTypes.func.isRequired,
  getNextOrderDate: PropTypes.func.isRequired,
  orders: PropTypes.array,
  vendor: PropTypes.object,
  addToast: PropTypes.func.isRequired,
};
