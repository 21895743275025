import PropTypes from 'prop-types';
import React from 'react';
import { Image } from '@bottomless/common/components';
import './Footer.scss';

export const Footer = ({ withLogos }) => (
  <>
    {withLogos && (
      <div className="footer-logo-wrapper">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col text-center geekwire-logo mr-sm-5">
              <Image src="/miscellaneous/logos/geekwire2.png" alt="Logo GeekWire" width="120" />
            </div>
            <div className="col text-center tc-logo ml-sm-5">
              <Image src="/miscellaneous/logos/tc.png" alt="Logo TechCrunch" width="70" />
            </div>
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-12 text-center">
              <h2 className="backed-by">Backed by the best:</h2>
            </div>
            <div className="col-12 col-sm-6 text-center yc-logo">
              <Image src="/miscellaneous/logos/yc3.png" alt="Logo Y Combinator" width="180" />
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);

Footer.propTypes = {
  withLogos: PropTypes.bool,
};

Footer.defaultProps = {
  withLogos: false,
};
