import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Eye, Loader } from 'react-feather';
import { get } from 'lodash-es';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Badge, Collapse, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { DateDiffFormat, Tracking } from '@bottomless/common/components';
import classNames from 'classnames';
import { StaticTracking } from '../../UspsTracking/StaticTracking';

export const ScaleShipment = ({ getScaleShipmentTrackingForUser, user }) => {
  const [isModalOpen, toggleModal] = useToggle(false);
  const [isCollapsed, collapse] = useToggle(true);
  const [shipment, setShipment] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onUSPSStatusClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      collapse();
    },
    [collapse]
  );

  const onClick = useCallback(async () => {
    toggleModal();
    setIsLoading(true);
    const { payload: shipment } = await getScaleShipmentTrackingForUser(user._id);
    setShipment(shipment);
    setIsLoading(false);
  }, [getScaleShipmentTrackingForUser, user, toggleModal]);

  return (
    <>
      <Eye size="14" className="ml-2 cursor-pointer" onClick={onClick} />
      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Scale Status</ModalHeader>
        <ModalBody>
          {isLoading && (
            <div className="d-flex justify-content-center">
              <Loader size="16" className="spin ml-2 text-center" />
            </div>
          )}
          {!isLoading && shipment && (
            <div>
              <div>
                Status: <span className="text-secondary">{shipment.status}</span>
              </div>
              <div>
                Shipped: <DateDiffFormat date={shipment.date_shipped} withColor />
              </div>
              <div>
                Tracking: <Tracking number={shipment.tracking_number} label={false} shippingService={'USPS'} />
              </div>
              {shipment.usps_tracking && (
                <div className="usps-status cursor-pointer" onClick={onUSPSStatusClick}>
                  New USPS status:{' '}
                  <Badge
                    color={classNames({
                      danger: ['alert', 'return_to_sender'].includes(shipment.usps_tracking.status),
                      success: shipment.usps_tracking.status === 'delivered',
                      primary: ['accepted', 'in_transit'].includes(shipment.usps_tracking.status),
                      warning: shipment.usps_tracking.status === 'pre_transit',
                      secondary: shipment.usps_tracking.status === 'unknown',
                    })}
                  >
                    {get(shipment, 'usps_tracking.status', '---')}
                  </Badge>
                  <span className="ml-1">{!isCollapsed ? <ChevronUp size="11" /> : <ChevronDown size="11" />}</span>
                </div>
              )}
              <div>
                <Collapse isOpen={!isCollapsed}>
                  {shipment.usps_tracking && (
                    <div className="mt-3">
                      <div>New tracking</div>
                      <StaticTracking usps_tracking={shipment.usps_tracking} />
                    </div>
                  )}
                </Collapse>
              </div>
            </div>
          )}
          {!isLoading && !shipment && (
            <div className="text-center">
              <p>No data available. Please check back later.</p>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

ScaleShipment.propTypes = {
  getScaleShipmentTrackingForUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
};
