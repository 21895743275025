import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { Form, SubmitButton } from '@bottomless/common/components';
import { Box } from '../../../../components/Box';
import { UpsertShopifyNoteModal } from './UpsertShopifyNoteModal';

export const ShopifyNote = ({ user, addToast, updateUserShopifyNote, onUpdate }) => {
  const [isModalOpen, toggleModal] = useToggle(false);
  const [isDeleteModalOpen, toggleDeleteModal] = useToggle(false);

  const menuItems = useMemo(() => [{ label: 'Edit', action: toggleModal }], [toggleModal]);

  const handleDelete = useCallback(() => updateUserShopifyNote(user._id, { shopifyNote: '' }), [
    updateUserShopifyNote,
    user,
  ]);

  const handleDeleteSuccess = useCallback(
    user => {
      onUpdate(user);
      addToast(`Shopify Order Note for user ${user.first_name} ${user.last_name} has been deleted.`);
      toggleDeleteModal();
    },
    [onUpdate, addToast, toggleDeleteModal]
  );

  return (
    <>
      <Box menuItems={menuItems} className="shopify-note-container" deleteAction={toggleDeleteModal}>
        <div className="d-flex align-items-center">
          <i className="fa fa-thumb-tack mr-3" aria-hidden="true"></i>
          <div className="font-weight-bold mr-3">[Shopify Order Note]</div>
          <div>{user.shopifyNote}</div>
        </div>
      </Box>

      <UpsertShopifyNoteModal
        user={user}
        addToast={addToast}
        updateUserShopifyNote={updateUserShopifyNote}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        onUpdate={onUpdate}
      />

      <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Delete Shopify Order Note</ModalHeader>
        <ModalBody>Are you sure you want to delete this Shopify Order Note?</ModalBody>
        <ModalFooter>
          <Form initialValues={{ shopifyNote: '' }} onSubmit={handleDelete} onSuccess={handleDeleteSuccess}>
            {({ isSubmitting }) => (
              <>
                <SubmitButton color="danger" size="sm" isSubmitting={isSubmitting}>
                  Delete
                </SubmitButton>
              </>
            )}
          </Form>
          <Button color="secondary" size="sm" outline onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ShopifyNote.propTypes = {
  updateUserShopifyNote: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    shopifyNote: PropTypes.string,
    _id: PropTypes.string.isRequired,
  }),
  onUpdate: PropTypes.func.isRequired,
};
