import './polyfills';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { hotjar } from 'react-hotjar';
import './index.scss';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

FullStory.init({
  orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
  devMode: process.env.NODE_ENV !== 'production',
  debug: process.env.NODE_ENV !== 'production',
});
FullStory.shutdown();

if (process.env.REACT_APP_HOTJAR_ID) {
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
