import PropTypes from 'prop-types';
import React from 'react';
import { DateFormat } from '@bottomless/common/components';
import { Shipment } from './Shipment';
import { Box, BoxCollapsible } from '../../../../components/Box';

export const Batch = ({ batch, changeStatusToDelivered }) => (
  <Box>
    <div>
      <div>
        <DateFormat date={batch.date} withTime />
      </div>
      <div className="small text-secondary">
        {batch.shipments.length} shipment{batch.shipments.length > 1 ? 's' : ''}
      </div>
    </div>

    <BoxCollapsible>
      {batch.shipments.map((shipment, i) => (
        <Shipment key={i} shipment={shipment} changeStatusToDelivered={changeStatusToDelivered} />
      ))}
    </BoxCollapsible>
  </Box>
);

Batch.propTypes = {
  batch: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    inTransit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    shipments: PropTypes.array.isRequired,
  }).isRequired,
  changeStatusToDelivered: PropTypes.func.isRequired,
};
