import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetProductsTypes = createAction('Vendor products', 'Get all');

export const getProductsAction = params => ({
  [RSAA]: {
    endpoint: `vendor-portal/products${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetProductsTypes.REQUEST, GetProductsTypes.SUCCESS, GetProductsTypes.FAILURE],
  },
});

export const GetProposedProductsV2Types = createAction('Vendor proposed products', 'Get all');

export const getProposedProductsV2Action = params => ({
  [RSAA]: {
    endpoint: `vendor-portal/products/proposed-v2${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetProposedProductsV2Types.REQUEST, GetProposedProductsV2Types.SUCCESS, GetProposedProductsV2Types.FAILURE],
  },
});

export const GetProductTypes = createAction('Vendor products', 'Get product');

export const getProductAction = id => ({
  [RSAA]: {
    endpoint: `vendor-portal/products/${id}`,
    method: 'GET',
    types: [GetProductTypes.REQUEST, GetProductTypes.SUCCESS, GetProductTypes.FAILURE],
  },
});

export const CreateProductFromProposedTypes = createAction('Vendor products', 'Create from proposed');

export const createProductFromProposedAction = proposedId => ({
  [RSAA]: {
    endpoint: `vendor-portal/products/from-proposed/${proposedId}`,
    method: 'POST',
    types: [
      CreateProductFromProposedTypes.REQUEST,
      CreateProductFromProposedTypes.SUCCESS,
      CreateProductFromProposedTypes.FAILURE,
    ],
  },
});

export const UpdateProductTypes = createAction('Vendor products', 'Update');

export const updateProductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/products/${id}`,
    method: 'PATCH',
    body: data,
    types: [UpdateProductTypes.REQUEST, UpdateProductTypes.SUCCESS, UpdateProductTypes.FAILURE],
  },
});

export const UploadProductImageTypes = createAction('Vendor products', 'Upload image');

export const uploadProductImageAction = data => ({
  [RSAA]: {
    endpoint: `vendor-portal/products/upload_image`,
    method: 'POST',
    headers: { upload: true },
    body: data,
    types: [UploadProductImageTypes.REQUEST, UploadProductImageTypes.SUCCESS, UploadProductImageTypes.FAILURE],
  },
});

export const GetTaxCategoriesTypes = createAction('Vendor products', 'Get tax categories');

export const getTaxCategoriesAction = () => ({
  [RSAA]: {
    endpoint: 'vendor-portal/products/tax-categories',
    method: 'GET',
    types: [GetTaxCategoriesTypes.REQUEST, GetTaxCategoriesTypes.SUCCESS, GetTaxCategoriesTypes.FAILURE],
  },
});

export const ApplyProposedProductChangesTypes = createAction('Vendor proposed products', 'Apply changes');

export const applyProposedProductChangesAction = id => ({
  [RSAA]: {
    endpoint: `vendor-portal/products/proposed/${id}/apply_changes`,
    method: 'PATCH',
    types: [
      ApplyProposedProductChangesTypes.REQUEST,
      ApplyProposedProductChangesTypes.SUCCESS,
      ApplyProposedProductChangesTypes.FAILURE,
    ],
  },
});

export const GetProductsFeedbackTypes = createAction('Vendor products', 'Get Liked');

export const getProductsFeedbackAction = () => ({
  [RSAA]: {
    endpoint: 'vendor-portal/products/feedback',
    method: 'GET',
    types: [GetProductsFeedbackTypes.REQUEST, GetProductsFeedbackTypes.SUCCESS, GetProductsFeedbackTypes.FAILURE],
  },
});
