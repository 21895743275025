import * as qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetGiftPurchasesTypes = createAction('Vendor gifts', 'Get all gift purchases');

export const getGiftPurchasesAction = params => ({
  [RSAA]: {
    endpoint: `vendor-portal/gifts/purchases${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetGiftPurchasesTypes.REQUEST, GetGiftPurchasesTypes.SUCCESS, GetGiftPurchasesTypes.FAILURE],
  },
});

export const GetGiftPurchaseTypes = createAction('Vendor gifts', 'Get single gift purchase');

export const getGiftPurchaseAction = id => ({
  [RSAA]: {
    endpoint: `vendor-portal/gifts/purchases/${id}`,
    method: 'GET',
    types: [GetGiftPurchaseTypes.REQUEST, GetGiftPurchaseTypes.SUCCESS, GetGiftPurchaseTypes.FAILURE],
  },
});

export const UpdateGiftPurchaseTypes = createAction('Vendor gifts', 'Update gift purchase');

export const updateGiftPurchaseAction = (id, data) => ({
  [RSAA]: {
    endpoint: `vendor-portal/gifts/purchases/${id}`,
    method: 'PATCH',
    body: data,
    types: [UpdateGiftPurchaseTypes.REQUEST, UpdateGiftPurchaseTypes.SUCCESS, UpdateGiftPurchaseTypes.FAILURE],
  },
});

export const CancelGiftPurchaseTypes = createAction('Vendor gifts', 'Cancel gift purchase');

export const cancelGiftPurchaseAction = id => ({
  [RSAA]: {
    endpoint: `vendor-portal/gifts/purchases/${id}/cancel`,
    method: 'PATCH',
    types: [CancelGiftPurchaseTypes.REQUEST, CancelGiftPurchaseTypes.SUCCESS, CancelGiftPurchaseTypes.FAILURE],
  },
});
