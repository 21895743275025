import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ActivityTimeline } from '../ActivityTimeline/ActivityTimeline';
import { createCustomerNoteAction } from '../../store';

const CustomerNotesComponent = ({ user, createCustomerNote, onCreate, order }) => {
  const { notes } = user || order;

  return <ActivityTimeline activities={notes} createComment={createCustomerNote} onCreate={onCreate} />;
};

CustomerNotesComponent.propTypes = {
  createCustomerNote: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    notes: PropTypes.arrayOf(PropTypes.object),
  }),
  order: PropTypes.shape({
    notes: PropTypes.arrayOf(PropTypes.object),
  }),
};

export const CustomerNotes = connect(null, (dispatch, { user }) => ({
  createCustomerNote: data => dispatch(createCustomerNoteAction({ user: user._id, ...data })),
}))(memo(CustomerNotesComponent));
