import * as momentTimezone from 'moment-timezone';

const pstTimezone = 'America/Los_Angeles';
const ONE_DAY_IN_MILLISECONDS = 86400000;

export const READABLE_FORMAT = 'MMM DD, YYYY';

const getNowIn = (timezone = pstTimezone) =>
  new Date(
    momentTimezone()
      .tz(timezone)
      .format('YYYY-MM-DD HH:mm:ss')
  );

const getNowInPst = () => getNowIn(pstTimezone);

const isSameDay = (a, b) =>
  a.getFullYear() === b.getFullYear() && a.getDate() === b.getDate() && a.getMonth() === b.getMonth();

const getDateAtMidnight = date => {
  return new Date(date).setHours(0, 0, 0, 0);
};

export const getNextFulfillmentDate = ({ startDate, excludedDates, qualifier }) => {
  const startDateZero = getDateAtMidnight(startDate);
  const excludedDatesZero = excludedDates.map(getDateAtMidnight);
  const now = getNowInPst();

  const findDate = compareDate => {
    if (
      !excludedDatesZero.includes(compareDate) &&
      (!qualifier || qualifier(new Date(compareDate))) &&
      !isSameDay(new Date(compareDate), now)
    ) {
      return compareDate;
    } else {
      return findDate(new Date(compareDate + ONE_DAY_IN_MILLISECONDS).getTime());
    }
  };

  return findDate(startDateZero);
};
