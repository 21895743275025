import React from 'react';
import PropTypes from 'prop-types';
import { Status } from './Status';
import { Box } from '../../../../components/Box';

export const ListProduct = ({ product }) => (
  <Box to={`/vendor/dashboard/products/${product._id}`} className="vendor-product">
    <div className="d-flex align-items-center">
      <img src={product.original_image} alt="product" className="img-fluid rounded mr-3" />
      <div>
        <div>
          {product.name}
          {product.vendor_change_set && <span className="text-warning ml-1"> - pending approval</span>}
        </div>
        <div className="small">
          <Status product={product} />
        </div>
      </div>
    </div>
  </Box>
);

ListProduct.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    original_image: PropTypes.string.isRequired,
    vendor_change_set: PropTypes.object,
  }).isRequired,
};
