export const downloadCSV = (data, filename, headerMapping) => {
  const separator = ',';
  const csvHeader =
    Object.keys(data[0])
      .map(row => (headerMapping && headerMapping[row]) || row)
      .join(separator) + '\n';

  const csvRows = data.map(item => Object.values(item).join(separator)).join('\n');
  const csv = csvHeader + csvRows;
  const blob = new Blob([csv], { type: 'text/csv' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();

  URL.revokeObjectURL(link.href);
};
