import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import {
  Form,
  SubmitButton,
  AddressInput,
  GoogleMapsLoaded,
  GoogleMapsLoader,
  Radio,
} from '@bottomless/common/components';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { Loader } from 'react-feather';

const AddressSchema = Yup.object().shape({
  verifiedAddress: Yup.object()
    .shape({
      street1: Yup.string(),
      city: Yup.string(),
      zip: Yup.string(),
      state: Yup.string(),
      street2: Yup.string(),
      pickup: Yup.bool(),
      shopifyLocationId: Yup.string()
        .optional()
        .when('pickup', {
          is: true,
          then: Yup.string().required('This field is required'),
          otherwise: Yup.mixed(),
        }),
    })
    .required(),
});

export const UpdateAddressForm = ({ user, onSubmit: onSubmitRaw, onSubmitSuccess, getPickupLocations }) => {
  const [pickupLocations, setPickupLocations] = useState();
  const isSimpleSubscription = useMemo(() => !!user.shopifySubscriptionContractId, [user]);

  const { isFetching } = useConditionalDataEffect(isSimpleSubscription, getPickupLocations, setPickupLocations);

  const onSubmit = useCallback(
    ({ verifiedAddress }) => {
      if (verifiedAddress.pickup === 'false' || !verifiedAddress.shopifyLocationId) {
        delete verifiedAddress.pickup;
      }
      if (verifiedAddress.pickup) {
        const selectedLocation = pickupLocations.find(row => row.id === verifiedAddress.shopifyLocationId);
        const pickupAddress = {
          ...selectedLocation?.address,
          pickup: true,
          shopifyLocationId: verifiedAddress.shopifyLocationId,
          city: selectedLocation?.address?.city || '',
          state: selectedLocation?.address?.provinceCode || '',
          street1: selectedLocation?.address?.address1 || '',
          street2: selectedLocation?.address?.address2 || '',
          zip: selectedLocation?.address?.zip || '',
        };
        return onSubmitRaw({ verifiedAddress: pickupAddress });
      }
      return onSubmitRaw({ verifiedAddress });
    },
    [onSubmitRaw, pickupLocations]
  );

  return (
    <GoogleMapsLoader>
      {!isSimpleSubscription || !isFetching ? (
        <Form
          initialValues={{
            verifiedAddress: { state: 'AL', pickup: false, ...(user.verifiedAddress || {}) },
          }}
          validationSchema={AddressSchema}
          onSubmit={onSubmit}
          onSuccess={onSubmitSuccess}
        >
          {({ isSubmitting, dirty, values, setFieldValue, errors }) => {
            return (
              <>
                <div className="d-flex mb-3">
                  <div className="mr-3">
                    <Radio name="verifiedAddress.pickup" type="radio" value={false} label="Delivery" />
                  </div>
                  {pickupLocations?.length && (
                    <div>
                      <Radio name="verifiedAddress.pickup" type="radio" value={true} label="Pickup" />
                    </div>
                  )}
                </div>
                {values.verifiedAddress.pickup === 'true' || values.verifiedAddress.pickup === true ? (
                  <div>
                    <div>Select Pickup Location</div>
                    {errors?.verifiedAddress?.shopifyLocationId && (
                      <div className="text-left invalid-feedback d-block">
                        {errors.verifiedAddress.shopifyLocationId}
                      </div>
                    )}
                    {pickupLocations?.map(location => (
                      <div key={location.id}>
                        <Radio
                          name="verifiedAddress.shopifyLocationId"
                          type="radio"
                          value={location.id}
                          label={location.name}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <GoogleMapsLoaded>
                    <AddressInput {...{ setFieldValue, values }} />
                  </GoogleMapsLoaded>
                )}
                <div className="d-flex justify-content-end">
                  <SubmitButton disabled={!dirty} size="sm" color="dark" isSubmitting={isSubmitting}>
                    Save
                  </SubmitButton>
                </div>
              </>
            );
          }}
        </Form>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <Loader size="18" className="spin" />
        </div>
      )}
    </GoogleMapsLoader>
  );
};

UpdateAddressForm.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    address_special_instructions: PropTypes.string,
    base_id: PropTypes.string.isRequired,
    paidUntil: PropTypes.instanceOf(Date),
    verifiedAddress: PropTypes.object,
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
    shopifySubscriptionContractId: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  getPickupLocations: PropTypes.func.isRequired,
};
