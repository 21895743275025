import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { getBatchShipmentsAction, generateReceiptAction, getStripeLinkAction } from '../../../store/vendor-portal';
import { addToastAction } from '@bottomless/common/store';
import { Dashboard } from '../../../layouts/Dashboard/Dashboard';
import { Stripe } from './components/Stripe';
import { Payout } from './components/Payout';
import './PayoutHistory.scss';

const PayoutHistoryPageComponent = ({ getBatchShipments, generateReceipt, addToast, vendor, getStripeLink }) => {
  const [batchShipments, setBatchShipments] = useState();

  const { isFetching } = useDataEffect(getBatchShipments, setBatchShipments);

  const payouts = useMemo(() => {
    if (batchShipments) {
      return batchShipments.data.reduce((all, current) => {
        if (current.payout_id) {
          if (all[current.payout_id]) {
            all[current.payout_id].batches.push(current);
            all[current.payout_id].summary += current.orders.reduce(
              (sum, order) => sum + (order.vendor_payout || 0),
              0
            );
          } else {
            all[current.payout_id] = { batches: [current] };
            all[current.payout_id].summary = current.orders.reduce((sum, order) => sum + (order.vendor_payout || 0), 0);
          }
        }
        return { ...all };
      }, {});
    }
  }, [batchShipments]);
  return (
    <Dashboard title="Payout">
      <>
        <Stripe vendor={vendor} getStripeLink={getStripeLink} addToast={addToast} />
        <DataLoading count={payouts ? Object.keys(payouts).length : 0} isLoading={isFetching} />
        {payouts &&
          Object.keys(payouts).map(key => (
            <Payout key={key} generateReceipt={generateReceipt} addToast={addToast} payoutId={key} payouts={payouts} />
          ))}
      </>
    </Dashboard>
  );
};

PayoutHistoryPageComponent.propTypes = {
  vendor: PropTypes.shape({
    accounting: {
      stripe_connected_account: PropTypes.string,
      status: PropTypes.string,
    },
  }),
  getBatchShipments: PropTypes.func.isRequired,
  generateReceipt: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  getStripeLink: PropTypes.func.isRequired,
};

export const PayoutHistoryPage = connect(
  ({ vendorPortal }) => ({
    vendor: vendorPortal.vendor,
  }),
  dispatch => ({
    getBatchShipments: (vendorId, params) => dispatch(getBatchShipmentsAction(vendorId, params)),
    generateReceipt: batches => dispatch(generateReceiptAction(batches)),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    getStripeLink: () => dispatch(getStripeLinkAction()),
  })
)(PayoutHistoryPageComponent);
